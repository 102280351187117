export const deleteTeamClientsLogic500 = async (datas) => {
  const {
    navigate,
    updateOpenModal,
    navigateTo,
    routePortfolio,
    updateNotificationData,
    updateOpenNotification,
    callData,
  } = datas;

  updateOpenNotification(true);
  updateNotificationData({
    ...callData,
  });
  updateOpenModal(false);

  navigateTo({ navigate, routeName: routePortfolio });
};
