import { Logo } from '../atoms/Logo';

export const Header = ({ children }) => (
  <header>
    <nav
      className="mx-auto flex items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <div className="flex lg:flex-1">
        <Logo />
      </div>

      <p className="text-center text-sm text-gray-500">{children}</p>
    </nav>
  </header>
);
