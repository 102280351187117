export const ActionPanel = ({ title, description, children }) => (
  <div className="bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </h3>
      <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          <p>{description}</p>
        </div>
      </div>
      <div className="mt-5">{children}</div>
    </div>
  </div>
);
