export const getTeamProjectLogic = async (datas) => {
  const { callData, setProject } = datas;

  setProject({
    ...callData,
    deleteTeamMembersUUIDs: [],
    deleteTeamProjectTagsUUIDs: [],
    teamMembersUUID: [],
    tagsUUIDs: [],
    newTagsToCreate: [],
  });
};
