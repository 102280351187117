export const postPasswordByInvitedUserLogic = async (datas) => {
  const {
    navigate,
    navigateTo,
    routeAllocations,
    callData,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    if (callData.type === 'success') {
      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
      });
      localStorage.setItem('authToken', callData.payload);
      navigateTo({ navigate, routeName: routeAllocations });
    } else {
      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
      });
    }
  }
};
