import { sortClientsAndProjects } from '../../../utils/sortClientsAndProjectsUtils';

export const getTeamClientsByTeamUUIDLogic = async (datas) => {
  const {
    setClients,
    setSearchedClientsResult,
    setSearchedProjectsResult,
    callData,
  } = datas;
  setClients(callData);

  if (setSearchedClientsResult) {
    setSearchedClientsResult(sortClientsAndProjects(callData));
  }

  if (setSearchedProjectsResult) {
    setSearchedProjectsResult(callData);
  }
};
