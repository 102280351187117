import { Link } from 'react-router-dom';
import { RequiredAsterisk } from '../atoms/RequiredAsterisk';

export const SlideOverInputLabel = ({
  label,
  subLabel,
  linkData,
  isRequired = false,
}) => (
  <div>
    <label
      htmlFor={`inputLabel${label}`}
      className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
    >
      {label}
      {isRequired && <RequiredAsterisk />}

      {subLabel && (
        <span className="flex text-xs font-light text-gray-500">
          {subLabel}
        </span>
      )}
    </label>

    {linkData && (
      <Link
        to={linkData.to}
        className="text-xs font-light text-indigo-500 hover:text-indigo-600"
      >
        {linkData.label}
      </Link>
    )}
  </div>
);
