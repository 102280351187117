import React, { useEffect, useState, useContext } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { SubSideBarLayout } from '../organisms/SubSideBarLayout';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SubTitle } from '../atoms/SubTitle';
import { ActionPanel } from '../molecules/ActionPanel';
import { Label } from '../atoms/Label';
import { NotificationContext } from '../../contexts/NotificationContext';
import { PageHeadingButton } from '../molecules/PageHeadingButton';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { useTeam } from '../../contexts/TeamContext';
import { formatDateInWords } from '../../services/utils/momentUtils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SettingsPlansAndBillingScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  /* Access context values using the useTeam hook */
  const { teamUUID } = useTeam();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const pricingData = {
    frequencies: [
      { value: 'monthly', label: 'Monthly', priceSuffix: 'per person/month' },
      {
        value: 'annually',
        label: 'Annually (10% off)',
        priceSuffix: 'per person/year',
      },
    ],
    tiers: [
      {
        name: 'Starter',
        id: 'starter',
        seatsQuantity: 10,
        price: { monthly: '$6', annually: '$54' },
        description: 'Best for growing teams.',
        features: [
          '50 projects',
          '10 clients',
          'Advanced analytics',
          'Reports dashboard',
          'Exportable data',
          '24 hour/5 day support',
        ],
        mostPopular: false,
      },
      {
        name: 'Pro',
        id: 'pro',
        seatsQuantity: 10,
        price: { monthly: '$10', annually: '$90' },
        description: 'Medium sized businesses.',
        features: [
          '500 projects',
          '100 clients',
          'Advanced analytics',
          'Reports dashboard',
          'Exportable data',
          '24 hour/5 day support',
          'Coming : Visual management',
          'Coming : Self & team assessment',
        ],
        mostPopular: true,
      },
      {
        name: 'Enterprise',
        id: 'enterprise',
        seatsQuantity: 10,
        price: { monthly: '$15', annually: '$162' },
        description: 'Biggest companies.',
        features: [
          'Unlimited projects',
          'Unlimited clients',
          'Advanced analytics',
          'Reports dashboard',
          'Exportable data',
          '24 hour/5 day support',
          'Coming : Visual management',
          'Coming : Self & team assessment',
          'Coming : Single SignOn(SSO)',
        ],
        mostPopular: false,
      },
    ],
  };

  const [pricing, setPricing] = useState(pricingData);
  const [frequency, setFrequency] = useState(pricing.frequencies[0]);
  const [members, setMembers] = useState([]);
  const [teamPlan, setTeamPlan] = useState({});
  const [stripePortalLink, setStripePortalLink] = useState(null);

  /* Handle the checkout success redirection */
  const urlParams = new URLSearchParams(location.search);
  const hasRequiredParams =
    urlParams.has('success') &&
    urlParams.has('session_id') &&
    urlParams.has('plan_uuid');

  /* BEGINNING CALL API */
  const postBillingPlanPromise = async (
    planUUID,
    stripeCheckoutSessionID,
    teamUUID,
    returnURI
  ) => {
    await dynamicApiCall({
      callName: 'postBillingPlanByStripeCheckoutSessionID',
      navigate,
      params: {
        planUUID,
        stripeCheckoutSessionID,
        teamUUID,
        returnURI,
      },
    });
  };

  const fetchTeamMembersByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamMembersByTeamUUID',
      navigate,
      params: { teamUUID, setPeople: setMembers },
    });
  };

  const postStripePortalSessionPromise = async (stripeCheckoutSessionID) => {
    await dynamicApiCall({
      callName: 'postStripePortalSession',
      navigate,
      params: {
        stripeCheckoutSessionID,
        returnURI: location.pathname,
        setStripePortalLink,
      },
    });
  };

  const postStripeCheckoutSessionPromise = async (
    plan,
    quantity,
    frequency
  ) => {
    await dynamicApiCall({
      callName: 'postStripeCheckoutSession',
      navigate,
      params: {
        plan,
        quantity,
        frequency,
        returnURI: location.pathname,
      },
    });
  };

  const fetchTeamBillingPlanByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getBillingPlanByTeamUUID',
      navigate,
      params: { teamUUID, setTeamPlan, postStripePortalSessionPromise },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page, optionalText) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        optionalText,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };
  /* END CALL API */
  const handleSeatQuantityChange = (tierId, newQuantity) => {
    setPricing((prevPricing) => ({
      ...prevPricing,
      tiers: prevPricing.tiers.map((tier) => {
        if (tier.id === tierId) {
          return { ...tier, seatsQuantity: newQuantity };
        }
        return tier;
      }),
    }));
  };

  useEffect(() => {
    if (hasRequiredParams && teamUUID) {
      postBillingPlanPromise(
        urlParams.get('plan_uuid'),
        urlParams.get('session_id'),
        teamUUID,
        location.pathname
      );
    }
  }, [location, teamUUID]);

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'PlansAndBillings', 'settings');
      fetchTeamMembersByTeamUUIDPromise(teamUUID);
      fetchTeamBillingPlanByTeamUUIDPromise(teamUUID);
    }
  }, [location, teamUUID]);

  return (
    <SideBarLayout>
      <SubSideBarLayout>
        <main className="lg:pl-72 xl:pl-96">
          <SingleScreenTitle text="Plans & billing" />
          <SubTitle text="Review your billing details, history and manage your plan." />
          <div className="mt-4" />

          <ActionPanel title="Billing details">
            <div className="flex flex-wrap -mx-2">
              <div className="px-2 w-1/4">
                <Label text="Seats used :" />
                <p className="block text-lg font-medium leading-6 text-indigo-500">
                  {members.length ? `${members.length} members` : '-'}
                </p>
                <p className="block text-xs font-light leading-6 text-gray-500">
                  {teamPlan?.seatsNumber
                    ? `${teamPlan.seatsNumber} includes in your plan`
                    : teamPlan[0]?.createdAt &&
                      (new Date() - new Date(teamPlan[0].createdAt)) /
                        (1000 * 60 * 60 * 24) >
                        30
                    ? `Trial expired`
                    : `Unlimited seats during free trial`}

                  {/* Calculate if teamPlan[0]?.createdAt is older than 30 days */}
                </p>
              </div>
              <div className="px-2 w-1/4">
                <Label text="Plan :" />
                <p className="block text-lg font-medium leading-6 text-indigo-500">
                  {teamPlan?.planName
                    ? `${teamPlan.planName}`
                    : teamPlan[0]?.createdAt &&
                      (new Date() - new Date(teamPlan[0].createdAt)) /
                        (1000 * 60 * 60 * 24) >
                        30
                    ? `Trial expired`
                    : `Trial`}
                </p>
                <p className="block text-xs font-light leading-6 text-gray-500">
                  {teamPlan?.billingPlanCreatedAt
                    ? `Since ${formatDateInWords(
                        teamPlan.billingPlanCreatedAt
                      )}`
                    : null}

                  {teamPlan[0]?.createdAt
                    ? `${Math.max(
                        0,
                        Math.ceil(
                          (new Date(
                            new Date(teamPlan[0]?.createdAt).getTime() +
                              30 * 24 * 60 * 60 * 1000
                          ) -
                            new Date()) /
                            (1000 * 60 * 60 * 24)
                        )
                      )} days left`
                    : null}
                </p>
              </div>
              <div className="px-2 ml-auto" />
              <div className="my-auto px-2">
                <PageHeadingButton
                  onClick={() => {
                    if (stripePortalLink) {
                      window.location.href = stripePortalLink;
                    }
                  }}
                  buttonColors={
                    teamPlan?.planName
                      ? `text-white bg-indigo-600`
                      : `text-white bg-gray-400`
                  }
                  disabled={!teamPlan?.planName}
                  label="Manage your plan"
                />
              </div>
            </div>
          </ActionPanel>

          <div className="mt-8" />

          <ActionPanel title="Plans">
            <div className="flex justify-center">
              <RadioGroup
                value={frequency}
                onChange={setFrequency}
                className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
              >
                <RadioGroup.Label className="sr-only">
                  Payment frequency
                </RadioGroup.Label>
                {pricing.frequencies.map((option) => (
                  <RadioGroup.Option
                    key={option.value}
                    value={option}
                    className={({ checked }) =>
                      classNames(
                        checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                        'cursor-pointer rounded-full px-2.5 py-1'
                      )
                    }
                  >
                    <span>{option.label}</span>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>

            <div className="isolate mx-auto mt-4 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-3 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
              {pricing.tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? 'ring-2 ring-indigo-600'
                      : 'ring-1 ring-gray-200',
                    'rounded-3xl p-8'
                  )}
                >
                  <h2
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                      'text-lg font-semibold leading-8'
                    )}
                  >
                    {tier.name}
                  </h2>
                  <p className="mt-4 text-sm leading-6 text-gray-600">
                    {tier.description}
                  </p>
                  <p className="mt-6 xl:flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      {tier.price[frequency.value]}
                    </span>
                    <span className="text-sm leading-6 text-gray-600">
                      {frequency.priceSuffix}
                    </span>
                  </p>
                  {!teamPlan?.planName ? (
                    <div>
                      <div className="mt-6 mb-2 justify-center flex items-center">
                        <p className="mr-2 text-sm leading-6 text-gray-600">
                          Total seats :
                        </p>
                        <input
                          type="number"
                          name="seatsNumber"
                          id={`seatsNumber-${tier.id}`}
                          className="w-18 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="0"
                          defaultValue={tier.seatsQuantity}
                          onChange={(e) =>
                            handleSeatQuantityChange(
                              tier.id,
                              parseInt(e.target.value)
                            )
                          }
                          max="200"
                          min="1"
                        />
                      </div>
                      <Link
                        onClick={() =>
                          postStripeCheckoutSessionPromise(
                            frequency.value,
                            tier.seatsQuantity,
                            tier.id
                          )
                        }
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                            : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                          'block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        )}
                      >
                        Subscribe
                      </Link>
                    </div>
                  ) : null}
                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                  >
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <CheckIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </ActionPanel>
        </main>
      </SubSideBarLayout>
    </SideBarLayout>
  );
};
