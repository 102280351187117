import Holidays from 'date-holidays';

export const getCountryByTeamUUIDLogic = async (datas) => {
  const { setHolidays, callData } = datas;

  if (callData) {
    const holidays = new Holidays();

    if (callData.countryRegion) {
      holidays.init(callData.countryCode, callData.countryRegion);
    } else {
      holidays.init(callData.countryCode);
    }

    setHolidays(holidays);
  }
};
