export const tableCellTextSort = (a, b, direction) => {
  // Handle cases where a or b is null or undefined
  if (a === null || a === undefined) {
    return direction === 'asc' ? 1 : -1;
  }
  if (b === null || b === undefined) {
    return direction === 'asc' ? -1 : 1;
  }

  // Compare non-null values
  if (a < b) {
    return direction === 'asc' ? -1 : 1;
  }
  if (a > b) {
    return direction === 'asc' ? 1 : -1;
  }
  return 0;
};

export const tableCellAmountSort = (a, b, direction) => {
  // Handle cases where a or b is null or undefined
  if (a === null || a === undefined) {
    return direction === 'asc' ? 1 : -1;
  }
  if (b === null || b === undefined) {
    return direction === 'asc' ? -1 : 1;
  }

  // Perform the sorting based on the direction
  return direction === 'asc' ? a - b : b - a;
};

export const tableCellDateSort = (a, b, direction) => {
  // Handle cases where a or b is null or undefined
  if (a === null || a === undefined) {
    return direction === 'asc' ? 1 : -1;
  }
  if (b === null || b === undefined) {
    return direction === 'asc' ? -1 : 1;
  }

  // Convert strings to Date objects
  const dateA = new Date(a);
  const dateB = new Date(b);

  // Compare dates
  if (dateA < dateB) {
    return direction === 'asc' ? -1 : 1;
  }
  if (dateA > dateB) {
    return direction === 'asc' ? 1 : -1;
  }
  return 0;
};

export const tableCellArrayLengthSort = (a, b, direction) => {
  const countA = Array.isArray(a) ? a.length : 0;
  const countB = Array.isArray(b) ? b.length : 0;
  return direction === 'asc' ? countA - countB : countB - countA;
};
