import React from 'react';
import moment from 'moment';
import DotStatus from '../atoms/DotStatus'; // Assuming this component exists and works as expected
import { formatDateInWords } from '../../services/utils/momentUtils'; // Assuming this is the correct utility function

export const DatePeriodWithDot = ({ from, to }) => {
  const isToDateInThePast = moment(to).isBefore(moment());
  const isFromDateInTheFuture = moment(from).isAfter(moment());

  const dotColor = isFromDateInTheFuture
    ? 'bg-blue-500'
    : isToDateInThePast
    ? 'bg-red-500'
    : 'bg-green-500';

  const backgroundColor = isFromDateInTheFuture
    ? 'bg-blue-500/10'
    : isToDateInThePast
    ? 'bg-red-500/10'
    : 'bg-green-500/10';

  return (
    <div className="flex items-center gap-x-2">
      <DotStatus color={dotColor} backgroundColor={backgroundColor} />
      <span>
        <time className="font-medium" dateTime={from}>
          {formatDateInWords(from)}
        </time>{' '}
        -{' '}
        <time className="font-medium" dateTime={to}>
          {formatDateInWords(to)}
        </time>
      </span>
    </div>
  );
};
