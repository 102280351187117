export const TableCellLink = ({
  data,
  label,
  index,
  customClassName,
  onClick,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap px-2 py-4 text-xs text-indigo-500',
        customClassName
      )}
    >
      <span
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={handleKeyPress}
        className="hover:underline truncate block max-w-xs"
        title={label} // Add a title attribute to show the full text on hover
      >
        {label}
      </span>
    </td>
  );
};
