import {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { UserPlusIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';
import { PageHeading } from '../molecules/PageHeading';
import { PageHeadingButton } from '../molecules/PageHeadingButton';
import { EmptyStates } from '../organisms/EmptyStates';
import { useTeam } from '../../contexts/TeamContext';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { CalendarPicker } from '../atoms/CalendarPicker';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverInputText } from '../molecules/SlideOverInputText';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { SlideOverInputCombobox } from '../molecules/SlideOverInputCombobox';
import { SlideOverInputWorkDays } from '../molecules/SlideOverInputWorkDays';
import { SlideOverInputItemsToggle } from '../molecules/SlideOverInputItemsToggle';
import { SlideOverInputNumberWithTradeBridge } from '../molecules/SlideOverInputNumberWithTradeBridge';
import DotStatus from '../atoms/DotStatus';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MembersScreen() {
  const navigate = useNavigate();
  const checkbox = useRef();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  /* Access context values using the useTeam hook */
  const {
    teamUUID,
    teamCurrencySymbol,
    teamName,
    teamDefaultDailyRate,
    teamDefaultHourlyRate,
  } = useTeam();

  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [slideOverDefaultValues, setSlideOverDefaultValues] = useState({
    uuid: null,
    userUUID: null,
    mail: null,
    firstName: '',
    lastName: '',
    access: '',
    contractUUID: '',
    hourlyRate: null,
    dailyRate: null,
    from: '',
    to: '',
    hoursPreferences: null,
    projects: null,
    isChecked: true,
  });

  useEffect(() => {
    if (teamDefaultDailyRate && teamDefaultHourlyRate) {
      setSlideOverDefaultValues({
        ...slideOverDefaultValues,
        dailyRate: teamDefaultDailyRate,
        hourlyRate: teamDefaultHourlyRate,
      });
    }
  }, [teamDefaultDailyRate, teamDefaultHourlyRate]);

  const [isUpdatingMember, setIsUpdatingMember] = useState(false);
  const [people, setPeople] = useState([]);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [currency, setCurrency] = useState();

  const [openMemberSlideOver, setOpenMemberSlideOver] = useState(false);
  const [privileges, setPrivileges] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [projects, setProjects] = useState([]);

  const [userPrivileges, setUserPrivileges] = useState('member');
  const updateOpenMemberSlideOver = (newOpenSlideOver) => {
    if (newOpenSlideOver === false) {
      setSlideOverDefaultValues({
        uuid: null,
        userUUID: null,
        mail: null,
        firstName: '',
        lastName: '',
        access: '',
        contractUUID: '',
        dailyRate: teamDefaultDailyRate,
        hourlyRate: teamDefaultHourlyRate,
        from: '',
        to: '',
        hoursPreferences: null,
        projects: null,
        isChecked: true,
      });
      setIsUpdatingMember(false);
    }
    setOpenMemberSlideOver(newOpenSlideOver);
  };

  const getSelectedProjects = (projects) => {
    if (!projects) return { selected: [], notSelected: [] };
    const selected = [];
    const notSelected = [];

    Object.keys(projects).forEach((key) => {
      if (projects[key]) {
        selected.push(key);
      } else {
        notSelected.push(key);
      }
    });

    return { selected, notSelected };
  };

  const postTeamMembersCall = async (inputData, reset) => {
    const { selected } = getSelectedProjects(inputData?.projects);

    const params = {
      privilegeUUID: inputData.access !== '' ? inputData.access : undefined,
      contractUUID:
        inputData.contractUUID !== '' ? inputData.contractUUID : undefined,
      hourlyRate: inputData.isChecked ? null : inputData.hourlyRate,
      firstName: inputData.firstName !== '' ? inputData.firstName : undefined,
      lastName: inputData.lastName !== '' ? inputData.lastName : undefined,
      startedAt: inputData.from !== '' ? inputData.from : null,
      endedAt: inputData.to !== '' ? inputData.to : null,
      mail: inputData.mail,
      hoursPreferences: inputData.hoursPreferences,
      teamUUID,
      teamProjectsUUID: selected.length > 0 ? selected : undefined,
    };

    await dynamicApiCall({
      callName: 'postInvitedUsersByMailAndTeamUUID',
      navigate,
      params: {
        ...params,
        reset,
        fetchMembersScreenData,
        updateOpenNotification,
        updateNotificationData,
        updateOpenMemberSlideOver,
      },
    });
  };

  const putTeamMembersCall = async (inputData, reset) => {
    const teamProjectsUUID = [];
    const deleteTeamProjectsUUID = [];

    Object.entries(inputData.projects).forEach(([uuid, value]) => {
      if (value) {
        teamProjectsUUID.push(uuid);
      } else {
        deleteTeamProjectsUUID.push(uuid);
      }
    });

    const params = {
      uuid: inputData.uuid,
      teamUUID,
      hoursPreferences: inputData.hoursPreferences,
      privilegeUUID: inputData.access,
      contractUUID: inputData.contractUUID || undefined,
      hourlyRate: inputData.isChecked ? null : inputData.hourlyRate,
      startedAt: inputData.from || null,
      endedAt: inputData.to || null,
      deleteTeamProjectsUUID,
      teamProjectsUUID,
    };

    const putTeamMembersByUUIDData = await dynamicApiCall({
      callName: 'putTeamMembersByUUID',
      navigate,
      params: {
        ...params,
        updateOpenModal: updateOpenMemberSlideOver,
        updateOpenNotification,
        updateNotificationData,
      },
    });

    if (
      putTeamMembersByUUIDData &&
      putTeamMembersByUUIDData.type === 'success'
    ) {
      await dynamicApiCall({
        callName: 'putUserByUUID',
        navigate,
        params: {
          uuid: inputData.userUUID,
          firstName:
            inputData.firstName && inputData.firstName !== ''
              ? inputData.firstName
              : undefined,
          lastName:
            inputData.lastName && inputData.lastName !== ''
              ? inputData.lastName
              : undefined,
          mail: inputData.mail,
          fetchInfo: fetchMembersScreenData,
          updateOpenModal: updateOpenMemberSlideOver,
          updateOpenNotification,
          updateNotificationData,
        },
      });
    }
  };

  const handleData = (peopleClicked) => {
    setIsUpdatingMember(true);
    const isChecked = !(peopleClicked.dailyRate && peopleClicked.hourlyRate);

    setSlideOverDefaultValues({
      uuid: peopleClicked.uuid,
      userUUID: peopleClicked.userUUID,
      mail: peopleClicked.mail,
      firstName: peopleClicked.firstName || '',
      lastName: peopleClicked.lastName || '',
      access: peopleClicked.privilegeUUID,
      contractUUID: peopleClicked.contractUUID,
      hourlyRate: peopleClicked.hourlyRate || teamDefaultHourlyRate,
      dailyRate: peopleClicked.dailyRate || teamDefaultDailyRate,
      isChecked,
      from: peopleClicked.startedAt || null,
      to: peopleClicked.endedAt || null,
      hoursPreferences: peopleClicked.hoursPreferences || null,
      projects: peopleClicked.projectsUUID.reduce((acc, project) => {
        acc[project] = true;
        return acc;
      }, {}),
    });

    updateOpenMemberSlideOver(true);
  };

  /* BEGINNING CALL API */
  const fetchTeamMembersByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamMembersByTeamUUID',
      navigate,
      params: {
        teamUUID,
        setPeople,
      },
    });
  };

  const fetchContractsByAuthTokenPromise = async () => {
    await dynamicApiCall({
      callName: 'getContractsByAuthToken',
      navigate,
      params: { setContracts },
    });
  };

  const fetchTeamProjectsByTeamUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'getTeamProjectsByTeamUUID',
      navigate,
      params: { teamUUID, setProjects },
    });
  };

  /* Get all existing privileges */
  const fetchPrivilegesData = async () => {
    await dynamicApiCall({
      callName: 'getPrivileges',
      navigate,
      params: { names: 'member,admin,owner', setPrivileges },
    });
  };

  const fetchMembersScreenData = async () => {
    await fetchTeamMembersByTeamUUIDPromise(teamUUID);
    await fetchContractsByAuthTokenPromise();
    await fetchTeamProjectsByTeamUUIDPromise(teamUUID);
    await fetchPrivilegesData();
  };

  const deleteTeamMembersByUUIDPromise = async () => {
    /* Retrieve the 'uuid' values from the objects and join them into a string separated by a comma */
    const teamMemberUUIDToDelete = selectedPeople
      .map((obj) => obj.uuid)
      .join(',');

    await dynamicApiCall({
      callName: 'deleteTeamMembersByUUID',
      navigate,
      params: {
        uuids: teamMemberUUIDToDelete,
        teamUUID,
        fetchMembersScreenData,
        setSelectedPeople,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* Call to get teams infos */
  const fetchTeamsByUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'getTeamsByUUID',
      navigate,
      params: {
        teamUUID,
        setCurrency,
      },
    });
  };
  /* END CALL API */

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'Members');
      fetchMembersScreenData();
      fetchTeamsByUUIDPromise();
    }
  }, [teamUUID, navigate]);

  useLayoutEffect(() => {
    if (people.length > 0) {
      const isIndeterminate =
        selectedPeople.length > 0 && selectedPeople.length < people.length;
      setChecked(selectedPeople.length === people.length);
      setIndeterminate(isIndeterminate);
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedPeople]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : people);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <SideBarLayout>
      <div className="px-4 h-full sm:px-6 lg:px-8">
        <PageHeading heading={`${people.length} Members`}>
          {userPrivileges === `owner` || userPrivileges === `admin` ? (
            <PageHeadingButton
              label="Add member"
              onClick={() => setOpenMemberSlideOver(true)}
            >
              <UserPlusIcon
                className="-ml-0.5 mr-1.5 h-5 w-5"
                aria-hidden="true"
              />
            </PageHeadingButton>
          ) : null}
        </PageHeading>
        {people.length > 0 ? (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="relative">
                  {selectedPeople.length > 0 && (
                    <div className="absolute left-14 top-0 flex h-12 items-center space-x-3 sm:left-12">
                      <button
                        type="button"
                        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                        onClick={() =>
                          deleteTeamMembersByUUIDPromise(selectedPeople)
                        }
                      >
                        Delete ({selectedPeople.length})
                      </button>
                    </div>
                  )}
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="relative px-7 sm:w-12 sm:px-6"
                        >
                          <input
                            type="checkbox"
                            className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        <th
                          scope="col"
                          className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" className="group inline-flex">
                            Name
                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          </a>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" className="group inline-flex">
                            Mail
                            <span className="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200">
                              <ChevronDownIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          </a>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" className="group inline-flex">
                            Contract
                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <ChevronDownIcon
                                className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                aria-hidden="true"
                              />
                            </span>
                          </a>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" className="group inline-flex">
                            Start date
                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <ChevronDownIcon
                                className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                aria-hidden="true"
                              />
                            </span>
                          </a>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" className="group inline-flex">
                            End date
                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <ChevronDownIcon
                                className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                aria-hidden="true"
                              />
                            </span>
                          </a>
                        </th>
                        {userPrivileges !== 'member' && (
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            <a href="#" className="group inline-flex">
                              Daily rate
                              <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                <ChevronDownIcon
                                  className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                  aria-hidden="true"
                                />
                              </span>
                            </a>
                          </th>
                        )}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <a href="#" className="group inline-flex">
                            Access
                            <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                              <ChevronDownIcon
                                className="invisible ml-2 h-5 w-5 flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
                                aria-hidden="true"
                              />
                            </span>
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-grey-900">
                      {people.map((person, index) => (
                        <tr
                          key={index}
                          className={`hover:bg-slate-100 cursor-pointer ${
                            selectedPeople.includes(person)
                              ? 'bg-gray-50'
                              : undefined
                          }`}
                          onClick={(event) => {
                            /* Check if the clicked element is not the checkbox */
                            if (event.target.tagName !== 'INPUT') {
                              handleData(person);
                            }
                          }}
                        >
                          <td className="relative px-7 sm:w-12 sm:px-6">
                            {selectedPeople.includes(person) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              value={person.email}
                              checked={selectedPeople.includes(person)}
                              onChange={(e) =>
                                setSelectedPeople(
                                  e.target.checked
                                    ? [...selectedPeople, person]
                                    : selectedPeople.filter((p) => p !== person)
                                )
                              }
                            />
                          </td>
                          <td
                            className={classNames(
                              'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                              selectedPeople.includes(person)
                                ? 'text-indigo-600'
                                : 'text-gray-900'
                            )}
                          >
                            <AvatarWithPlaceholder
                              placeholder={
                                person.firstName.charAt(0) +
                                  person.lastName.charAt(0) || '-'
                              }
                            />
                            {person.firstName || (
                              <span className="italic font-light text-gray-400">
                                firstname
                              </span>
                            )}{' '}
                            {person.lastName || (
                              <span className="italic font-light text-gray-400">
                                LASTNAME
                              </span>
                            )}
                          </td>
                          <td className="relative flex items-center whitespace-nowrap px-3 py-4 text-sm text-gray-500 group">
                            <DotStatus
                              color={
                                person.verifiedAt
                                  ? 'bg-red-500'
                                  : 'bg-green-500'
                              }
                              backgroundColor={
                                person.verifiedAt
                                  ? 'bg-red-500/10'
                                  : 'bg-green-500/10'
                              }
                            />
                            <span className="ml-2">{person.mail}</span>
                            <div className="absolute bottom-full left-1/2 -mb-2 hidden w-auto p-2 bg-black text-white text-xs leading-tight transform -translate-x-1/2 rounded opacity-0 group-hover:block group-hover:opacity-100">
                              {person.verifiedAt
                                ? `Mail verified`
                                : `We sent a link to this mail to verify the owner`}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.contract}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.startedAt}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.endedAt}
                          </td>
                          {userPrivileges !== 'member' && (
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="relative mt-2 rounded-md">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                                  <span className="text-gray-500 sm:text-sm">
                                    {currency}
                                  </span>
                                  {person.hoursPreferences === null
                                    ? teamDefaultDailyRate
                                    : person.dailyRate}
                                </div>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3" />
                              </div>
                            </td>
                          )}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.access}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex justify-center items-center">
            <EmptyStates
              displayFor={userPrivileges}
              label="No members"
              subLabel="Get started by adding a new member."
              buttonLabel="Add member"
              onClick={() => handleData(null, true)}
            >
              <UsersIcon
                className="mx-auto h-12 w-12 text-gray-400"
                aria-hidden="true"
              />
            </EmptyStates>
          </div>
        )}
      </div>

      {/* SlideOverMenu */}
      <SlideOver
        open={openMemberSlideOver}
        setOpen={updateOpenMemberSlideOver}
        title="Add member"
        defaultValues={slideOverDefaultValues}
      >
        <SlideOverFormContainer
          onSubmit={isUpdatingMember ? putTeamMembersCall : postTeamMembersCall}
        >
          <SlideOverInputContainer>
            <SlideOverInputLabel label="Email" isRequired />
            <SlideOverInputText name="mail" type="email" isRequired />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Firstname" />
            <SlideOverInputText name="firstName" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Lastname" />
            <SlideOverInputText name="lastName" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Contract type" />
            <SlideOverInputCombobox name="contractUUID" data={contracts} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Availability" />
            <CalendarPicker />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Payment rate"
              linkData={{
                to: `/settings/teams/${encodeURIComponent(
                  teamName
                )}/hours&currency`,
                label: `Manage team settings`,
              }}
            />
            <SlideOverInputNumberWithTradeBridge
              buttonLabel="Save"
              inputType="number"
              inputPrefix={teamCurrencySymbol}
              inputSuffix="/ day"
              inputPrefixTraded={teamCurrencySymbol}
              inputSuffixTraded="/ hour"
              name="hourlyRate"
              nameToWatch="hoursPreferences"
              nameToWatch2="dailyRate"
              isUpdatingUser={!!isUpdatingMember}
            />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Hours preferences"
              linkData={{
                to: `/settings/teams/${encodeURIComponent(
                  teamName
                )}/hours&currency`,
                label: `Manage team settings`,
              }}
            />
            <SlideOverInputWorkDays name="hoursPreferences" />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel label="Role" />
            <SlideOverInputCombobox name="access" data={privileges} />
          </SlideOverInputContainer>

          <SlideOverInputContainer>
            <SlideOverInputLabel
              label="Projects"
              linkData={{
                to: `/portfolio`,
                label: `Manage projects`,
              }}
            />
            <SlideOverInputItemsToggle name="projects" data={projects} />
          </SlideOverInputContainer>

          <SlideOverHandleButtons
            submitLabel={isUpdatingMember ? 'Save changes' : 'Send invite'}
          />
        </SlideOverFormContainer>
      </SlideOver>
    </SideBarLayout>
  );
}
