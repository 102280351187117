import React from 'react';
import { formatCurrency } from '../../services/utils/formatCurrency';

export const TableCellCurrencyAmount = ({
  data,
  label,
  currencySymbol = null,
  index,
  customClassName,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 px-2 text-xs text-gray-600',
        customClassName
      )}
    >
      <span>
        {formatCurrency(label)} {currencySymbol}
      </span>
    </td>
  );
};
