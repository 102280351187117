import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export default function CalendarHeader({
  handleNextMonth,
  handlePreviousMonth,
  resetDates,
  yearSelected,
  monthSelected,
  selectedView,
  canAllocate,
  updateOpenSlideOver,
}) {
  let handleNextAction;
  let handlePreviousAction;
  if (selectedView === 'Month') {
    handleNextAction = handleNextMonth;
    handlePreviousAction = handlePreviousMonth;
  }

  return (
    <header className="flex items-center justify-between px-1 md:px-4 rounded-t-md border border-gray-200 py-2 lg:flex-none">
      <div className="flex items-center">
        <div className="relative flex space-x-2 rounded-md items-stretch">
          <button
            type="button"
            className="border rounded-md bg-white border-gray-300 px-3 md:px-6 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative"
            onClick={() => resetDates(selectedView)}
          >
            Today
          </button>

          <div className="flex items-center rounded-md bg-white shadow-sm md:items-stretch">
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
              onClick={handlePreviousAction}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
              onClick={handleNextAction}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>

        <h1 className="text-base font-semibold leading-6 text-gray-900 ml-2">
          <time
            dateTime={`${yearSelected.start}-${monthSelected.start
              .toString()
              .padStart(2, '0')}`}
          >
            {monthSelected.start === monthSelected.end
              ? /* Only one month selected */
                new Date(
                  yearSelected.start,
                  monthSelected.start - 1
                ).toLocaleDateString('en-US', {
                  month: 'long',
                  year: 'numeric',
                })
              : /* Range of months selected */
                `${new Date(
                  yearSelected.start,
                  monthSelected.start - 1
                ).toLocaleDateString('en-US', { month: 'long' })}${
                  yearSelected.start === yearSelected.end
                    ? ` - ${new Date(
                        yearSelected.end,
                        monthSelected.end - 1
                      ).toLocaleDateString('en-US', {
                        month: 'long',
                        year: 'numeric',
                      })}`
                    : ` ${yearSelected.start} - ${new Date(
                        yearSelected.end,
                        monthSelected.end - 1
                      ).toLocaleDateString('en-US', {
                        month: 'long',
                        year: 'numeric',
                      })}`
                }`}
          </time>
        </h1>
      </div>
      <div className="lg:flex lg:items-center lg:justify-between">
        <div className="min-w-0 flex-1">
          <div className="flex items-center justify-between">
            <div className="tooltip">
              <button
                type="button"
                onClick={() => (canAllocate ? updateOpenSlideOver(true) : null)}
                className={`ml-6 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
                  canAllocate
                    ? `bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`
                    : `bg-gray-400 cursor-not-allowed`
                }`}
              >
                Add allocation
              </button>
              {!canAllocate && (
                <span className="tooltip-text">
                  To allocate, switch to your own view
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
