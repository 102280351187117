/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { AvatarWithPlaceholder } from './AvatarWithPlaceholder';

export const StackedList = ({ items, clientName }) => {
  const navigate = useNavigate();

  return (
    <ul
      role="list"
      className="cursor-pointer divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
    >
      {items.map((item) => (
        <li
          key={item.uuid}
          className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
          type="button"
          onClick={() =>
            navigate(
              `/portfolio/${encodeURIComponent(
                clientName
              )}/${encodeURIComponent(item.name)}`,
              {
                state: {
                  initialProject: item,
                },
              }
            )
          }
        >
          <div className="flex min-w-0 gap-x-4">
            <AvatarWithPlaceholder
              size="lg"
              placeholder={item.name.charAt(0)}
              className={item.color || `bg-gray-400`}
            />
            <div className="min-w-0 flex-auto flex items-center justify-center">
              <p className="truncate text-sm font-semibold leading-6 text-gray-900 ">
                {item.name}
              </p>
            </div>
          </div>
          <div className="flex shrink-0 items-center gap-x-4">
            <div className="hidden sm:flex sm:flex-col sm:items-end">
              <p className="mt-1 text-xs leading-5 text-gray-500">
                Last update{' '}
                <time dateTime={item.updated_at}>{item.updated_at}</time>
              </p>
            </div>
            <ChevronRightIcon
              className="h-5 w-5 flex-none text-gray-400"
              aria-hidden="true"
            />
          </div>
        </li>
      ))}
    </ul>
  );
};
