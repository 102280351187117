import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';

export const ParentCardWithChildList = ({
  data,
  onParentClick,
  onChildClick,
}) => (
  <ul role="list" className="grid grid-cols-1 gap-y-4">
    {data.map((parent) => (
      <li
        key={parent.uuid}
        className="overflow-hidden rounded-xl border border-slate-300 bg-white"
      >
        <button
          type="button"
          className="w-full group"
          onClick={() => onParentClick(parent)}
        >
          <div className="flex items-center gap-x-4 border-b hover:bg-slate-100 border-gray-900/5 bg-slate-200 p-6">
            <AvatarWithPlaceholder
              placeholder={parent.name.charAt(0)}
              size="lg"
              rounded="lg"
              className={parent.color || `bg-indigo-500`}
            />
            <div className="text-left">
              <div className="text-sm font-medium leading-6 text-gray-900 group-hover:underline">
                {parent.name}
              </div>
              <div className="text-sm font-light text-gray-700">
                Projects : {parent.nbProjects}
              </div>
            </div>
          </div>
        </button>

        {parent.nbProjects === 0 && (
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            <div className="flex justify-between gap-x-4 py-3">
              <p className="text-left text-gray-700">
                No project attached to this client.
              </p>
            </div>
          </dl>
        )}
        {parent.projects.length !== 0 && (
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
            {parent.projects.map((child) => (
              <button
                key={child.uuid}
                type="button"
                className="w-full group"
                onClick={() => onChildClick({ parent, child })}
              >
                <div className="flex justify-between gap-x-4 py-3">
                  <dt className="flex">
                    <AvatarWithPlaceholder
                      size="xs"
                      placeholder={child.name.charAt(0)}
                      className={child.color || `bg-gray-400`}
                    />
                    <p className="text-left text-gray-700 group-hover:text-gray-800 group-hover:font-medium">
                      {child.name}
                    </p>
                  </dt>
                  <dd>
                    <ChevronRightIcon
                      className="mx-auto h-4 w-4 text-gray-500 group-hover:text-gray-700 group-hover:font-medium"
                      aria-hidden="true"
                    />
                  </dd>
                </div>
              </button>
            ))}
          </dl>
        )}
      </li>
    ))}
  </ul>
);
