import { calculateAllocationAmount } from './calculateAllocationAmount';

export const calculateTotalAllocationsAmountForMembers = (
  projects,
  budget,
  teamData
) => {
  const memberAllocations = {};

  projects.forEach((project) => {
    project.allocations.forEach((allocation) => {
      const memberId = allocation.user.uuid;
      const amount = calculateAllocationAmount(
        allocation,
        project,
        budget,
        teamData
      );

      if (memberAllocations[memberId]) {
        memberAllocations[memberId].amount += amount;
      } else {
        memberAllocations[memberId] = {
          label: `${allocation.user.firstname} ${allocation.user.lastname}`,
          amount,
        };
      }
    });
  });

  return Object.values(memberAllocations);
};
