import { useState } from 'react';

export const CreateTeamForm = ({ postTeamsPromise }) => {
  const [formData, setFormData] = useState({});

  // Function to handle form submission
  const handleSubmit = () => {
    if (formData.name) {
      postTeamsPromise(formData.name);
    }
  };

  // Function to handle key press events on the input field
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(); // Call the submit function when Enter key is pressed
    }
  };

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <div className="space-y-6">
        <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label
            htmlFor="teamName"
            className="block text-xs font-medium text-gray-900"
          >
            Team name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="My super agency"
            onChange={(e) =>
              setFormData({ ...formData, [e.target.name]: e.target.value })
            }
            onKeyDown={handleKeyPress}
            required
          />
        </div>

        <div>
          <button
            type="submit"
            onClick={handleSubmit}
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
