import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

export const Breadcrumbs = ({ pages }) => (
  <nav className="flex" aria-label="Breadcrumb">
    <ol role="list" className="flex items-center space-x-4">
      {pages.map((page, index) => (
        <li key={`${page.name + index}`}>
          <div className="flex items-center">
            {index > 0 && ( // Check if it's not the first iteration
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-900"
                aria-hidden="true"
              />
            )}
            {page.href === null ? (
              <p
                className={`text-sm font-medium text-gray-900 ${
                  index > 0 ? 'ml-4' : ''
                }`}
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </p>
            ) : (
              <Link
                to={page.href}
                state={
                  page.initialClient
                    ? { initialClient: page.initialClient }
                    : null
                }
                className={`text-sm font-medium text-gray-900 hover:text-gray-700 ${
                  index > 0 ? 'ml-4' : ''
                }`}
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            )}
          </div>
        </li>
      ))}
    </ol>
  </nav>
);
