import { attachMembersToAllocations } from './attachMembersToAllocations';

export const attachAllocationsToProjects = async (
  projects,
  budget,
  teamUUID,
  memberCache,
  navigate,
  dynamicApiCall
) => {
  const updatedProjects = [];

  for (const project of projects) {
    const getTeamMembersAllocationsData = await dynamicApiCall({
      callName: 'getTeamMembersAllocations',
      navigate,
      params: {
        teamUUID,
        from: new Date(budget.startedAt).toISOString().split('T')[0],
        to: new Date(budget.endedAt).toISOString().split('T')[0],
        projectUUID: project.uuid,
        getAllAllocationsByProject: true,
        returnData: true,
      },
    });

    project.allocations = await attachMembersToAllocations(
      getTeamMembersAllocationsData.allocationsByProject || [],
      teamUUID,
      memberCache,
      navigate
    );

    updatedProjects.push(project);
  }

  return updatedProjects;
};
