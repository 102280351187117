export const putTeamClientsLogic = async (datas) => {
  const {
    updateOpenSlideOver,
    updateOpenNotification,
    updateNotificationData,
    callData,
    navigate,
    reset,
    resetValues,
    name,
    fetchTeamClientsByNamePromise,
  } = datas;
  navigate(`/portfolio/${encodeURIComponent(callData.payload)}`);

  if (fetchTeamClientsByNamePromise) {
    await fetchTeamClientsByNamePromise(name);
  }

  updateOpenNotification(true);
  updateNotificationData({ ...callData });
  updateOpenSlideOver(false);
  reset({ ...resetValues });
};
