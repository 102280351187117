export const putConfirmTeamTransferOwnershipLogic = async (datas) => {
  const {
    navigate,
    navigateTo,
    updateOpenModal,
    updateOpenNotification,
    updateNotificationData,
    callData,
  } = datas;

  navigateTo({ navigate, routeName: `/portfolio` });

  updateOpenNotification(true);
  updateNotificationData({ ...callData });
  updateOpenModal(false);
};
