import React, { Fragment, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';

export const Notification = ({
  open,
  type,
  setOpen,
  title,
  description,
  primaryAction = null,
  primaryActionOnClick = null,
  secondaryAction = null,
  secondaryActionOnClick = null,
}) => {
  const successDuration = 4000;
  const errorDuration = 10000;

  useEffect(() => {
    if (open) {
      const duration = type === 'success' ? successDuration : errorDuration;
      const timer = setTimeout(() => setOpen(false), duration);
      return () => clearTimeout(timer);
    }
  }, [open, type, setOpen]);

  const millisecondsToSeconds = (ms) => `${ms / 1000}s`;
  const animationDuration = millisecondsToSeconds(
    type === 'success' ? successDuration : errorDuration
  );

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-60 pointer-events-none fixed inset-0 flex items-start justify-end px-4 py-6 sm:p-6"
      >
        <div className="flex w-full flex-col items-end space-y-4">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={open}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {type === 'success' && (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    )}
                    {type === 'error' && (
                      <ExclamationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{description}</p>
                    <div className="mt-3 flex space-x-7">
                      {primaryAction && (
                        <button
                          type="button"
                          className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={(e) => primaryActionOnClick(e)}
                        >
                          {primaryAction}
                        </button>
                      )}
                      {secondaryAction && (
                        <button
                          type="button"
                          className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={(e) => secondaryActionOnClick(e)}
                        >
                          {secondaryAction}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`relative h-1 ${
                  type === 'success' ? `bg-green-600` : `bg-red-600`
                }`}
                style={{
                  animation: `countdown ${animationDuration} linear forwards`,
                }}
              />
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
