export const postPasswordResetTokenByUserUUIDLogic303 = async (datas) => {
  const {
    navigate,
    navigateTo,
    updateOpenNotification,
    updateNotificationData,
    callData,
  } = datas;

  updateOpenNotification(true);
  updateNotificationData({
    ...callData,
    primaryAction: 'Signup ?',
    primaryActionOnClick: (e) => {
      navigateTo({ navigate, routeName: `/signup` });
    },
  });
};
