import { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';
import { formatDateInWords } from '../../services/utils/momentUtils';
import { attachMembersToAllocations } from '../../services/utils/budgets/attachMembersToAllocations';
import { calculateAllocationAmount } from '../../services/utils/budgets/calculateAllocationAmount';
import { formatCurrency } from '../../services/utils/formatCurrency';

export const Feeds = ({ filtersData = null, activities = null, teamData }) => {
  const [activity, setActivity] = useState([]);
  const memberCache = useRef({});

  const attachAllocationsWithMembers = async () => {
    const updatedActivities = await attachMembersToAllocations(
      activities,
      teamData.teamUUID,
      memberCache,
      null // Not using navigation in this case
    );

    setActivity(updatedActivities);
  };

  useEffect(() => {
    if (activities.length > 0) {
      attachAllocationsWithMembers(); // Fetch and attach members details when activities are loaded
    }
  }, [activities]);

  useEffect(() => {
    if (filtersData.length > 0 && activity.length > 0) {
      const showUser = [];
      /* Retrieve from the categoryFilters component who is selected or not and extract it */
      filtersData.forEach((filter) => {
        filter.options.forEach((option) => {
          showUser.push({
            uuid: option.uuid,
            isSelected: option.isSelected,
          });
        });
      });

      /* For each activity check if any user match the filters array, and retrieve the isSelected value and add it to the state */
      const updatedActivity = activity.map((activityItem) => {
        if (activityItem.type === 'allocation') {
          const teamMemberUserUUID = activityItem.user.teamMemberUUID;
          /* The some method iterates through each element of the showUser array. */
          const isSelected = showUser.some(
            (user) => user.uuid === teamMemberUserUUID && user.isSelected
          );

          /* Create a new object with the isVisible property */
          return {
            ...activityItem,
            isVisible: isSelected,
          };
        }

        return activityItem; /* Return unchanged items for other types */
      });

      /* Update the state with the modified activity array */
      setActivity(updatedActivity);
    }
  }, [filtersData]);

  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {activity.map((activityItem, activityItemIdx) =>
          // Check if activityItem.isVisible is true before rendering
          activityItem.isVisible ? (
            <li key={activityItemIdx}>
              <div className="relative pb-8">
                {activityItemIdx !== activity.length - 1 ? (
                  <span
                    className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  {activityItem.type === 'allocation' ? (
                    <>
                      <div className="relative">
                        <div className="-mx-2">
                          <AvatarWithPlaceholder
                            placeholder={
                              (activityItem.user.firstname?.charAt(0) || '') +
                              (activityItem.user.lastname?.charAt(0) || '')
                            }
                            size="md"
                          />
                        </div>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div>
                          <div className="text-sm flex">
                            <p className="text-sm text-gray-500">
                              <Link
                                to="/members"
                                className="font-medium text-gray-900"
                              >
                                {activityItem.user.firstname}{' '}
                                {activityItem.user.lastname}{' '}
                              </Link>
                              on{' '}
                              {formatDateInWords(
                                activityItem.allocation.allocationDate
                              )}
                            </p>
                          </div>

                          <div className="flex">
                            <p className="mt-0.5 self-center text-sm text-gray-500">
                              {activityItem.allocation.allocationHours} hours -{' '}
                              {formatCurrency(
                                calculateAllocationAmount(
                                  activityItem,
                                  null,
                                  null,
                                  teamData
                                )
                              )}{' '}
                              {teamData.teamCurrencySymbol}
                            </p>
                            <span className="ml-2 mr-0.5">
                              {activityItem.allocation.tags.map((tag) =>
                                tag.name ? (
                                  <Fragment key={tag.name}>
                                    <a
                                      href={tag.href}
                                      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                    >
                                      {tag.color && (
                                        <span
                                          className={`h-3 w-3 rounded-full ${tag.color} inline-block`}
                                        />
                                      )}
                                      {tag.name}
                                    </a>
                                  </Fragment>
                                ) : null
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="mt-2 text-sm text-gray-700">
                          <p>{activityItem.allocation.notes}</p>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};
