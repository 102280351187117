import React, { useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Header } from '../molecules/Header';
import { FormTitle } from '../atoms/FormTitle';
import { Container } from '../atoms/Container';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { NotificationContext } from '../../contexts/NotificationContext';
import { RequiredAsterisk } from '../atoms/RequiredAsterisk';

export default function ResetPasswordScreen() {
  const { resetToken, mail } = useParams();
  const navigate = useNavigate();
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  /* Check if mail is present, if it is present this means user has been invited to team */
  const isMailPresent = !!mail;

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      dynamicApiCall({
        callName: 'getAuthTokenValidity',
        navigate,
        params: {
          authToken,
        },
      });
    }
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = watch('password'); // Watch the value of the password field to compare with confirmation

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      updateNotificationData({
        message: 'Passwords do not match',
        type: 'error',
      });
      updateOpenNotification(true);
      return;
    }

    /* Means we are in case of user invited in team that need to set up password and session */
    if (isMailPresent) {
      await dynamicApiCall({
        callName: 'postPasswordByInvitedUser',
        navigate,
        params: {
          navigate,
          resetToken,
          mail,
          password: data.password,
          updateOpenNotification,
          updateNotificationData,
        },
      });
    } else {
      await dynamicApiCall({
        callName: 'putPasswordByResetToken',
        navigate,
        params: {
          resetToken,
          password: data.password,
          updateOpenNotification,
          updateNotificationData,
        },
      });
    }
  };

  return (
    <>
      <Header>
        New to Synkros?{' '}
        <Link
          to="/signup"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Try for free
        </Link>
      </Header>

      <div className="flex h-5/6 items-center">
        <Container>
          <div className="text-center">
            <FormTitle
              text={
                isMailPresent
                  ? `Define password to join your team`
                  : `Change your password`
              }
            />
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    New password
                    <RequiredAsterisk />
                    <p className="text-xs font-light text-gray-400">
                      (Min. 8 characters with a letter and a number)
                    </p>
                  </label>
                </div>
                <div
                  className={`mt-2 ${errors.password ? 'border-red-500' : ''}`}
                >
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    {...register('password', {
                      required: 'Password is required',
                      pattern: {
                        value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
                        message:
                          'Password must be at least 8 characters long, with a letter and a number',
                      },
                    })}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      errors.password ? 'ring-red-500' : ''
                    }`}
                  />
                  {errors.password && (
                    <span className="text-red-500 text-xs">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Confirm new password
                    <RequiredAsterisk />
                    <p className="text-xs font-light text-gray-400">
                      (Min. 8 characters with a letter and a number)
                    </p>
                  </label>
                </div>
                <div
                  className={`mt-2 ${
                    errors.confirmPassword ? 'border-red-500' : ''
                  }`}
                >
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="new-password"
                    {...register('confirmPassword', {
                      validate: (value) =>
                        value === password || 'The passwords do not match',
                    })}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      errors.confirmPassword ? 'ring-red-500' : ''
                    }`}
                  />
                  {errors.confirmPassword && (
                    <span className="text-red-500 text-xs">
                      {errors.confirmPassword.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {isMailPresent ? 'Set password' : 'Reset password'}
                </button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
}
