import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

export default function WeekView({
  weekDays,
  updateIsDraggingEvent,
  isDraggingEvent,
  onEventClick,
  onDragEnd,
  isMouseDown,
  setIsMouseDown,
  handleMouseDown,
  handleMouseOver,
  handleFocus,
  startDayIndex,
  endDayIndex,
}) {
  const container = useRef(null);
  const containerNav = useRef(null);
  // const [updatedWeekDays, setUpdatedWeekDays] = useState(weekDays);

  // useEffect(() => {
  //   if (weekDays.length === 7) {
  //     // setUpdatedWeekDays(weekDays);
  //   }
  // }, [weekDays]);

  return (
    <DragDropContext
      onDragStart={() => {
        updateIsDraggingEvent(true);
        setIsMouseDown(false);
      }}
      onDragEnd={(result) => onDragEnd(result)}
    >
      <div className="flex h-full flex-col">
        <div
          ref={container}
          className="isolate flex flex-auto flex-col overflow-auto bg-white"
        >
          <div
            style={{ width: `${weekDays.length * 100}%` }}
            className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
          >
            <div
              ref={containerNav}
              className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
            >
              <div className="grid grid-cols-{weekDays.length} text-sm leading-6 text-gray-500 sm:hidden">
                {weekDays.map((singleDay, index) => (
                  <button
                    key={index}
                    type="button"
                    className="flex flex-col items-center pb-3 pt-2"
                  >
                    {singleDay.day}
                    <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">
                      {/* Replace the day number with the appropriate value */}
                      {singleDay.date.slice(-2)}
                    </span>
                  </button>
                ))}
              </div>

              <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                <div className="col-end-1 w-14" />
                {/* Render the days of the week */}
                {weekDays.map((singleDay, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center py-3"
                  >
                    <span>
                      {singleDay.day}{' '}
                      <span className="items-center justify-center font-semibold text-gray-900">
                        {/* Replace the day number with the appropriate value */}
                        {singleDay.date.slice(-2)}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-auto">
              <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Calendar */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                  style={{
                    gridTemplateRows: '1.75rem repeat(1, minmax(0, 1fr)) auto',
                    minHeight: '500px',
                  }}
                >
                  {weekDays.map((singleDay, index) => {
                    const isWorkingDay = singleDay.maxHours !== 0;
                    const isHoliday =
                      singleDay.isHoliday && singleDay.isHoliday.length > 0;

                    return (
                      <div
                        key={index}
                        className="border-r-2 border-gray-500"
                        style={{
                          minHeight: 'inherit',
                          background: !isWorkingDay
                            ? 'repeating-linear-gradient(45deg, transparent, transparent 7px, rgba(255, 255, 255, .5) 7px, rgba(255, 255, 255, .5) 17px)'
                            : '',
                          backgroundColor: !isWorkingDay ? '#E5E7EB' : '',
                          /* Non working days are not clickable */
                          pointerEvents: !isWorkingDay ? 'none' : '',
                        }}
                      >
                        <Droppable
                          droppableId={singleDay.date}
                          isDropDisabled={isHoliday || !isWorkingDay}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className={`h-full relative ${
                                snapshot.isDraggingOver && 'bg-yellow-200'
                              }
                             ${
                               isMouseDown &&
                               index >= Math.min(startDayIndex, endDayIndex) &&
                               index <= Math.max(startDayIndex, endDayIndex)
                                 ? 'bg-blue-200'
                                 : ''
                             }
                            
                            `}
                              role="button"
                              tabIndex={0}
                              onMouseDown={(event) => {
                                /* Check if the left mouse button was clicked */
                                if (event.button === 0) {
                                  handleMouseDown(index);
                                }
                              }}
                              onMouseOver={() => handleMouseOver(index)}
                              onFocus={() => handleFocus(index)}
                              data-open-modal="true"
                            >
                              {isHoliday && (
                                <div className="select-none">
                                  <ul>
                                    {singleDay.isHoliday.map(
                                      (holiday, index) => (
                                        <li key={index}>
                                          <strong>{holiday.name}</strong>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                              {/* Render events */}
                              {singleDay.events && singleDay.events.length > 0
                                ? singleDay.events
                                    .filter(
                                      (event) =>
                                        event.isVisible === undefined ||
                                        event.isVisible
                                    )
                                    .sort((a, b) => a.position - b.position)
                                    .map((event, eventIndex) => {
                                      const ratio = (event.hours / 24) * 100;
                                      const eventHeight = 20;
                                      const totalHeight = eventHeight + ratio;
                                      const roundedTotalHeight =
                                        Math.round(totalHeight);

                                      return (
                                        <Draggable
                                          key={eventIndex}
                                          draggableId={`${singleDay.date}-${eventIndex}`} // Use a unique identifier for each event
                                          index={eventIndex}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className={`bg-black select-none
                                                ${
                                                  snapshot.isDragging
                                                    ? 'shadow-2xl bg-blue-200'
                                                    : 'shadow-md bg-red-500'
                                                }`}
                                              key={eventIndex}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              role="button"
                                              tabIndex={0}
                                              onMouseDown={(e) => {
                                                e.stopPropagation();
                                              }}
                                              onClick={() => {
                                                onEventClick({
                                                  event,
                                                  day: singleDay,
                                                });
                                              }}
                                              onKeyDown={() => {}}
                                              data-open-modal="false"
                                            >
                                              <div
                                                className={`p-2 rounded-md flex items-center ${
                                                  event.color
                                                    ? event.color
                                                    : 'bg-blue-500'
                                                }`}
                                                style={{
                                                  height: `${roundedTotalHeight}px`,
                                                }}
                                              >
                                                <p className="text-white">
                                                  {event.name} - {event.hours}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                : null}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}
