export const getTeamsByUUIDLogic = async (datas) => {
  const {
    setCurrentTeam,
    callData,
    setCurrency,
    setCheckboxesOptions,
    checkboxesOptions,
  } = datas;

  if (callData && callData[0]) {
    if (setCurrentTeam) {
      setCurrentTeam(callData[0]);
    }
    if (setCurrency) {
      setCurrency(callData[0].currencySymbol);
    }

    if (checkboxesOptions && setCheckboxesOptions) {
      const teamDefaultHoursPreferences = JSON.parse(
        callData[0].defaultHoursPreferences
      );
      let updatedCheckboxesOptions = null;
      const defaultOptions = [
        { id: 1, name: 'Monday', isSelected: false, hoursPreferences: 0 },
        {
          id: 2,
          name: 'Tuesday',
          isSelected: false,
          hoursPreferences: 0,
        },
        {
          id: 3,
          name: 'Wednesday',
          isSelected: false,
          hoursPreferences: 0,
        },
        {
          id: 4,
          name: 'Thursday',
          isSelected: false,
          hoursPreferences: 0,
        },
        { id: 5, name: 'Friday', isSelected: false, hoursPreferences: 0 },
        {
          id: 6,
          name: 'Saturday',
          isSelected: false,
          hoursPreferences: 0,
        },
        {
          id: 7,
          name: 'Sunday',
          isSelected: false,
          hoursPreferences: 0,
        },
      ];

      /* Set default hours preferences to the array */
      updatedCheckboxesOptions = defaultOptions.map((option) => {
        const dayName = option.name.toLowerCase();

        if (
          teamDefaultHoursPreferences[dayName] !== undefined &&
          parseInt(teamDefaultHoursPreferences[dayName]) !== 0
        ) {
          option.hoursPreferences = teamDefaultHoursPreferences[dayName];
          option.isSelected = true;
        } else {
          option.hoursPreferences = 0;
          option.isSelected = false;
        }
        return option;
      });

      setCheckboxesOptions(updatedCheckboxesOptions);
    }
  }
};
