import React, { useEffect, useState } from 'react';

export const ActionPanelWithInput = ({
  title,
  description,
  buttonLabel,
  onChange,
  onClick,
  value = null,
  inputType = 'name',
}) => {
  const [inputValue, setInputValue] = useState('');

  /* Handler to update the input value */
  const handleInputChange = (event) => {
    setInputValue(event.target.value);

    if (onChange) {
      onChange(
        event.target.value
      ); /* Passes the value to the parent component */
    }
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{description}</p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault(); /* Prevents the default form submission behavior */
            onClick(
              inputValue
            ); /* Passes the input value to the click handler */
          }}
          className="mt-5 sm:flex sm:items-center"
        >
          <div className="w-full">
            <label htmlFor={inputType} className="sr-only">
              {inputType}
            </label>
            <input
              type={inputType}
              name={inputType}
              id={inputType}
              onChange={handleInputChange}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={inputValue}
            />
          </div>
          <button
            type="submit"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto"
          >
            {buttonLabel}
          </button>
        </form>
      </div>
    </div>
  );
};
