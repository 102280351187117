import { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../molecules/Header';

import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { Container } from '../atoms/Container';
import { NotificationContext } from '../../contexts/NotificationContext';

export default function ConfirmTransferOwnershipScreen() {
  const { transferOwnershipToken } = useParams();
  const authToken = localStorage.getItem('authToken');

  const navigate = useNavigate();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (authToken && transferOwnershipToken) {
      fetchPutConfirmTeamTransferOwnership(transferOwnershipToken);
    }
  }, [authToken, transferOwnershipToken]);

  useEffect(() => {
    if (!authToken) {
      setLoading(false);
      setError(true);
      setErrorMessage({
        title: 'Error',
        description: 'You are not logged in.',
      });
    }
  }, []);

  /* BEGINNING CALL API */
  /* Check if token are correct and update ownership */
  const fetchPutConfirmTeamTransferOwnership = async (
    transferOwnershipToken
  ) => {
    await dynamicApiCall({
      callName: 'putConfirmTeamTransferOwnership',
      navigate,
      params: {
        setError,
        setErrorMessage,
        transferOwnershipToken,
        setLoading,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };
  /* END CALL API */

  return (
    <>
      <Header />
      <div className="flex h-5/6 items-center">
        <Container>
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-indigo-500 border-solid" />
            </div>
          ) : error ? (
            <div className="flex justify-center">
              <div>{errorMessage.title}</div>
              <div>{errorMessage.description}</div>
            </div>
          ) : (
            <h1>Ownership transfered with success</h1>
          )}
        </Container>
      </div>
    </>
  );
}
