export const PageHeadingButton = ({
  label,
  onClick,
  buttonColors = 'text-white bg-indigo-600',
  hoverEffect = 'hover:bg-indigo-500',
  children,
  disabled = false,
}) => (
  <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
    <button
      disabled={disabled}
      type="button"
      className={`
        flex rounded-md
        ${buttonColors}
        ${!disabled ? hoverEffect : 'cursor-not-allowed'}
        px-3 py-2 text-center text-sm font-semibold shadow-sm
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
      `}
      onClick={onClick}
    >
      {children}
      {label}
    </button>
  </div>
);
