export const postLoginLogic303 = async (datas) => {
  const {
    navigate,
    navigateTo,
    updateOpenNotification,
    updateNotificationData,
    callData,
  } = datas;

  updateOpenNotification(true);

  updateNotificationData({
    ...callData,
    primaryAction:
      callData.redirection === `/resetPassword` ? `Set password ?` : `Signup ?`,
    primaryActionOnClick: () => {
      navigateTo({ navigate, routeName: callData.redirection });
    },
  });
};
