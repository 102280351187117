export const defaultLogic403 = async (datas) => {
  const {
    updateOpenModal,
    navigate,
    navigateTo,
    routeAllocations,
    routeUnauthorized,
    updateNotificationData,
    updateOpenNotification,
    callData,
    currentRoute,
  } = datas;

  if (updateOpenModal) {
    updateOpenModal(false);
  }
  if (updateNotificationData && updateOpenNotification) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
  }
  if (navigate) {
    if (currentRoute === '/allocations') {
      navigateTo({ navigate, routeName: routeUnauthorized });
      return;
    }
    navigateTo({ navigate, routeName: routeAllocations });
  }
};
