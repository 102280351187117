export const putTeamMembersAllocationsLogic = async (datas) => {
  const {
    callData,
    reset,
    view,
    monthSelected,
    yearSelected,
    startWeekSelected,
    endWeekSelected,
    fetchDataForMonth,
    fetchDataForWeek,
    updateOpenSlideOver,
    updateAllocationToUpdateData,
    updateOpenNotification,
    updateNotificationData,
    returnData,
    teamUUID,
    fetchAnomalies,
  } = datas;

  if (callData) {
    if (returnData) {
      return callData;
    }
    updateOpenSlideOver(false);

    if (reset) {
      reset();
    }

    if (fetchAnomalies) {
      fetchAnomalies({ teamUUID });
    }

    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
      });
    }

    if (updateAllocationToUpdateData) {
      updateAllocationToUpdateData(null);
    }
    /* Re-fetch data after successful post or after error 500 */
    if (view === 'Month') {
      fetchDataForMonth({
        month: {
          start: monthSelected.start,
          end: monthSelected.end,
        },
        year: {
          start: yearSelected.start,
          end: yearSelected.end,
        },
        view,
      });
    } else if (view === 'Week') {
      fetchDataForWeek({
        start: startWeekSelected,
        end: endWeekSelected,
        view,
      });
    }
  }
};
