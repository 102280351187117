export const deleteTeamBudgetsByUUIDLogic = async (datas) => {
  const {
    navigate,
    updateOpenModal,
    navigateTo,
    routeBudget,
    updateNotificationData,
    updateOpenNotification,
    callData,
  } = datas;

  if (callData) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
    updateOpenModal(false);

    navigateTo({ navigate, routeName: routeBudget });
  }
};
