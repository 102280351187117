export function renderTextWithLinks(text) {
  const urlRegex =
    /(\b(?:http[s]?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|]|\bwww\.[a-z0-9\-.]+[a-z]{2,}|[a-z0-9\-.]+\.[a-z]{2,}\b(?!@))/gi;

  // Splitting the text by space to capture the words and potential URLs
  const parts = text.split(/\s+/);

  // Use flatMap to process each part and ensure spaces are maintained
  const elements = parts.flatMap((part, index) => {
    if (part.match(urlRegex)) {
      let urlPart = part;
      // Prepend https:// if the URL doesn't contain a scheme
      if (!/^http[s]?:\/\//.test(urlPart) && !/^ftp:\/\//.test(urlPart)) {
        urlPart = `https://${urlPart}`;
      }
      // Return the link element for URLs with a following space
      return [
        <a
          href={urlPart}
          key={index}
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600 hover:underline"
        >
          {part}
        </a>,
        ' ', // Add a space after each element
      ];
    }
    // Return the text part followed by a space
    return [<span key={index}>{part}</span>, ' ']; // Ensure a space is rendered after each part
  });

  return elements; // Directly return the array of elements
}
