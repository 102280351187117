import { useEffect, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// TODO: Fix UI when Modal is not height enough, popup the options
// FIXME: Reset tag creation after a click
export const Comboboxes = ({
  data,
  dataType,
  label,
  onClick,
  canCreateOption = false,
  onCreateOptionClick = null,
  initialValue = null,
}) => {
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const filteredData =
    query === ''
      ? data
      : data.filter((item) => {
          // Use a switch statement to filter based on dataType
          switch (dataType) {
            case 'name':
              return item.name
                .replace(/\s/g, '')
                .toLowerCase()
                .includes(query.replace(/\s/g, '').toLowerCase());
            case 'currency':
              return item.name
                .replace(/\s/g, '')
                .toLowerCase()
                .includes(query.replace(/\s/g, '').toLowerCase());
            case 'firstnameAndLastname':
              const fullName = `${item.firstName} ${item.lastName}`;
              return fullName.toLowerCase().includes(query.toLowerCase());
            default:
              return true; // No filtering by default
          }
        });

  useEffect(() => {
    /* Check if the initialValue is an object with the 'uuid' property */
    if (initialValue?.uuid) {
      const matchedObject = data.find(
        (item) => item.uuid === initialValue.uuid
      );

      /* Update the selectedItem state only if the matchedObject exists */
      if (matchedObject) {
        setSelectedItem(matchedObject);
      }
    } else {
      /* Update the selectedItem directly if initialValue doesn't have 'uuid' */
      setSelectedItem(initialValue);
    }
  }, [data, initialValue]);

  return (
    <Combobox as="div" value={selectedItem} onChange={setSelectedItem}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </Combobox.Label>
      <div className="relative mt-2">
        <div className="relative">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item) => {
              if (dataType === `name`) {
                return item?.name;
              }
              if (dataType === `currency`) {
                if (item?.format && item?.name) {
                  return `(${item?.format}) ${item?.name}`;
                }
              }
              if (item?.firstName && item?.lastName) {
                return `${item?.firstName} ${item?.lastName}`;
              }
              return ``;
            }}
          />
          <Combobox.Button className="absolute inset-0 flex items-center justify-end rounded-md px-2 focus:outline-none">
            <span className="h-5 w-5 text-gray-400" aria-hidden="true">
              <ChevronUpDownIcon />
            </span>
          </Combobox.Button>
        </div>

        {filteredData.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredData.map((item, index) => (
              // TODO: Handle "enter" key press to select item
              <Combobox.Option
                key={index}
                value={item}
                onClick={() => {
                  onClick(item);
                }}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 px-4',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'ml-4 block truncate',
                        selected && 'font-semibold'
                      )}
                    >
                      {dataType === `name` && item.color && (
                        <span
                          className={`h-3 w-3 rounded-full ${item.color} inline-block mr-2`}
                        />
                      )}
                      {dataType === `name` ? `${item.name}` : null}
                      {dataType === `currency`
                        ? `(${item.format}) ${item.name}`
                        : null}
                      {dataType !== `name` && dataType !== `currency`
                        ? `${item.firstName} ${item.lastName}`
                        : null}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}

            {canCreateOption && query ? (
              <Combobox.Option
                key="createOption"
                value={query}
                onClick={() => {
                  onCreateOptionClick(query);
                  setQuery('');
                }}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 px-4',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ selected }) => (
                  <div className="flex">
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />

                    <span
                      className={classNames(
                        'block truncate ml-2',
                        selected && 'font-semibold'
                      )}
                    >
                      Create new tag {query ? `"${query}"` : null}
                    </span>
                  </div>
                )}
              </Combobox.Option>
            ) : null}
          </Combobox.Options>
        )}

        {filteredData.length === 0 && canCreateOption && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {canCreateOption && query ? (
              <Combobox.Option
                key="createOption"
                value={query}
                onClick={() => {
                  onCreateOptionClick(query);
                  setQuery('');
                }}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 px-4',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ selected }) => (
                  <div className="flex">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />

                    <span
                      className={classNames(
                        'block truncate ml-2',
                        selected && 'font-semibold'
                      )}
                    >
                      Create a new tag {query ? `"${query}"` : null}
                    </span>
                  </div>
                )}
              </Combobox.Option>
            ) : null}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
