import { PlusIcon, ArrowUturnLeftIcon } from '@heroicons/react/20/solid';

export const EmptyStates = ({
  label,
  subLabel,
  buttonLabel,
  displayFor,
  onClick,
  children,
  iconBack,
  shouldDisplayButton = true,
}) => (
  <div className="text-center">
    {children}
    <h3 className="mt-2 text-sm font-semibold text-gray-900">{label}</h3>
    <p className="mt-1 text-sm text-gray-500">{subLabel}</p>
    {(displayFor === 'owner' || displayFor === 'admin') &&
    shouldDisplayButton ? (
      <div className="mt-6">
        <button
          type="button"
          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={onClick}
        >
          {iconBack ? (
            <ArrowUturnLeftIcon
              className="-ml-0.5 mr-1.5 h-4 w-4"
              aria-hidden="true"
            />
          ) : (
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          )}
          {buttonLabel}
        </button>
      </div>
    ) : null}
  </div>
);
