import { PrimaryButton } from '../atoms/PrimaryButton';
import { SecondaryButton } from '../atoms/SecondaryButton';
import {
  useSlideOverWarningContext,
  useSlideOverFormContext,
} from './SlideOver';

export const SlideOverHandleButtons = ({ submitLabel = 'Save changes' }) => {
  const { shouldDisplayWarning } = useSlideOverWarningContext();
  const { reset, dirtyFields } = useSlideOverFormContext();

  return (
    /* Only display if there are dirty fields */
    Object.keys(dirtyFields).length > 0 && (
      <div className="flex items-center justify-center">
        <div className="animate-slideIn fixed bottom-4 mx-2">
          <div
            className={`h-24 rounded-md sm:h-14 text-white flex items-center justify-between p-2 transition-colors duration-300 ${
              shouldDisplayWarning ? 'bg-red-700 animate-shake' : 'bg-gray-800'
            }`}
          >
            <p className="text-sm font-light sm:mr-20">
              Attention, there are unsaved changes remaining!
            </p>

            <div className="flex">
              <SecondaryButton
                label="Reset"
                className={`text-white focus-visible:outline-white ${
                  shouldDisplayWarning
                    ? `hover:bg-red-600`
                    : `hover:bg-gray-700`
                }`}
                onClick={() => reset()}
              />
              <div className="ml-2" />

              <PrimaryButton
                type="submit"
                label={submitLabel}
                className="bg-green-600 hover:bg-green-500 focus-visible:outline-green-600"
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};
