// NotificationContext.js
import React, { createContext, useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Notification } from '../components/atoms/Notification';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationData, setNotificationData] = useState(null);

  const updateOpenNotification = (newOpenNotification) => {
    setOpenNotification(newOpenNotification);
  };

  const updateNotificationData = (newNotificationData) => {
    setNotificationData(newNotificationData);
  };

  // Using useMemo to memorize the context value
  const contextValue = useMemo(
    () => ({
      openNotification,
      notificationData,
      updateOpenNotification,
      updateNotificationData,
    }),
    [openNotification, notificationData]
  );

  const portalElement = document.getElementById('notification-portal');

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      {portalElement &&
        ReactDOM.createPortal(
          <Notification
            open={openNotification}
            setOpen={updateOpenNotification}
            type={notificationData?.type}
            title={notificationData?.title}
            description={notificationData?.description}
            primaryAction={notificationData?.primaryAction}
            primaryActionOnClick={notificationData?.primaryActionOnClick}
            secondaryAction={notificationData?.secondaryAction}
            secondaryActionOnClick={notificationData?.secondaryActionOnClick}
          />,
          portalElement
        )}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => React.useContext(NotificationContext);
