import { useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Header } from '../molecules/Header';

import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { Container } from '../atoms/Container';
import { FormTitle } from '../atoms/FormTitle';
import { RequiredAsterisk } from '../atoms/RequiredAsterisk';
import { NotificationContext } from '../../contexts/NotificationContext';

export default function SignupScreen() {
  const navigate = useNavigate();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  useEffect(() => {
    /* Check if an authToken is present in localStorage */
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      dynamicApiCall({
        callName: 'getAuthTokenValidity',
        navigate,
        params: {
          authToken,
        },
      });
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await dynamicApiCall({
      callName: 'postUserSignup',
      navigate,
      params: {
        firstname: data.firstname,
        lastname: data.lastname,
        mail: data.mail,
        password: data.password,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  return (
    <>
      <Header>
        Already have an account ?{' '}
        <Link
          to="/login"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Log in
        </Link>
      </Header>
      <div className="flex h-5/6 items-center">
        <Container>
          <div className="text-center">
            <FormTitle text="Sign up" />
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex justify-between">
                <div>
                  <label
                    htmlFor="firstname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Firstname
                    <RequiredAsterisk />
                  </label>
                  <div
                    className={`mt-2 ${
                      errors.firstname ? 'border-red-500' : ''
                    }`}
                  >
                    <input
                      id="firstname"
                      name="firstname"
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                        errors.firstname ? 'ring-red-500' : ''
                      }`}
                      autoComplete="firstname"
                      type="text"
                      {...register('firstname', {
                        required: 'Firstname is required',
                      })}
                    />
                    {errors.firstname && (
                      <span className="text-red-500 text-xs">
                        {errors.firstname.message}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="lastname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Lastname
                    <RequiredAsterisk />
                  </label>
                  <div
                    className={`mt-2 ${
                      errors.lastname ? 'border-red-500' : ''
                    }`}
                  >
                    <input
                      id="lastname"
                      name="lastname"
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                        errors.lastname ? 'ring-red-500' : ''
                      }`}
                      autoComplete="lastname"
                      type="text"
                      {...register('lastname', {
                        required: 'Lastname is required',
                      })}
                    />
                    {errors.lastname && (
                      <span className="text-red-500 text-xs">
                        {errors.lastname.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                  <RequiredAsterisk />
                </label>
                <div className={`mt-2 ${errors.mail ? 'border-red-500' : ''}`}>
                  <input
                    id="email"
                    name="email"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      errors.mail ? 'ring-red-500' : ''
                    }`}
                    autoComplete="email"
                    type="email"
                    {...register('mail', {
                      required: 'Email is required',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Invalid email format',
                      },
                    })}
                  />
                  {errors.mail && (
                    <span className="text-red-500 text-xs">
                      {errors.mail.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                    <RequiredAsterisk />
                    <p className="text-xs font-light text-gray-400">
                      (Min. 8 characters with a letter and a number)
                    </p>
                  </label>
                </div>
                <div
                  className={`mt-2 ${errors.password ? 'border-red-500' : ''}`}
                >
                  <input
                    id="password"
                    name="password"
                    type="password"
                    {...register('password', {
                      required: 'Password is required',
                      pattern: {
                        value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
                        message:
                          'Password must be at least 8 characters long, with a letter and a number',
                      },
                    })}
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      errors.password ? 'ring-red-500' : ''
                    }`}
                  />
                  {errors.password && (
                    <span className="text-red-500 text-xs">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign up
                </button>
              </div>
            </form>

            <p className="mt-5 text-center text-sm text-gray-500">
              By continuing, you are agreeing to Synkros
              <a
                href={`${process.env.REACT_APP_LANDING_URL}/terms&conditions`}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Privacy Policy
              </a>{' '}
              and{' '}
              <a
                href={`${process.env.REACT_APP_LANDING_URL}/privacyPolicy`}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}
