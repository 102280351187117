import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

export const Searchbar = ({ placeholder, onChange }) => (
  <form
    className="pr-4 relative flex flex-auto h-12 border border-gray-200 rounded-md bg-white"
    action="#"
    method="GET"
  >
    <label htmlFor="search-field" className="sr-only">
      Search
    </label>
    <MagnifyingGlassIcon
      className="pointer-events-none absolute mx-2 inset-y-0 left-0 h-full w-5 text-gray-400"
      aria-hidden="true"
    />
    <input
      id="search-field"
      className="block h-full bg-white rounded-md w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
      placeholder={placeholder}
      type="search"
      name="search"
      onChange={(e) => onChange(e.target.value)}
    />
  </form>
);
