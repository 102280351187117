export const postTeamMembersAllocationsLogic = async (datas) => {
  const {
    callData,
    reset,
    view,
    fetchDataForMonth,
    fetchDataForWeek,
    monthSelected,
    yearSelected,
    fetchAnomalies,
    teamUUID,
    startWeekSelected,
    endWeekSelected,
    updateOpenSlideOver,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    updateOpenSlideOver(false);
    reset();

    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
      });
    }

    if (fetchAnomalies) {
      fetchAnomalies({ teamUUID });
    }

    /* Re-fetch data after successful post */
    if (view === 'Month') {
      fetchDataForMonth({
        month: {
          start: monthSelected.start,
          end: monthSelected.end,
        },
        year: {
          start: yearSelected.start,
          end: yearSelected.end,
        },
        view,
      });
    } else if (view === 'Week') {
      fetchDataForWeek({
        start: startWeekSelected,
        end: endWeekSelected,
        view,
      });
    }
  }
};
