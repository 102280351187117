/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  format,
  getDay,
  subMonths,
} from 'date-fns';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { SecondaryButton } from './SecondaryButton';
import { IconButton } from './IconButton';
import { InputErrorMessage } from './InputErrorMessage';
import { useSlideOverFormContext } from '../molecules/SlideOver';

// Function to generate month data with correct day alignment
const generateMonthData = (forMonth) => {
  const start = startOfMonth(forMonth);
  const end = endOfMonth(forMonth);
  const daysArray = eachDayOfInterval({ start, end });

  // Find out the day of the week for the 1st of the month
  // Adjusting so that 0 is Monday and 6 is Sunday
  let firstDayOfWeek = getDay(start);
  firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; // Adjust so Monday is 0, Sunday is 6

  // Generate placeholders for days before the first day of the month to align the first day correctly
  const leadingDays = Array.from({ length: firstDayOfWeek }, () => ({
    date: null,
    isCurrentMonth: false,
    isToday: false,
  }));

  // Map each day to an object and include the current month and today checks
  const days = leadingDays.concat(
    daysArray.map((date) => ({
      date: format(date, 'yyyy-MM-dd'),
      isCurrentMonth: true,
      isToday: format(new Date(), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'),
    }))
  );

  return {
    // Include the year right after the month
    name: `${format(forMonth, 'MMMM')} ${format(forMonth, 'yyyy')}`,
    days,
  };
};
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const CalendarPicker = ({
  name1 = 'from',
  name2 = 'to',
  openedFrom = null,
  isDisabled = false,
  modalRef = null,
  isRequired = false,
  defaultStartValue,
  defaultEndValue,
  onChange,
}) => {
  const slideOverFormContext = useSlideOverFormContext();

  const { register, errors, setValue, watch } = slideOverFormContext || {};

  const allocatedFrom = (watch && watch(name1)) || null;
  const allocatedTo = (watch && watch(name2)) || null;

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [monthsData, setMonthsData] = useState([]);
  const [visibleMonths, setVisibleMonths] = useState([]);
  const calendarRef = useRef(null);
  const [calendarTopOffset, setCalendarTopOffset] = useState(0);
  const inputContainerRef = useRef(null);
  // Add state for tracking 'from' and 'to' dates
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectingFrom, setSelectingFrom] = useState(true); // true if selecting 'from', false for 'to'
  const [hoveredDate, setHoveredDate] = useState(null);

  useEffect(() => {
    setFromDate(allocatedFrom);
    setToDate(allocatedTo);
  }, [allocatedFrom && allocatedTo]);

  useEffect(() => {
    if (defaultStartValue) {
      setFromDate(defaultStartValue);
    }
    if (defaultEndValue) {
      setToDate(defaultEndValue);
    }
  }, [defaultStartValue, defaultEndValue]);

  useEffect(() => {
    if (onChange) {
      onChange(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (
      openedFrom === 'modal' &&
      inputContainerRef?.current &&
      modalRef?.current
    ) {
      const inputRect = inputContainerRef.current.getBoundingClientRect();
      const modalRect = modalRef.current.getBoundingClientRect();

      // Calculate the top position relative to the modal
      setCalendarTopOffset(inputRect.bottom - modalRect.top);
    }
  }, [openedFrom, isCalendarOpen, inputContainerRef, modalRef]);

  // Function to check if a date is between the 'from' date and the hovered date
  const isDateInHoverRange = (date) => {
    if (!fromDate || !hoveredDate || !date) return false;
    const from = new Date(fromDate);
    const hovered = new Date(hoveredDate);
    const current = new Date(date);
    return current > from && current < hovered;
  };

  useEffect(() => {
    const months = [];
    const startMonth = subMonths(new Date(), 24); // One year before
    const endMonth = addMonths(new Date(), 24); // One year after

    for (
      let month = startMonth;
      month <= endMonth;
      month = addMonths(month, 1)
    ) {
      months.push(generateMonthData(month));
    }

    setMonthsData(months);
    // Calculate the current and next month names
    const currentMonthName = `${format(new Date(), 'MMMM')} ${format(
      new Date(),
      'yyyy'
    )}`;
    const nextMonthName = `${format(addMonths(new Date(), 1), 'MMMM')} ${format(
      addMonths(new Date(), 1),
      'yyyy'
    )}`;
    setVisibleMonths([currentMonthName, nextMonthName]); // Set only the current and next month as visible
  }, []);

  // Handle keyboard interactions for the button
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsCalendarOpen(true);
    }
  };

  // Function to check if a date is between 'from' and 'to' dates
  const isDateInRange = (date) => {
    if (!fromDate || !toDate || !date) return false;
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const current = new Date(date);
    return current > from && current < to;
  };

  // Function for internal date comparison ('YYYY-MM-DD' format)
  const formatInternalDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Simplified function to compare dates in 'YYYY-MM-DD' format
  const isBefore = (date1, date2) => date1 < date2;

  // Function to handle date selection
  const handleDateClick = (day) => {
    if (!day.date) return; // Ignore clicks on empty cells

    const internalFormattedDate = formatInternalDate(day.date);

    if (
      selectingFrom ||
      isBefore(internalFormattedDate, formatInternalDate(fromDate))
    ) {
      setFromDate(internalFormattedDate);

      if (setValue) {
        setValue(name1, internalFormattedDate, { shouldDirty: true });
      }
      setToDate(null);
      setSelectingFrom(false);
    } else {
      setToDate(internalFormattedDate);
      if (setValue) {
        setValue(name2, internalFormattedDate, { shouldDirty: true });
      }
      setSelectingFrom(true);
    }
  };

  // Function to handle outside click
  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsCalendarOpen(false);
    }
  };

  // Set up the event listeners
  useEffect(() => {
    // Bind the event listener
    const handleMouseDown = (event) => handleClickOutside(event);
    if (isCalendarOpen) {
      window.addEventListener('mousedown', handleMouseDown);
    }

    // Unbind the event listener on clean up
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isCalendarOpen]);

  const shiftMonths = (direction) => {
    const currentIndex = monthsData.findIndex(
      (m) => m.name === visibleMonths[0]
    );
    if (direction === 'left' && currentIndex > 0) {
      setVisibleMonths([
        monthsData[currentIndex - 1].name,
        monthsData[currentIndex].name,
      ]);
    } else if (direction === 'right' && currentIndex < monthsData.length - 2) {
      setVisibleMonths([
        monthsData[currentIndex + 1].name,
        monthsData[currentIndex + 2].name,
      ]);
    }
  };

  const toggleCalendar = (event, target) => {
    event.stopPropagation(); // Prevent click from affecting other elements

    if (isDisabled) return;

    // Open the calendar and specify whether 'from' or 'to' is being selected
    setIsCalendarOpen(true);
    setSelectingFrom(target === 'from');
  };

  // Automatically close the calendar when both 'from' and 'to' dates are set
  useEffect(() => {
    if (fromDate && toDate) {
      setIsCalendarOpen(false);
    }
  }, [fromDate, toDate]);

  return (
    <div className={`sm:col-span-2 flex ${!openedFrom && 'relative'}`}>
      <div ref={inputContainerRef} className="relative w-full">
        <button
          type="button"
          onClick={!isDisabled ? () => setIsCalendarOpen(true) : null}
          onKeyDown={!isDisabled ? handleKeyDown : null}
          tabIndex={0}
          aria-label="Toggle calendar from date"
          className={`w-full flex justify-between items-center cursor-pointer rounded-tl-md rounded-bl-md px-3 bg-white pb-1 pt-2 shadow-sm ring-1 ring-inset ${
            isCalendarOpen && selectingFrom
              ? 'ring-2 ring-indigo-600'
              : 'ring-gray-300'
          }`}
        >
          <div>
            <div
              role="button"
              tabIndex={0}
              onClick={(e) => toggleCalendar(e, 'from')}
              className="cursor-pointer block text-xs text-left font-medium text-gray-900"
            >
              From
            </div>
            <input
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              value={fromDate || ''}
              readOnly
              name="from"
              id="from"
              {...(register &&
                register('from', {
                  required: isRequired ? `This input is required.` : false,
                }))}
              className="cursor-pointer border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="DD/MM/YYYY"
            />
          </div>

          {fromDate && !isDisabled ? (
            <XMarkIcon
              onClick={() => {
                setFromDate(null);
                setToDate(null);
                setSelectingFrom(true);
              }}
              className="absolute right-2 h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
              aria-label="Clear from date"
            />
          ) : null}
        </button>

        {errors && errors.from && (
          <InputErrorMessage errors={errors} name="from" />
        )}
      </div>
      <div className="relative w-full">
        <button
          type="button"
          onClick={!isDisabled ? () => setIsCalendarOpen(true) : null}
          onKeyDown={!isDisabled ? handleKeyDown : null}
          tabIndex={0}
          aria-label="Toggle calendar to date"
          className={`w-full flex justify-between items-center cursor-pointer rounded-tr-md rounded-br-md px-3 bg-white pb-1 pt-2 shadow-sm ring-1 ring-inset ${
            isCalendarOpen && !selectingFrom && fromDate
              ? 'ring-2 ring-indigo-600'
              : 'ring-gray-300'
          }`}
        >
          <div>
            <div
              role="button"
              tabIndex={0}
              onClick={(e) => toggleCalendar(e, 'to')}
              className="cursor-pointer block text-xs text-left font-medium text-gray-900"
            >
              To
            </div>
            <input
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              value={toDate || ''}
              readOnly
              {...(register &&
                register('to', {
                  required: isRequired ? `This input is required.` : false,
                }))}
              name="to"
              id="to"
              className="cursor-pointer block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="DD/MM/YYYY"
            />
          </div>
          {toDate && !isDisabled && (
            <XMarkIcon
              onClick={() => {
                setToDate(null);
                setSelectingFrom(false);
              }}
              className="absolute right-2 h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
              aria-label="Clear to date"
            />
          )}
        </button>
        {errors && errors.to && <InputErrorMessage errors={errors} name="to" />}
      </div>

      {/* Modal for the calendar */}
      {isCalendarOpen && (
        <div
          className={`absolute z-50 ${
            openedFrom === 'modal'
              ? `top-[${calendarTopOffset}px]`
              : 'top-full right-0 mt-[2px]'
          } bg-smoke-light flex shadow-lg`}
          style={{
            top: openedFrom === 'modal' ? `${calendarTopOffset}px` : undefined,
          }}
        >
          <div
            ref={calendarRef}
            className="relative p-4 bg-white w-max max-w-2xl flex-col flex rounded-lg shadow-lg"
          >
            <div
              aria-modal="true"
              role="dialog"
              className="relative grid grid-cols-1 gap-x-8 md:grid-cols-2"
            >
              <button
                type="button"
                onClick={() => shiftMonths('left')}
                className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => shiftMonths('right')}
                className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              {monthsData
                .filter((month) => visibleMonths.includes(month.name))
                .map((month, monthIdx) => (
                  <section
                    key={monthIdx}
                    className={classNames(
                      monthIdx === monthsData.length - 1 && 'hidden md:block',
                      'text-center'
                    )}
                  >
                    <h2 className="text-sm font-semibold text-gray-900">
                      {month.name}
                    </h2>
                    <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                      <div>Mon.</div>
                      <div>Tue.</div>
                      <div>Wed.</div>
                      <div>Thu.</div>
                      <div>Fri.</div>
                      <div>Sat.</div>
                      <div>Sun.</div>
                    </div>
                    <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg text-sm">
                      {month.days.map((day, dayIdx) => (
                        <button
                          key={dayIdx}
                          type="button"
                          onClick={() => handleDateClick(day)}
                          onMouseEnter={() => setHoveredDate(day.date)}
                          onMouseLeave={() => setHoveredDate(null)}
                          className={classNames(
                            'relative py-1.5 focus:z-20',
                            day.date &&
                              (day.isCurrentMonth
                                ? 'hover:bg-indigo-200'
                                : null),
                            !day.date && 'bg-white pointer-events-none',
                            formatInternalDate(day.date) === fromDate &&
                              'bg-indigo-600 font-semibold text-white',
                            formatInternalDate(day.date) === toDate &&
                              'bg-indigo-600 font-semibold text-white',
                            isDateInRange(formatInternalDate(day.date)) &&
                              'bg-indigo-50',
                            isDateInHoverRange(formatInternalDate(day.date)) &&
                              !selectingFrom &&
                              'bg-indigo-50'
                          )}
                        >
                          {day.date ? (
                            <time
                              dateTime={day.date}
                              className={classNames(
                                'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                                day.isToday
                                  ? 'text-indigo-600'
                                  : 'text-gray-900',
                                formatInternalDate(day.date) === fromDate
                                  ? 'text-white'
                                  : '',
                                formatInternalDate(day.date) === toDate
                                  ? 'text-white'
                                  : '',
                                formatInternalDate(day.date) === fromDate
                                  ? 'bg-indigo-600'
                                  : '',
                                formatInternalDate(day.date) === toDate
                                  ? 'bg-indigo-600'
                                  : ''
                              )}
                            >
                              {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                          ) : null}
                        </button>
                      ))}
                    </div>
                  </section>
                ))}
            </div>
            <div className="flex mt-2 justify-end">
              <SecondaryButton
                id="resetDates"
                label="Reset dates"
                onClick={() => {
                  setFromDate(null);
                  setToDate(null);
                  setSelectingFrom(true);
                }}
              />

              <div className="mx-1" />

              <IconButton
                id="close"
                label="Close"
                onClick={() => setIsCalendarOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
