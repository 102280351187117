import React from 'react';

export const TableCellLinksList = ({
  data,
  labels,
  index,
  customClassName,
  onClick,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleKeyPress = (event, label) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick(event, label);
    }
  };

  return (
    <td
      className={classNames(
        index !== data.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap px-2 py-4 text-xs text-indigo-500',
        customClassName
      )}
    >
      {labels.map((label, labelIndex) => (
        <React.Fragment key={labelIndex}>
          <span
            role="button"
            tabIndex="0"
            onClick={(e) => onClick(e, label)}
            onKeyDown={(event) => handleKeyPress(event, label)}
            className="hover:underline truncate max-w-xs"
            title={label} // Show the full text on hover
          >
            {label}
          </span>
          {labelIndex < labels.length - 1 && (
            <span className="text-black mr-1">,</span>
          )}
        </React.Fragment>
      ))}
    </td>
  );
};
