export const SecondaryButton = ({
  id,
  children,
  label,
  onClick,
  isDisabled = false,
  className = `text-indigo-700 hover:bg-indigo-200 focus-visible:outline-indigo-600`,
}) => (
  <button
    id={id}
    disabled={isDisabled}
    type="button"
    onClick={onClick}
    className={`${className} inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
  >
    {children}
    {label}
  </button>
);
