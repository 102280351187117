export const postInvitedUsersByMailAndTeamUUIDLogic = async (datas) => {
  const {
    fetchMembersScreenData,
    updateOpenNotification,
    updateNotificationData,
    callData,
    updateOpenMemberSlideOver,
    reset,
  } = datas;

  if (callData) {
    await fetchMembersScreenData();
    reset();

    if (updateOpenMemberSlideOver) {
      updateOpenMemberSlideOver(false);
    }

    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({ ...callData });
    }
  }
};
