export function handleDragEnd({ result, days }) {
  if (!result.destination) return;

  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;

  const sourceDay = days.find((day) => day.date === result.source.droppableId);
  const destinationDay = days.find(
    (day) => day.date === result.destination.droppableId
  );

  if (
    !sourceDay ||
    !destinationDay ||
    !sourceDay.events ||
    !destinationDay.events
  ) {
    return;
  }

  /* Check if the item is dragged within the same day and returned to its original position */
  if (
    sourceDay.date === destinationDay.date &&
    sourceIndex === destinationIndex
  ) {
    return; /* No changes needed */
  }

  if (sourceIndex < 0 || sourceIndex >= sourceDay.events.length) {
    return;
  }

  const draggedEvent = sourceDay.events[sourceIndex];

  /* Delete Dragged element from his source (it will be also deleted into days var due to reference above) */
  sourceDay.events.splice(sourceIndex, 1);

  /* Insert dragged element in destination at his correct position (it will be also added into days var due to reference above) */
  destinationDay.events.splice(destinationIndex, 0, draggedEvent);

  /* Update position of all events in destination day */
  destinationDay.events.forEach((event, index) => {
    event.position = index;
  });

  /* Update Dragged Event */
  draggedEvent.date = destinationDay.date;

  return { days, draggedEvent };
}
