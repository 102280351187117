export const putUserConfirmMailLogic = async (datas) => {
  const {
    navigate,
    navigateTo,
    routeAllocations,
    setLoading,
    callData,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    if (callData.type === 'success') {
      setLoading(false);

      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
      });

      navigateTo({ navigate, routeName: routeAllocations });
    } else {
      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
      });
    }
  }
};
