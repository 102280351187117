import moment from 'moment';

export function formatDate(
  dateString,
  format = 'YYYY-MM-DD',
  toLowerCase = false
) {
  let formattedDate = moment(dateString).format(format);

  if (toLowerCase) {
    formattedDate = formattedDate.toLowerCase();
  }

  return formattedDate;
}

export function currentDayInDayName() {
  return moment().format('dddd').toLowerCase();
}

export function getDateInDayName(date) {
  return moment(date).format('dddd').toLowerCase();
}

export function addDays(dateString, days) {
  return moment(dateString).add(days, 'days').format('YYYY-MM-DD');
}

export function subtractDays(dateString, days) {
  return moment(dateString).subtract(days, 'days').format('YYYY-MM-DD');
}

export function formatDateInWords(dateString) {
  const date = moment(dateString);
  const day = date.format('D');
  const month = date.format('MMMM');
  const year = date.format('YYYY');

  return `${day} ${month} ${year}`;
}

export function formatDateInDDMMMYYYY(dateString) {
  return moment(dateString).format('DD MMM YYYY');
}

export function beginningOfYear() {
  return moment().startOf('year').format('YYYY-MM-DD');
}

export function endOfYear() {
  return moment().endOf('year').format('YYYY-MM-DD');
}

export function getCurrentDay() {
  return moment().format('YYYY-MM-DD');
}

export function getCurrentMonth() {
  return moment().format('MM');
}

export function getCurrentYear() {
  return moment().format('YYYY');
}

export function getCurrentYearNumber() {
  return parseInt(moment().format('YYYY'), 10);
}

export function getCurrentYearNumberFromDate(date) {
  return parseInt(moment(date).format('YYYY'), 10);
}

export function getCurrentMonthNumber() {
  return parseInt(moment().format('MM'), 10);
}

export function getCurrentMonthNumberFromDate(date) {
  return parseInt(moment(date).format('MM'), 10);
}

export function getStartOfMonth(month, year) {
  return moment()
    .month(month - 1)
    .year(year)
    .startOf('month')
    .format('YYYY-MM-DD');
}

export function getEndOfMonth(month, year) {
  return moment()
    .month(month - 1)
    .year(year)
    .endOf('month')
    .format('YYYY-MM-DD');
}

export function getStartAndEndOfWeek(date) {
  let momentDate = moment();
  if (date) {
    momentDate = moment(date);
  }

  const startOfWeek = momentDate.startOf('isoWeek').format('YYYY-MM-DD');
  const endOfWeek = momentDate.endOf('isoWeek').format('YYYY-MM-DD');

  return {
    startOfWeek,
    endOfWeek,
  };
}
