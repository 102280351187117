export const getTeamBudgetsLogic = async (datas) => {
  const { setBudget, budgetUUID, callData } = datas;

  if (budgetUUID) {
    // Find the index of the object with the matching uuid
    const index = callData.findIndex((item) => item.uuid === budgetUUID);

    // Check if the index is found
    if (index !== -1) {
      // If found, set the client using the found index
      setBudget(callData[index]);
    } else {
      // If not found, handle the case (e.g., set to null or handle error)
      setBudget(null);
    }
  } else {
    setBudget(callData[0]);
  }
};
