import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MonthView({
  days,
  updateIsDraggingEvent,
  isDraggingEvent,
  onDragEnd,
  isMouseDown,
  setIsMouseDown,
  handleMouseDown,
  handleMouseOver,
  handleFocus,
  startDayIndex,
  endDayIndex,
  onEventClick,
  canAllocate,
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (days.length > 7) {
      setIsVisible(true);
    }
  }, [days]);

  if (isVisible) {
    return (
      <DragDropContext
        onDragStart={() => {
          if (canAllocate) {
            updateIsDraggingEvent(true);
            setIsMouseDown(false);
          }
        }}
        onDragEnd={(result) => {
          if (canAllocate) {
            onDragEnd(result);
          }
        }}
      >
        <div className="flex flex-col h-full">
          <div className="flex-grow flex flex-col rounded-b-md border">
            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
              <div className="bg-white py-2">
                M<span className="sr-only sm:not-sr-only">on</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">ue</span>
              </div>
              <div className="bg-white py-2">
                W<span className="sr-only sm:not-sr-only">ed</span>
              </div>
              <div className="bg-white py-2">
                T<span className="sr-only sm:not-sr-only">hu</span>
              </div>
              <div className="bg-white py-2">
                F<span className="sr-only sm:not-sr-only">ri</span>
              </div>
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">at</span>
              </div>
              <div className="bg-white py-2">
                S<span className="sr-only sm:not-sr-only">un</span>
              </div>
            </div>

            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto flex-grow">
              <div className="w-full grid grid-cols-7 gap-px flex-grow">
                {days.map((day, index) => {
                  const isWorkingDay = day.maxHours !== 0;
                  const isHoliday = day.isHoliday && day.isHoliday.length > 0;

                  return (
                    <div
                      key={day.date}
                      tabIndex={0}
                      className={classNames(
                        day.isCurrentMonth
                          ? 'bg-white hover:bg-indigo-50'
                          : 'bg-gray-100 text-gray-500 hover:bg-indigo-50',
                        'relative pt-2 flex flex-col h-full',
                        !isWorkingDay &&
                          'pointer-events-none' /* Non working days are not clickable */, // TODO: ajouter cursor-not-allowed
                        !canAllocate && 'cursor-not-allowed'
                      )}
                      role="button"
                      style={{
                        background: !isWorkingDay
                          ? 'repeating-linear-gradient(45deg, transparent, transparent 7px, rgba(255, 255, 255, .5) 7px, rgba(255, 255, 255, .5) 17px)'
                          : '',
                      }}
                      onMouseDown={(event) => {
                        if (canAllocate && event.button === 0) {
                          handleMouseDown(index);
                        }
                      }}
                      onMouseOver={() => {
                        if (canAllocate) {
                          handleMouseOver(index);
                        }
                      }}
                      onFocus={() => {
                        if (canAllocate) {
                          handleFocus(index);
                        }
                      }}
                      data-open-modal="true"
                    >
                      {isWorkingDay && (
                        <div
                          className={`absolute inset-0 ${
                            isMouseDown &&
                            index >= Math.min(startDayIndex, endDayIndex) &&
                            index <= Math.max(startDayIndex, endDayIndex)
                              ? 'border border-indigo-600'
                              : ''
                          } `}
                        />
                      )}

                      <div className="flex">
                        <time
                          dateTime={day.date}
                          className={classNames(
                            'select-none px-2',
                            day.isToday
                              ? 'flex h-6 w-6 ml-1 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                              : undefined
                          )}
                          data-open-modal="true"
                        >
                          {day.date.split('-').pop().replace(/^0/, '')}
                        </time>
                        {isHoliday && (
                          <div className="select-none truncate pr-0.5">
                            {day.isHoliday.map((holiday, index) => (
                              <p key={index} className="truncate">
                                {holiday.name}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>

                      <Droppable
                        droppableId={day.date}
                        key={day.date}
                        isDropDisabled={
                          isHoliday || !isWorkingDay || !canAllocate
                        }
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            key={day.date}
                            className={classNames(
                              day.isCurrentMonth ? '' : 'text-gray-500',
                              'relative bg-transparent py-2 px-1 w-full h-full',
                              snapshot.isDraggingOver
                            )}
                            data-open-modal="true"
                          >
                            {day.events
                              .filter(
                                (event) =>
                                  event.isVisible === undefined ||
                                  event.isVisible
                              )
                              .sort((a, b) => a.position - b.position)
                              .map((event, eventIndex) => (
                                <Draggable
                                  key={eventIndex}
                                  draggableId={`${day.date}-${eventIndex}`}
                                  index={eventIndex}
                                  isDragDisabled={!canAllocate}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      style={{
                                        pointerEvents: isDraggingEvent
                                          ? 'none'
                                          : 'auto',
                                      }}
                                      className={classNames(
                                        'mb-1 select-none',
                                        !canAllocate && 'cursor-not-allowed'
                                      )}
                                      role="button"
                                      tabIndex={0}
                                      onMouseDown={(e) => {
                                        e.stopPropagation();
                                      }}
                                      onClick={() => {
                                        if (canAllocate) {
                                          onEventClick({ event, day });
                                        }
                                      }}
                                      onKeyDown={() => {}}
                                      data-open-modal="false"
                                    >
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`
                                          truncate fd font-medium text-gray-900 group-hover:text-indigo-600 h-full rounded
                                          ${
                                            snapshot.isDragging
                                              ? 'shadow-2xl bg-gray-200'
                                              : null
                                          }

                                        `}
                                      >
                                        <div className="flex pr-1 hover:bg-gray-200">
                                          <span className="inline-flex items-center gap-x-1.5 rounded-full px-1 text-xs font-medium text-gray-900">
                                            <div
                                              className={`h-2 w-2 rounded-full ${
                                                event.color ||
                                                'bg-white-500 border border-gray-300'
                                              }`}
                                            />
                                          </span>
                                          <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                            {event.name}
                                          </p>
                                          <time className="ml-3 block flex-none text-gray-500 group-hover:text-indigo-600 ">
                                            {event.hours}h
                                          </time>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            {provided.placeholder && (
                              <div className="h-6 bg-transparent hidden">
                                {provided.placeholder}
                              </div>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </DragDropContext>
    );
  }
}
