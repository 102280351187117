import { useSlideOverFormContext } from './SlideOver';

export const SlideOverFormContainer = ({ onSubmit, children }) => {
  const { handleSubmit, reset } = useSlideOverFormContext();

  return (
    <form onSubmit={handleSubmit((inputData) => onSubmit(inputData, reset))}>
      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
        {children}
      </div>
    </form>
  );
};
