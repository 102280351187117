export const postTeamClientsLogic = async (datas) => {
  const {
    fetchTeamClientsByTeamUUIDPromise,
    fetchTeamBudgetsByTeamUUIDPromise,
    teamUUID,
    updateOpenSlideOver,
    reset,
    updateNotificationData,
    updateOpenNotification,
    callData,
  } = datas;

  if (callData) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });

    fetchTeamClientsByTeamUUIDPromise(teamUUID);
    fetchTeamBudgetsByTeamUUIDPromise(teamUUID);
    updateOpenSlideOver(false);
    reset();
  }
};
