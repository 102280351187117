import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SlideOverInputCombobox = ({
  name,
  placeholder,
  data,
  isRequired,
  customFunction,
}) => {
  const { register, errors, setValue, watch } = useSlideOverFormContext();

  const preselectedItem = data.find((item) => item.uuid === watch(name));

  const [isFocused, setIsFocused] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const comboboxRef = useRef(null);

  const resetQueryIfNeeded = (event) => {
    // Check if the new focused element is outside the combobox
    if (!comboboxRef.current.contains(event.relatedTarget)) {
      setQuery('');
      setIsFocused(false);
    }
  };

  const handleSelectItem = (item) => {
    if (customFunction) {
      customFunction(item);
    }
    setSelectedItem(item);
    setValue(name, item.uuid, { shouldDirty: true });
  };

  const filteredAndSortedData = data
    .filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  /* Reset selectedItem when data is empty */
  useEffect(() => {
    if (data.length === 0) {
      setSelectedItem(null);
    }
  }, [data]);

  return (
    <div className="sm:col-span-2" ref={comboboxRef}>
      <Combobox as="div" value={selectedItem} onChange={handleSelectItem}>
        <div className="relative">
          {/* Hidden Input for capturing the UUID */}
          <input
            id={name}
            type="hidden"
            name={name}
            {...register(name, {
              required: isRequired ? 'This input is required.' : false,
            })}
          />

          {preselectedItem?.name === `owner` ? (
            <input
              className="bg-gray-100 cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              type="text"
              placeholder={preselectedItem?.name || placeholder}
              disabled
            />
          ) : (
            <>
              <Combobox.Input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                type="text"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(item) => item?.name}
                placeholder={preselectedItem?.name || placeholder}
                onFocus={() => setIsFocused(true)}
                onBlur={resetQueryIfNeeded}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </>
          )}

          {filteredAndSortedData.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredAndSortedData.map((item) => {
                if (item.name === 'owner') {
                  return null;
                }
                return (
                  <Combobox.Option
                    key={item.uuid}
                    value={item}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              'inline-block h-3 w-3 flex-shrink-0 rounded-full',
                              item.color ? item.color : 'bg-white border-2'
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              'ml-3 truncate',
                              selected && 'font-semibold'
                            )}
                          >
                            {item.name}
                          </span>
                        </div>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                );
              })}
            </Combobox.Options>
          )}
        </div>
      </Combobox>

      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};
