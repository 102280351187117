export const putUserByUUIDLogic = async (datas) => {
  const {
    fetchInfo,
    setUpdatingUserData,
    updatingUserData,
    updateOpenModal,
    callData,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    await fetchInfo();

    updateOpenModal(false);
    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({ ...callData });
    }
    if (setUpdatingUserData) {
      setUpdatingUserData({
        ...updatingUserData,
        currentPassword: null,
        newPasswordConfirm: null,
        newPassword: null,
      });
    }
  }
};
