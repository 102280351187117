import React from 'react';
import moment from 'moment';
import { formatDateInDDMMMYYYY } from '../../services/utils/momentUtils';
import DotStatus from '../atoms/DotStatus';

export const TableCellDatesPeriod = ({
  data,
  from,
  to,
  index,
  customClassName,
}) => {
  // Combines multiple class names into one string
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  // Formats the date range based on the year difference
  const formatDates = (from, to) => {
    const fromDate = moment(from);
    const toDate = moment(to);

    // Check if both dates are in the same year
    if (fromDate.year() === toDate.year()) {
      return `${fromDate.format('DD MMM')} - ${toDate.format('DD MMM YYYY')}`;
    }
    return `${formatDateInDDMMMYYYY(from)} - ${formatDateInDDMMMYYYY(to)}`;
  };

  // Determine if the 'to' date is in the past
  const isToDateInThePast = moment(to).isBefore(moment());
  // Determine if the 'from' date is in the future
  const isFromDateInTheFuture = moment(from).isAfter(moment());

  // Determine dot color based on date conditions
  const dotColor = isFromDateInTheFuture
    ? 'bg-blue-500'
    : isToDateInThePast
    ? 'bg-red-500'
    : 'bg-green-500';

  const backgroundColor = isFromDateInTheFuture
    ? 'bg-blue-500/10'
    : isToDateInThePast
    ? 'bg-red-500/10'
    : 'bg-green-500/10';

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 pr-3 text-xs text-gray-600',
        customClassName
      )}
    >
      <div className="flex items-center justify-end gap-x-2 sm:justify-start">
        <DotStatus color={dotColor} backgroundColor={backgroundColor} />
        <span>{formatDates(from, to)}</span>
      </div>
    </td>
  );
};
