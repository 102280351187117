export const LabelInput = ({
  label,
  id,
  placeholder,
  defaultValue,
  updateData,
  onKeyDown = null,
  disabled,
  type = 'text',
  register,
  registerName,
  registerValue,
  registerMessage,
  errors,
}) => {
  const errorKey = id; /* Use the id as the key for each input */

  /* Conditionally apply a class for hover effect when disabled */
  const inputClass = `block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
    disabled ? 'cursor-not-allowed hover:opacity-70' : ''
  }`;

  return (
    <div className="max-w-lg bg-white rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
      <label htmlFor={id} className="block text-xs font-medium text-gray-900">
        {label}
      </label>
      <input
        type={type}
        name={id}
        id={id}
        disabled={disabled}
        defaultValue={defaultValue}
        className={inputClass}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        {...(register &&
          register(registerName, {
            pattern: {
              value: registerValue,
              message: registerMessage,
            },
          }))}
        onChange={(e) => updateData(e.target.value)}
      />
      {errors && errors[errorKey] && (
        <span className="text-red-500 text-xs">{errors[errorKey].message}</span>
      )}
    </div>
  );
};
