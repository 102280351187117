import { getOverlappingBudgetNames } from './getOverlappingBudgetNames';

export const calculateAllocationAmount = (
  allocation,
  project,
  budget,
  teamData
) => {
  const hourlyRate =
    allocation.user.teamMemberDetails[0]?.hourlyRate ||
    teamData.teamDefaultHourlyRate;
  const { allocationHours } = allocation.allocation;

  // Get the number of overlapping budgets and include the current budget in the count
  let overlappingBudgetsCount = 0;

  if (project && budget) {
    overlappingBudgetsCount =
      getOverlappingBudgetNames(
        allocation.allocation.allocationDate,
        project.budgets,
        budget.uuid
      ).length + 1; // Increment by 1 to include the current budget
  }

  // Calculate the amount
  const amount =
    overlappingBudgetsCount > 0
      ? (hourlyRate * allocationHours) / overlappingBudgetsCount
      : hourlyRate * allocationHours;

  return amount ? parseFloat(amount.toFixed(2)) : '0.00'; // Ensure it's a valid number and format to 2 decimal places
};
