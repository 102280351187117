const LogoSvg = ({
  startColor = 'rgb(79 70 229)',
  endColor = 'rgb(79 70 229)',
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800">
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="nnneon-grad">
        <stop stopColor={startColor} stopOpacity="1" offset="0%" />
        <stop stopColor={endColor} stopOpacity="1" offset="100%" />
      </linearGradient>
      <filter
        id="nnneon-filter"
        x="-100%"
        y="-100%"
        width="400%"
        height="400%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur
          stdDeviation="0 0"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          in="SourceGraphic"
          edgeMode="none"
          result="blur"
        />
      </filter>
      <filter
        id="nnneon-filter2"
        x="-100%"
        y="-100%"
        width="400%"
        height="400%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur
          stdDeviation="0 0"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          in="SourceGraphic"
          edgeMode="none"
          result="blur"
        />
      </filter>
    </defs>
    <g
      strokeWidth="22"
      stroke="url(#nnneon-grad)"
      fill="none"
      transform="rotate(76, 400, 400)"
    >
      <path
        d="M379.2154498077597 100.23022365710392C392.0769483509953 92.80597142755676 407.92311268416097 92.80597142755676 420.78461122739657 100.23230211517489L649.2154670605854 232.11670209318595C662.0769656038211 239.5409543227331 670.000047770404 253.26501296542625 670.000047770404 268.1155958825916V531.8843958386137C670.000047770404 546.7349787557791 662.0769656038211 560.4590373984722 649.2154670605854 567.8853680860902L420.78461122739657 699.7697680641013C407.92311268416097 707.1940202936485 392.0769483509953 707.1940202936485 379.2154498077597 699.7676896060304L150.78459397457084 567.8832896280194C137.92309543133518 560.4590373984722 130.00001326475234 546.7349787557791 130.00001326475234 531.8843958386137V268.1155958825916C130.00001326475234 253.26501296542625 137.92309543133518 239.5409543227331 150.78459397457084 232.11462363511498L379.2154498077597 100.23022365710392Z "
        filter="url(#nnneon-filter)"
      />
      <path
        d="M465.2154498077597 100.23022365710392C478.0769483509953 92.80597142755676 493.92311268416097 92.80597142755676 506.78461122739657 100.23230211517489L735.2154670605854 232.11670209318595C748.0769656038211 239.5409543227331 756.000047770404 253.26501296542625 756.000047770404 268.1155958825916V531.8843958386137C756.000047770404 546.7349787557791 748.0769656038211 560.4590373984722 735.2154670605854 567.8853680860902L506.78461122739657 699.7697680641013C493.92311268416097 707.1940202936485 478.0769483509953 707.1940202936485 465.2154498077597 699.7676896060304L236.78459397457084 567.8832896280194C223.92309543133518 560.4590373984722 216.00001326475234 546.7349787557791 216.00001326475234 531.8843958386137V268.1155958825916C216.00001326475234 253.26501296542625 223.92309543133518 239.5409543227331 236.78459397457084 232.11462363511498L465.2154498077597 100.23022365710392Z "
        filter="url(#nnneon-filter2)"
        opacity="1"
      />
      <path
        d="M293.2154498077597 100.23022365710392C306.0769483509953 92.80597142755676 321.92311268416097 92.80597142755676 334.78461122739657 100.23230211517489L563.2154670605854 232.11670209318595C576.0769656038211 239.5409543227331 584.000047770404 253.26501296542625 584.000047770404 268.1155958825916V531.8843958386137C584.000047770404 546.7349787557791 576.0769656038211 560.4590373984722 563.2154670605854 567.8853680860902L334.78461122739657 699.7697680641013C321.92311268416097 707.1940202936485 306.0769483509953 707.1940202936485 293.2154498077597 699.7676896060304L64.78459397457084 567.8832896280194C51.923095431335184 560.4590373984722 44.00001326475234 546.7349787557791 44.00001326475234 531.8843958386137V268.1155958825916C44.00001326475234 253.26501296542625 51.923095431335184 239.5409543227331 64.78459397457084 232.11462363511498L293.2154498077597 100.23022365710392Z "
        filter="url(#nnneon-filter2)"
        opacity="1"
      />
      <path d="M379.2154498077597 100.23022365710392C392.0769483509953 92.80597142755676 407.92311268416097 92.80597142755676 420.78461122739657 100.23230211517489L649.2154670605854 232.11670209318595C662.0769656038211 239.5409543227331 670.000047770404 253.26501296542625 670.000047770404 268.1155958825916V531.8843958386137C670.000047770404 546.7349787557791 662.0769656038211 560.4590373984722 649.2154670605854 567.8853680860902L420.78461122739657 699.7697680641013C407.92311268416097 707.1940202936485 392.0769483509953 707.1940202936485 379.2154498077597 699.7676896060304L150.78459397457084 567.8832896280194C137.92309543133518 560.4590373984722 130.00001326475234 546.7349787557791 130.00001326475234 531.8843958386137V268.1155958825916C130.00001326475234 253.26501296542625 137.92309543133518 239.5409543227331 150.78459397457084 232.11462363511498L379.2154498077597 100.23022365710392Z " />
    </g>
  </svg>
);

export default LogoSvg;
