export const deleteTeamTagsByUUIDLogic = async (datas) => {
  const {
    callData,
    fetchTeamTagsByTeamUUIDPromise,
    teamUUID,
    updateOpenModal,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    updateOpenModal(false);
    fetchTeamTagsByTeamUUIDPromise({
      teamUUID,
      fetchNumberTagUsedInProjects: true,
    });
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
  }
};
