import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { SideBarLayout } from '../organisms/SiderBarLayout';
import { SubSideBarLayout } from '../organisms/SubSideBarLayout';
import { ActionPanelWithButtonOnRight } from '../molecules/ActionPanelWithButtonOnRight';
import { ActionPanelWithInput } from '../molecules/ActionPanelWithInput';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SubTitle } from '../atoms/SubTitle';
import { ActionPanel } from '../molecules/ActionPanel';
import { Comboboxes } from '../atoms/Comboboxes';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTeam } from '../../contexts/TeamContext';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { Modal } from '../molecules/Modal';
import { DeleteModalInput } from '../molecules/DeleteModalInput';

export const SettingsGeneralScreen = () => {
  const navigate = useNavigate();
  /* Access context values using the useTeam hook */
  const { teamUUID, updateTeamName } = useTeam();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);
  const [currentTeam, setCurrentTeam] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  const [selectedOwner, setSelectedOwner] = useState();
  const [shouldDisableTransferButton, setShouldDisableTransferButton] =
    useState(true);
  const [members, setMembers] = useState([]);

  /* BEGINNING CALL API */
  /* Call to get team members */
  const fetchTeamMembersByTeamUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'getTeamMembersByTeamUUID',
      navigate,
      params: {
        teamUUID,
        setPeople: setMembers,
      },
    });
  };

  /* Call to get teams infos */
  const fetchTeamsByUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'getTeamsByUUID',
      navigate,
      params: {
        teamUUID,
        setCurrentTeam,
      },
    });
  };

  /* Call to save teams pref */
  const fetchPutTeamsByUUIDPromise = async (data, name) => {
    const params = {
      [name]:
        data /* Using computed property name to set the property dynamically */,
      uuid: teamUUID,
      fetchTeamsByUUIDPromise,
      updateOpenNotification,
      updateNotificationData,
      updateTeamName,
      teamName: data,
      navigate,
      path: `/settings/teams/${encodeURIComponent(data)}/general`,
    };

    await dynamicApiCall({
      callName: 'putTeamsByUUID',
      params,
    });
  };

  const deleteTeamsByUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'deleteTeamsByUUID',
      navigate,
      params: {
        teamUUID,
        navigate,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page, optionalText) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        optionalText,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* Call to post team transfer ownership */
  const fetchPostTeamTransferOwnership = async ({
    lastOwnerUUID,
    newOwnerUUID,
  }) => {
    await dynamicApiCall({
      callName: 'postTeamTransferOwnership',
      navigate,
      params: {
        navigate,
        teamUUID,
        lastOwnerUUID,
        newOwnerUUID,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* END CALL API */

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(teamUUID, 'TeamsSettingsGeneral', 'settings');
      fetchTeamMembersByTeamUUIDPromise();
      fetchTeamsByUUIDPromise();
    }
  }, [teamUUID]);

  return (
    <SideBarLayout>
      <SubSideBarLayout>
        <main className="lg:pl-72 xl:pl-96">
          <SingleScreenTitle text="General" />
          <SubTitle text="Set plan, owner and team name." />
          <div className="mt-4" />

          <ActionPanel
            title="Account owner"
            description="The Account Owner is the only person who can manage the settings and upgrade/downgrade your plan."
          >
            <Comboboxes
              data={members}
              initialValue={members.find((member) => member.access === 'owner')}
              onClick={(e) => {
                setSelectedOwner(e);
                setShouldDisableTransferButton(
                  members.find((member) => member.access === 'owner') === e
                );
              }}
            />
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => {
                  fetchPostTeamTransferOwnership({
                    lastOwnerUUID: members.find(
                      (member) => member.access === 'owner'
                    )?.uuid,
                    newOwnerUUID: selectedOwner.uuid,
                  });
                }}
                disabled={shouldDisableTransferButton}
                className={`${
                  shouldDisableTransferButton
                    ? `bg-gray-300 cursor-not-allowed`
                    : `bg-indigo-600 hover:bg-indigo-500`
                } mt-3 inline-flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-auto`}
              >
                Transfer
              </button>
            </div>
          </ActionPanel>
          <div className="mt-8" />
          <ActionPanelWithInput
            title="Team name"
            description="Update your team name."
            buttonLabel="Save"
            onClick={(e) => fetchPutTeamsByUUIDPromise(e, 'name')}
            value={currentTeam?.name}
          />
          <div className="mt-8" />
          <ActionPanelWithButtonOnRight
            title="Delete team"
            description="Deleting a team cannot be undone. We'll delete all of your clients, projects, allocations, members and data immediately and permanently."
            buttonLabel="Delete team"
            buttonColor="bg-red-600"
            buttonColorHover="bg-red-500"
            buttonFocusVisible="outline-red-500"
            onClick={() => updateOpenModal(true)}
          />
        </main>

        <Modal open={openModal} setOpen={updateOpenModal}>
          <DeleteModalInput
            labelToCopy={currentTeam?.name}
            onClickCancel={() => updateOpenModal(false)}
            deleteFunction={() => deleteTeamsByUUIDPromise()}
          />
        </Modal>
      </SubSideBarLayout>
    </SideBarLayout>
  );
};
