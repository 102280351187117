import { RadioGroup } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SmallCards = ({
  options,
  selectedOption,
  onChange,
  label,
  sublabel,
  addValueToName = false,
}) => (
  <div>
    <div className="flex items-center justify-between">
      <h2 className="text-sm font-medium leading-6 text-gray-900">
        {label}{' '}
        <span className="text-xs text-gray-600 font-light">{sublabel}</span>
      </h2>
    </div>

    <RadioGroup
      value={selectedOption}
      onChange={(value) => {
        onChange(value);
      }}
      className="mt-2"
    >
      <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
        {options.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ active }) =>
              classNames(
                'cursor-pointer focus:outline-none',
                active ? 'ring-2 ring-indigo-600 ring-offset-2' : '',
                option.isSelected
                  ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold sm:flex-1'
              )
            }
          >
            <RadioGroup.Label as="span">
              <span>{option.name}</span>
              {addValueToName && (
                <span
                  className={` ml-1 text-xs font-light
                        ${option.isSelected ? 'text-white' : 'text-gray-600'}`}
                >
                  ({parseFloat(option.value)}h)
                </span>
              )}
            </RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  </div>
);
