import React, { useState, useEffect } from 'react';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import { useSlideOverFormContext } from './SlideOver';
import { useTeam } from '../../contexts/TeamContext';

export const SlideOverInputNumberWithTradeBridge = ({
  inputType = 'text',
  inputPrefix,
  inputSuffix,
  inputPrefixTraded,
  inputSuffixTraded,
  name,
  nameToWatch,
  nameToWatch2,
  isRequired,
  isUpdatingUser,
}) => {
  const { teamMaxHours, teamDefaultDailyRate, teamDefaultHourlyRate } =
    useTeam();

  const [customMaxHours, setCustomMaxHours] = useState(null);
  const { register, errors, isDirty, setValue, watch } =
    useSlideOverFormContext();

  const hourlyRate = watch(name);
  const hoursPreferences = watch(nameToWatch);
  const dailyRate = watch(nameToWatch2);
  const isCheckedWatch = watch('isChecked');

  const [firstInputValue, setFirstInputValue] = useState(dailyRate);
  const [secondInputValue, setSecondInputValue] = useState(hourlyRate);

  const [initialFirstInputValue, setInitialFirstInputValue] =
    useState(dailyRate);
  const [initialSecondInputValue, setInitialSecondInputValue] =
    useState(hourlyRate);
  const [initialIsCheckedValue, setInitialIsCheckedValue] =
    useState(isCheckedWatch);

  const [isChecked, setIsChecked] = useState(isCheckedWatch);

  /* Clear the inputs when the form is reseted */
  useEffect(() => {
    if (!isDirty) {
      setFirstInputValue(initialFirstInputValue);
      setSecondInputValue(initialSecondInputValue);
      setIsChecked(initialIsCheckedValue);
    }
  }, [initialFirstInputValue, initialSecondInputValue, isDirty]);

  useEffect(() => {
    /*
      Only allow those changes if we are not reseting the form, otherwise when pressing reset,
      the form will be reseted to the default values, and then the useEffect will be triggered
      which will set values to what they were before the reset.
    */
    if (isDirty) {
      if (hoursPreferences) {
        const selectedHours = hoursPreferences.filter((day) => day.isSelected);
        const maxHoursLocal =
          selectedHours.length > 0
            ? Math.max(...selectedHours.map((day) => day.hoursPreferences))
            : 0;

        setCustomMaxHours(maxHoursLocal);

        const valueForFirstInput = isChecked
          ? teamDefaultDailyRate
          : firstInputValue;
        const valueForSecondInput = (
          isChecked
            ? teamDefaultDailyRate / maxHoursLocal
            : firstInputValue / maxHoursLocal
        )
          .toFixed(2)
          .replace(/\.00$/, '');

        setSecondInputValue(valueForSecondInput);
        setFirstInputValue(valueForFirstInput);

        setValue(name, valueForSecondInput, { shouldDirty: true });
        setValue(nameToWatch2, valueForFirstInput, { shouldDirty: true });
      } else {
        setCustomMaxHours(null);

        const valueForFirstInput = isChecked
          ? teamDefaultDailyRate
          : firstInputValue;
        const valueForSecondInput = (
          isChecked
            ? teamDefaultDailyRate / teamMaxHours
            : firstInputValue / teamMaxHours
        )
          .toFixed(2)
          .replace(/\.00$/, '');

        setSecondInputValue(valueForSecondInput);
        setFirstInputValue(valueForFirstInput);

        setValue(name, valueForSecondInput, { shouldDirty: true });
        setValue(nameToWatch2, valueForFirstInput, { shouldDirty: true });
      }
    } else if (isUpdatingUser && hoursPreferences) {
      /* If we are updating the user, and the user has hours preferences to set default values we calculate to his maxHours */
      /* Case of following teamHours but needs to be divided by custom Hours Prefs */
      const selectedHours = hoursPreferences.filter((day) => day.isSelected);
      const maxHoursLocal =
        selectedHours.length > 0
          ? Math.max(...selectedHours.map((day) => day.hoursPreferences))
          : 0;

      setCustomMaxHours(maxHoursLocal);

      setSecondInputValue(
        (dailyRate / maxHoursLocal).toFixed(2).replace(/\.00$/, '')
      );
      setValue(
        name,
        (dailyRate / maxHoursLocal).toFixed(2).replace(/\.00$/, '')
      );
    } else {
      setCustomMaxHours(null);
    }
  }, [hoursPreferences, isChecked]);

  const handleFirstInputChange = (event) => {
    if (event.target.value < 0 || event.target.value === '') {
      event.target.value = 0;
    }

    const effectiveMaxHours = customMaxHours || teamMaxHours;

    const newValue = event.target.value;
    const valueForSecondInput = (newValue / effectiveMaxHours)
      .toFixed(2)
      .replace(/\.00$/, '');
    setFirstInputValue(newValue);
    setSecondInputValue(valueForSecondInput);
    /* Set explicity the value for second input react hook form */
    setValue(name, valueForSecondInput, { shouldDirty: true });
  };

  const handleSecondInputChange = (event) => {
    if (event.target.value < 0 || event.target.value === '') {
      event.target.value = 0;
    }

    const effectiveMaxHours = customMaxHours || teamMaxHours;

    let newValue = event.target.value;

    if (!Number.isNaN(parseFloat(newValue)) && newValue !== '') {
      newValue = parseFloat(newValue).toFixed(2).replace(/\.00$/, '');
    }
    setSecondInputValue(newValue);

    const convertedValue = (parseFloat(newValue) * effectiveMaxHours)
      .toFixed(2)
      .replace(/\.00$/, '');

    setFirstInputValue(convertedValue);
    /* Set explicity the value for first input react hook form */
    setValue(nameToWatch2, convertedValue, { shouldDirty: true });
  };

  return (
    <div className="bg-white sm:col-span-2">
      <div className="max-w-xl text-sm text-gray-500">
        <label className="inline-flex items-center mb-3">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              setValue('isChecked', e.target.checked, { shouldDirty: true });
            }}
          />
          <span className="ml-2">Follow team settings</span>
        </label>
        <p className="mb-3">
          Current max hours worked per day:{' '}
          {customMaxHours !== null
            ? customMaxHours
            : `${teamMaxHours} (team settings)`}
        </p>
      </div>
      <div className="w-full flex space-x-2 bg-white shadow-sm rounded-md">
        <div className="flex-1 relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">{inputPrefix}</span>
          </div>
          <input
            type={inputType}
            name={inputType}
            id={inputType}
            className={`block w-full rounded-md border-gray-300 py-1.5 pl-7 pr-12 text-gray-900 placeholder:text-gray-400 shadow-sm focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm ${
              isChecked ? 'cursor-not-allowed bg-gray-100' : ''
            }`}
            // onChange={handleFirstInputChange}
            // value={firstInputValue}
            {...(register &&
              register(nameToWatch2, {
                required: isRequired ? `This input is required.` : false,
                onChange: handleFirstInputChange,
              }))}
            disabled={isChecked}
            step="any"
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm">{inputSuffix}</span>
          </div>
        </div>

        <div className="flex flex-none items-center justify-center px-2">
          <ArrowPathRoundedSquareIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>

        <div className="flex-1 relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">
              {inputPrefixTraded}
            </span>
          </div>
          <input
            type={inputType}
            name={`${inputType}Traded`}
            id={`${inputType}Traded`}
            className={`block w-full rounded-md border-gray-300 py-1.5 pl-7 pr-16 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm ${
              isChecked ? 'cursor-not-allowed bg-gray-100' : ''
            }`}
            // value={secondInputValue}
            // onChange={(e) => {
            //   handleSecondInputChange(e);
            // }}
            {...(register &&
              register(name, {
                required: isRequired ? `This input is required.` : false,
                onChange: (e) => {
                  handleSecondInputChange(e);
                },
              }))}
            disabled={isChecked}
            step="any"
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm">
              {inputSuffixTraded}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
