import tailwindColors from './tailwindColors';

// Function to group colors by their base hues (excluding grays)
const groupColorsByHue = (colors) => {
  const colorGroups = {
    red: [],
    orange: [],
    yellow: [],
    green: [],
    blue: [],
    indigo: [],
    violet: [],
    purple: [],
    pink: [],
    rose: [],
  };

  colors.forEach((color) => {
    if (color.includes('red')) colorGroups.red.push(color);
    if (color.includes('orange')) colorGroups.orange.push(color);
    if (color.includes('yellow')) colorGroups.yellow.push(color);
    if (color.includes('green')) colorGroups.green.push(color);
    if (color.includes('blue')) colorGroups.blue.push(color);
    if (color.includes('indigo')) colorGroups.indigo.push(color);
    if (color.includes('violet')) colorGroups.violet.push(color);
    if (color.includes('purple')) colorGroups.purple.push(color);
    if (color.includes('pink')) colorGroups.pink.push(color);
    if (color.includes('rose')) colorGroups.rose.push(color);
  });

  return colorGroups;
};

const colorGroups = groupColorsByHue(tailwindColors);

// Function to get a contrasted color with priority to `500` shades
export const getContrastedColor = (index, previousGroup, usedColors) => {
  const colorGroupKeys = Object.keys(colorGroups);
  let groupKey = colorGroupKeys[index % colorGroupKeys.length];

  // Ensure the new group is different from the previous one
  if (groupKey === previousGroup) {
    groupKey = colorGroupKeys[(index + 1) % colorGroupKeys.length] || groupKey;
  }

  // Prioritize the `500` shade
  let color = colorGroups[groupKey].find(
    (c) => c.includes('500') && !usedColors.includes(c)
  );

  // If no available `500` shade, pick a random non-used color
  if (!color) {
    const availableColors = colorGroups[groupKey].filter(
      (c) => !usedColors.includes(c)
    );
    color = availableColors[Math.floor(Math.random() * availableColors.length)];
  }

  // If still no color found, fall back to any available color
  if (!color) {
    color =
      colorGroups[groupKey][
        Math.floor(Math.random() * colorGroups[groupKey].length)
      ];
  }

  return { color, groupKey };
};
