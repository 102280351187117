export const putUserConfirmMailLogic401 = async (datas) => {
  const {
    navigate,
    navigateTo,
    updateOpenNotification,
    updateNotificationData,
    callData,
    setLoading,
    setErrorMessage,
    routeAllocations,
    authToken,
    setError,
    fetchPostNewConfirmationMailPromise,
  } = datas;

  if (callData) {
    if (callData.payload === false) {
      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
      });
      navigateTo({ navigate, routeName: routeAllocations });
    } else {
      setLoading(false);
      setError(true);
      setErrorMessage({
        title: callData.title,
        description: callData.description,
      });

      updateOpenNotification(true);
      updateNotificationData({
        ...callData,
        primaryAction: 'Generate new confirm mail ?',
        primaryActionOnClick: () => {
          fetchPostNewConfirmationMailPromise(authToken);
        },
      });
    }
  }
};
