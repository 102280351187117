export const defaultLogic409 = async (datas) => {
  const {
    updateOpenModal,
    updateNotificationData,
    updateOpenNotification,
    callData,
  } = datas;

  if (updateOpenModal) {
    updateOpenModal(false);
  }
  if (updateNotificationData && updateOpenNotification) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
  }
};
