import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

export const InputWithValidation = ({
  index,
  type,
  updateData,
  errorMessage = null,
}) => (
  <>
    <div className="max-w-lg relative mt-2 rounded-md shadow-sm">
      <input
        type={type}
        name={type}
        autoComplete={type === 'email' ? 'email' : 'off'}
        id={`${type}-${index}`}
        className={`block w-full rounded-md border-0 py-1.5 ${
          !errorMessage ? `text-gray-900` : `text-red-900`
        } shadow-sm ring-1 ring-inset ${
          !errorMessage ? `ring-gray-300` : `ring-red-300`
        }  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:${
          !errorMessage ? `ring-indigo-600` : `ring-red-600`
        } sm:text-sm sm:leading-6`}
        placeholder="Enter email address"
        defaultValue={null}
        aria-invalid="true"
        aria-describedby="input-error"
        onChange={(e) => updateData(index, e.target.value)}
      />

      {errorMessage ? (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      ) : null}
    </div>

    {errorMessage ? (
      <p className="mt-2 text-sm text-red-600" id="input-error">
        {errorMessage}
      </p>
    ) : null}
  </>
);
