import packageJson from '../../../package.json';
import LogoSvg from './LogoSvg';

export const Logo = () => (
  <a
    href={process.env.REACT_APP_LANDING_URL}
    className="flex items-center -m-1.5 p-1.5"
  >
    <span className="sr-only">Site logo</span>
    <div className="w-12 h-12">
      <LogoSvg />
    </div>
    <p className="ml-3 font-bold text-xl">
      {packageJson.name.charAt(0).toUpperCase() + packageJson.name.slice(1)}
    </p>
  </a>
);
