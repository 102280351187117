export const getOverlappingBudgetNames = (
  allocationDate,
  projectBudgets,
  currentBudgetUUID
) => {
  // Ensure projectBudgets is parsed as an array
  const parsedBudgets =
    typeof projectBudgets === 'string'
      ? JSON.parse(`[${projectBudgets}]`)
      : projectBudgets;

  return parsedBudgets
    .filter((projectBudget) => {
      // Skip the current budget
      if (projectBudget.uuid === currentBudgetUUID) return false;

      // Skip the budget if it has been deleted
      if (projectBudget.budgetLinkAssociationDeletedAt !== null) return false;

      const budgetStartDate = new Date(projectBudget.startedAt);
      const budgetEndDate = new Date(projectBudget.endedAt);

      const allocationDateObj = new Date(Date.parse(allocationDate));

      // Check if the allocation date falls within the budget's date range
      return (
        allocationDateObj >= budgetStartDate &&
        allocationDateObj <= budgetEndDate
      );
    })
    .map((projectBudget) => projectBudget.name); // Return the names of the overlapping budgets
};
