export const putTeamClientsLogic500 = async (datas) => {
  const {
    updateOpenSlideOver,
    updateNotificationData,
    updateOpenNotification,
    callData,
  } = datas;

  if (updateOpenSlideOver) {
    updateOpenSlideOver(false);
  }

  if (updateNotificationData && updateOpenNotification) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
  }
};
