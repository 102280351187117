export const getStatsByTeamUUIDLogic = async (datas) => {
  const { setStats, setAllAllocations, callData } = datas;

  if (callData?.stats) {
    setStats(callData.stats);
  }
  if (callData?.allAllocations) {
    setAllAllocations(callData.allAllocations);
  }
};
