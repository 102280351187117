import React, { useState, useEffect, useRef } from 'react';
import { Searchbar } from '../atoms/Searchbar';

export const SelectMenu = ({
  data = [], // Array of data to select from
  value, // Currently selected value
  onChange, // onChange event handler
  displayKey = '', // Key to display in the UI (e.g., 'userFirstName userLastName')
  compareKey = '', // Key to compare for selecting value (e.g., 'userUUID')
  placeholder = 'Select', // Placeholder text for the button
  showSelectEveryone = false, // Flag to show the "Select Everyone" option
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState('left-0');

  useEffect(() => {
    if (value && value.isEveryoneSelected === true) {
      setSelectedItem({ isEveryoneSelected: true });
    } else if (value) {
      const item = data.find((item) => item[compareKey] === value);
      setSelectedItem(item);
    }
  }, [value, data, compareKey]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (buttonRect.right > viewportWidth / 2) {
        setDropdownPosition('right-0');
      } else {
        setDropdownPosition('left-0');
      }
    }
  }, [isDropdownOpen]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setIsDropdownOpen(false);
    setSearchTerm('');
    if (onChange) {
      onChange(item);
    }
  };

  const handleKeyDown = (e, item) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleSelectItem(item);
    }
  };

  const filteredItems = data.filter((item) =>
    displayKey
      .split(' ')
      .some((key) =>
        String(item[key]).toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const formatDisplay = (item) => {
    if (item.isEveryoneSelected) {
      return 'Everyone';
    }

    const displayValues = displayKey.split(' ').map((key) => item[key] || '');
    if (displayValues.every((value) => value === '')) {
      return <em>firstname lastname</em>;
    }
    return displayValues.map((value) => value || '').join(' ');
  };

  return (
    <div className="relative w-full mx-auto min-w-40" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="block w-full py-2 pl-4 pr-10 text-left sm:text-sm text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
        aria-haspopup="listbox"
        aria-expanded={isDropdownOpen}
        ref={buttonRef}
      >
        {selectedItem ? formatDisplay(selectedItem) : placeholder}
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </button>

      {isDropdownOpen && (
        <div
          className={`absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md pb-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${dropdownPosition}`}
        >
          <Searchbar
            placeholder="Search member"
            onChange={handleSearchChange}
          />
          <ul className="py-1 w-full" role="listbox" aria-label="Item list">
            {showSelectEveryone && (
              <li
                onClick={() => handleSelectItem({ isEveryoneSelected: true })}
                onKeyDown={(e) =>
                  handleKeyDown(e, { isEveryoneSelected: true })
                }
                className="relative py-2 pl-3 pr-9 cursor-pointer select-none hover:bg-gray-100 focus:bg-gray-100 border-b border-gray-300"
                role="option"
                tabIndex={0}
                aria-selected={selectedItem?.isEveryoneSelected}
              >
                <span className="block truncate">Everyone</span>
              </li>
            )}
            {filteredItems.map((item) => (
              <li
                key={item[compareKey]}
                onClick={() => handleSelectItem(item)}
                onKeyDown={(e) => handleKeyDown(e, item)}
                className="relative py-2 pl-3 pr-9 cursor-pointer select-none hover:bg-gray-100 focus:bg-gray-100"
                role="option"
                tabIndex={0}
                aria-selected={
                  selectedItem && selectedItem[compareKey] === item[compareKey]
                }
              >
                <span className="block truncate">{formatDisplay(item)}</span>
                {selectedItem &&
                  selectedItem[compareKey] === item[compareKey] && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                        className="w-5 h-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  )}
              </li>
            ))}
            {filteredItems.length === 0 && (
              <li className="relative py-2 px-4 text-gray-700 cursor-default select-none">
                No results found
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
