export const putPasswordByResetTokenLogic = async (datas) => {
  const {
    callData,
    navigate,
    navigateTo,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  updateOpenNotification(true);
  updateNotificationData({
    ...callData,
  });

  navigateTo({ navigate, routeName: `/login` });
};
