import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const StatsWithSharedBoard = ({ data }) => (
  <div className="mb-1">
    <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
      {data.map((item, index) => (
        <div key={index} className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">{item.name}</dt>
          <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
              {parseFloat(item.stat).toFixed(1)}
              <span className="text-sm font-normal">{item.unitLabel}</span>
              <span className="ml-2 text-sm font-medium text-gray-500">
                from {parseFloat(item.stat).toFixed(1)}
              </span>
            </div>

            <div
              className={classNames(
                item.changeType === 'increase'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800',
                'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
              )}
            >
              {item.changeType === 'increase' ? (
                <ArrowUpIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <ArrowDownIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                  aria-hidden="true"
                />
              )}

              <span className="sr-only">
                {' '}
                {item.changeType === 'increase'
                  ? 'Increased'
                  : 'Decreased'} by{' '}
              </span>
              {parseFloat(item.change).toFixed(1)}
            </div>
          </dd>
        </div>
      ))}
    </dl>
  </div>
);
