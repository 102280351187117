import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Header } from '../molecules/Header';
import { FormTitle } from '../atoms/FormTitle';
import { FormSubTitle } from '../atoms/FormSubTitle';
import { Container } from '../atoms/Container';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { NotificationContext } from '../../contexts/NotificationContext';

export default function AskTokenToResetPasswordScreen() {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(45);

  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  useEffect(() => {
    /* Check if an authToken is present in localStorage */
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      dynamicApiCall({
        callName: 'getAuthTokenValidity',
        navigate,
        params: {
          authToken,
        },
      });
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    await dynamicApiCall({
      callName: 'postPasswordResetTokenByUserUUID',
      navigate,
      params: {
        mail: data.mail,
        setIsButtonDisabled,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  // useEffect to handle the timer countdown
  useEffect(() => {
    let interval;
    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return 30;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isButtonDisabled]);

  return (
    <>
      <Header>
        New to Synkros?{' '}
        <Link
          to="/signup"
          className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
        >
          Try for free
        </Link>
      </Header>

      <div className="flex h-5/6 items-center">
        <Container>
          <div className="text-center">
            <FormTitle text="Reset my password" />
            <FormSubTitle text="We will send you a link to reset your password." />
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className={`mt-2 ${errors.mail ? 'border-red-500' : ''}`}>
                  <input
                    id="email"
                    name="email"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                      errors.mail ? 'ring-red-500' : ''
                    }`}
                    autoComplete="email"
                    type="email"
                    {...register('mail', {
                      required: 'Email is required',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Invalid email format',
                      },
                    })}
                  />
                  {errors.mail && (
                    <span className="text-red-500 text-xs">
                      {errors.mail.message}
                    </span>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isButtonDisabled}
                  className={`flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                    isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  Send me a link by mail
                </button>
                {isButtonDisabled && (
                  <p className="mt-2 text-xs text-slate-500" id="input-error">
                    We sent you an email, you can ask for another in {timer}{' '}
                    seconds.
                  </p>
                )}
              </div>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
}
