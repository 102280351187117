// TeamContext.js
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

const TeamContext = createContext();

export const useTeam = () => useContext(TeamContext);

export const TeamProvider = ({ children }) => {
  const [teamUUID, setTeamUUID] = useState(null);
  const [teamName, setTeamName] = useState();
  const [teamCurrencyUUID, setTeamCurrencyUUID] = useState();
  const [teamCurrencySymbol, setTeamCurrencySymbol] = useState();
  const [teamCurrencyName, setTeamCurrencyName] = useState();
  const [teamDefaultHoursPreferences, setTeamDefaultHoursPreferences] =
    useState();
  const [teamDefaultHourlyRate, setTeamDefaultHourlyRate] = useState();
  const [teamDefaultDailyRate, setTeamDefaultDailyRate] = useState();
  const [teamMaxHours, setTeamMaxHours] = useState();

  useEffect(() => {
    if (teamDefaultHoursPreferences) {
      const parsedPreferences = JSON.parse(teamDefaultHoursPreferences);
      setTeamMaxHours(Math.max(...Object.values(parsedPreferences)));
    }
  }, [teamDefaultHoursPreferences]);

  const updateTeamUUID = (newUuid) => {
    setTeamUUID(newUuid);
  };

  const updateTeamName = (newName) => {
    setTeamName(newName);
  };

  const updateTeamCurrencyUUID = (newCurrencyUUID) => {
    setTeamCurrencyUUID(newCurrencyUUID);
  };

  const updateTeamCurrencySymbol = (newCurrencySymbol) => {
    setTeamCurrencySymbol(newCurrencySymbol);
  };

  const updateTeamCurrencyName = (newCurrencyName) => {
    setTeamCurrencyName(newCurrencyName);
  };

  const updateTeamDefaultHoursPreferences = (newDefaultHoursPreferences) => {
    setTeamDefaultHoursPreferences(newDefaultHoursPreferences);
  };

  const updateTeamDefaultHourlyRate = (newHourlyRate) => {
    setTeamDefaultHourlyRate(newHourlyRate);
  };

  const updateTeamDefaultDailyRate = (newDailyRate) => {
    setTeamDefaultDailyRate(newDailyRate);
  };

  const contextValue = useMemo(
    () => ({
      teamUUID,
      teamName,
      teamCurrencyUUID,
      teamCurrencySymbol,
      teamCurrencyName,
      teamDefaultHoursPreferences,
      teamDefaultHourlyRate,
      teamMaxHours,
      teamDefaultDailyRate,
      updateTeamUUID,
      updateTeamName,
      updateTeamCurrencyUUID,
      updateTeamCurrencySymbol,
      updateTeamCurrencyName,
      updateTeamDefaultHoursPreferences,
      updateTeamDefaultHourlyRate,
      updateTeamDefaultDailyRate,
    }),
    [
      teamUUID,
      teamName,
      teamCurrencyUUID,
      teamCurrencySymbol,
      teamCurrencyName,
      teamDefaultHoursPreferences,
      teamDefaultHourlyRate,
      teamMaxHours,
      teamDefaultDailyRate,
    ]
  );

  return (
    <TeamContext.Provider value={contextValue}>{children}</TeamContext.Provider>
  );
};
