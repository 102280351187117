export const getAuthTokenValidityLogic = async (datas) => {
  const { navigate, navigateTo, callData } = datas;

  if (
    window.location.pathname.includes('/login') ||
    window.location.pathname.includes('/signup') ||
    window.location.pathname.includes('/resetPassword')
  ) {
    navigateTo({ navigate, routeName: `/portfolio` });
  }
};
