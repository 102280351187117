import { useEffect, useState, useRef } from 'react';
import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

export const SlideOverInputRange = ({
  name,
  isRequired,
  disabled,
  maxNumber,
  defaultValue = 0,
}) => {
  const { register, errors, watch, setValue } = useSlideOverFormContext();
  const currentValue = watch(name) ?? defaultValue; // Use defaultValue if currentValue is undefined

  const [inputValue, setInputValue] = useState(currentValue);
  const sliderRef = useRef(null);

  useEffect(() => {
    setInputValue(disabled ? maxNumber : currentValue);
  }, [currentValue, disabled, maxNumber]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setValue(name, newValue); // Update form value
  };

  const handleSliderClick = (event) => {
    if (disabled) return;
    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    const newValue = Math.round((offsetX / rect.width) * maxNumber);
    setInputValue(newValue);
    setValue(name, newValue); // Update form value
  };

  const handleKeyDown = (event) => {
    if (disabled) return;
    let newValue = inputValue;
    if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
      newValue = Math.min(maxNumber, inputValue + 1);
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
      newValue = Math.max(0, inputValue - 1);
    }
    setInputValue(newValue);
    setValue(name, newValue); // Update form value
  };

  const steps = Array.from({ length: maxNumber + 1 }, (_, index) => index);

  return (
    <div className="sm:col-span-2 flex flex-col space-y-2 w-full">
      <div className="flex items-center space-x-4 w-full">
        <div className="relative w-full">
          <div
            ref={sliderRef}
            className="w-full h-6 appearance-none rounded-lg bg-gray-100 relative"
            onClick={handleSliderClick}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            role="slider"
            aria-valuemin={0}
            aria-valuemax={maxNumber}
            aria-valuenow={inputValue}
            aria-disabled={disabled}
            style={{
              background: `linear-gradient(to right, #4f46e5 0%, #4f46e5 ${
                (inputValue / maxNumber) * 100
              }%, #e5e7eb ${(inputValue / maxNumber) * 100}%, #e5e7eb 100%)`,
            }}
          >
            <input
              id={name}
              name={name}
              type="range"
              min="0"
              max={maxNumber}
              value={inputValue}
              onChange={handleChange}
              {...register(name, {
                required: isRequired ? `This input is required.` : false,
              })}
              className="absolute w-full h-6 opacity-0 cursor-pointer"
              disabled={disabled}
            />
            <div className="absolute inset-0 flex justify-between items-center pointer-events-none h-6 mx-3">
              {steps.map((step, index) => (
                <span
                  key={index}
                  className={`${
                    step === parseInt(inputValue)
                      ? 'font-medium text-sm text-white bg-indigo-600 py-1 px-2 rounded-full h-8 w-8 flex items-center justify-center'
                      : step < inputValue
                      ? 'text-white text-xs'
                      : 'text-gray-500 text-xs'
                  } ${disabled && step !== maxNumber ? 'hidden' : ''}`}
                  style={{
                    position: 'absolute',
                    left: `${(index / maxNumber) * 100}%`,
                    transform: 'translate(-50%, -50%)',
                    top: '50%',
                  }}
                >
                  {!disabled || step === maxNumber ? `${step}h` : ''}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      {disabled && (
        <div className="text-gray-500 text-xs mt-2">
          Hours selection is locked at the maximum due to multiple dates being
          selected.
        </div>
      )}
      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};
