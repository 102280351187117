// src/utils.js

// Function to capitalize the first letter of a string
export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

// Function to transform team default hours preferences into the desired format
export const transformTeamDefaultHoursPreferences = (
  teamDefaultHoursPreferences
) => {
  const parsedPreferences = JSON.parse(teamDefaultHoursPreferences);

  return Object.entries(parsedPreferences).map(([day, hours]) => ({
    id: capitalizeFirstLetter(day),
    name: capitalizeFirstLetter(day),
    isSelected: hours > 0,
    hoursPreferences: hours,
  }));
};
