export const postTeamTagsLogic = async (datas) => {
  const {
    callData,
    teamUUID,
    fetchTeamTagsByTeamUUIDPromise,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  await fetchTeamTagsByTeamUUIDPromise({
    teamUUID,
    fetchNumberTagUsedInProjects: true,
  });
  if (updateOpenNotification && updateNotificationData) {
    updateOpenNotification(true);
    updateNotificationData({ ...callData });
  }
};
