import React, { useState, useEffect } from 'react';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';

export const ActionPanelWithInputTradeBridge = ({
  title,
  description,
  buttonLabel,
  onChange,
  onClick,
  value = null,
  inputType = 'text',
  inputPrefix,
  inputSuffix,
  inputPrefixTraded,
  inputSuffixTraded,
  maxHours = 8,
}) => {
  const [firstInputValue, setFirstInputValue] = useState('');
  const [secondInputValue, setSecondInputValue] = useState('');
  const [initialFirstInputValue, setInitialFirstInputValue] = useState('');
  const [initialSecondInputValue, setInitialSecondInputValue] = useState('');

  useEffect(() => {
    if (value !== null) {
      const initialSecondValue = (value / maxHours)
        .toFixed(2)
        .replace(/\.00$/, '');
      setFirstInputValue(value.toString());
      setSecondInputValue(initialSecondValue);
      setInitialFirstInputValue(value.toString());
      setInitialSecondInputValue(initialSecondValue);
    }
  }, [value, maxHours]);

  const handleFirstInputChange = (event) => {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    const newValue = event.target.value;
    setFirstInputValue(newValue);
    setSecondInputValue((newValue / maxHours).toFixed(2).replace(/\.00$/, ''));
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleSecondInputChange = (event) => {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    let newValue = event.target.value;
    if (!Number.isNaN(parseFloat(newValue)) && newValue !== '') {
      newValue = parseFloat(newValue).toFixed(2).replace(/\.00$/, '');
    }
    setSecondInputValue(newValue);
    const convertedValue = (parseFloat(newValue) * maxHours)
      .toFixed(2)
      .replace(/\.00$/, '');
    setFirstInputValue(convertedValue);
    if (onChange) {
      onChange(convertedValue);
    }
  };

  const isModified =
    firstInputValue !== initialFirstInputValue ||
    secondInputValue !== initialSecondInputValue;

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {title}
        </h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>{description}</p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClick({
              firstValue: firstInputValue,
              secondValue: secondInputValue,
            });
          }}
          className="mt-5 sm:flex sm:items-center"
        >
          <div className="w-full flex space-x-2 bg-white shadow-sm rounded-md">
            <div className="flex-1 relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">{inputPrefix}</span>
              </div>
              <input
                type={inputType}
                name={inputType}
                id={inputType}
                className="block w-full rounded-none rounded-l-md border-gray-300 py-1.5 pl-7 pr-12 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                onChange={handleFirstInputChange}
                value={firstInputValue}
                step="any"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">{inputSuffix}</span>
              </div>
            </div>

            <div className="flex flex-none items-center justify-center px-2">
              <ArrowPathRoundedSquareIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>

            <div className="flex-1 relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">
                  {inputPrefixTraded}
                </span>
              </div>
              <input
                type={inputType}
                name={`${inputType}Traded`}
                id={`${inputType}Traded`}
                className="block w-full rounded-none rounded-r-md border-gray-300 py-1.5 pl-7 pr-16 text-gray-900 placeholder:text-gray-400 focus:1 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                onChange={handleSecondInputChange}
                value={secondInputValue}
                step="any"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm">
                  {inputSuffixTraded}
                </span>
              </div>
            </div>
          </div>
          <button
            type="submit"
            disabled={!isModified}
            className={`mt-3 inline-flex w-full items-center justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:mt-0 sm:w-auto ${
              isModified
                ? 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {buttonLabel}
          </button>
        </form>
      </div>
    </div>
  );
};
