import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export const Accordion = ({
  title,
  children,
  className,
  icon,
  buttonClassName,
  chevronClassName,
}) => (
  <div className={`mt-4 w-full ${className}`}>
    <Disclosure as="div">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`${buttonClassName} flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-indigo-100 rounded-lg hover:bg-indigo-200 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75`}
          >
            <span className="flex">
              {icon}
              {title}
            </span>
            <ChevronDownIcon
              className={`${
                open ? 'transform rotate-180' : ''
              } w-5 h-5 ${chevronClassName} text-indigo-900`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="pb-2 px-4 text-sm">
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  </div>
);
