export const deleteTeamsByUUIDLogic = async (datas) => {
  const {
    callData,
    navigateTo,
    navigate,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });

    navigateTo({ navigate, routeName: `/login` });
  }
};
