export const ActionPanelWithButtonOnRight = ({
  title,
  description,
  buttonLabel,
  onClick,
  buttonColor = 'bg-indigo-600',
  buttonColorHover = 'bg-indigo-500',
  buttonFocusVisible = 'outline-indigo-500',
}) => (
  <div className="bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </h3>
      <div className="mt-2 sm:flex sm:items-start sm:justify-between">
        <div className="max-w-xl text-sm text-gray-500">
          <p>{description}</p>
        </div>
        <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
          <button
            type="button"
            onClick={onClick}
            className={`inline-flex items-center rounded-md ${buttonColor} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:${buttonColorHover} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:${buttonFocusVisible}`}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  </div>
);
