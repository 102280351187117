import { useNavigate } from 'react-router-dom';
import { ArrowDownTrayIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { useEffect, useContext, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTeam } from '../../contexts/TeamContext';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { PageHeadingButton } from '../molecules/PageHeadingButton';
import { StatsWithSharedBoard } from '../molecules/StatsWithSharedBorder';
import { DividerWithTitleOnLeft } from '../atoms/DividerWithTitleOnLeft';
import { Container } from '../atoms/Container';
import { beginningOfYear, endOfYear } from '../../services/utils/momentUtils';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { Accordion } from '../molecules/Accordion';
import { CategoryFilter } from '../molecules/CategoryFilters';
import { exportDataInXlsx } from '../../services/utils/export';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function ReportsScreen() {
  const navigate = useNavigate();
  /* Access context values using the useTeam hook */
  const { teamUUID, teamName } = useTeam();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [allAllocations, setAllAllocations] = useState();
  const [stats, setStats] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [pieChart, setPieChart] = useState({
    labels: [],
    datasets: [
      {
        label: '# of Votes',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });
  const [linesChart, setLinesChart] = useState({
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    datasets: [],
  });

  const updateFromDate = (date) => {
    setFromDate(date);
  };

  const updateToDate = (date) => {
    setToDate(date);
  };

  /* BEGINNING CALL API */
  /* Get stats of the team */
  const fetchStatsByTeamUUIDPromise = async ({
    teamUUID,
    dateStart = beginningOfYear(),
    dateEnd = endOfYear(),
  }) => {
    await dynamicApiCall({
      callName: 'getStatsByTeamUUID',
      navigate,
      params: {
        teamUUID,
        dateStart,
        dateEnd,
        setStats,
        setAllAllocations,
      },
    });
  };

  /* Get filters data used in header */
  const fetchTeamReportingFiltersPromise = async ({ teamUUID }) => {
    await dynamicApiCall({
      callName: 'getTeamReportingFilters',
      navigate,
      params: {
        teamUUID,
        setFiltersData,
      },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  const fetchTeamReportingPieChartPromise = async ({
    teamUUID,
    dateStart,
    dateEnd,
    filterOutProjects,
    filterOutMembers,
  }) => {
    await dynamicApiCall({
      callName: 'getTeamReportingPieChart',
      navigate,
      params: {
        teamUUID,
        from: dateStart,
        to: dateEnd,
        setPieChart,
        filterOutProjects,
        filterOutMembers,
      },
    });
  };

  const fetchTeamReportingMultipleLineChartPromise = async ({
    teamUUID,
    dateStart,
    dateEnd,
    filterOutClients,
    filterOutMembers,
  }) => {
    await dynamicApiCall({
      callName: 'getTeamReportingMultipleLineChart',
      navigate,
      params: {
        teamUUID,
        from: dateStart,
        to: dateEnd,
        setLinesChart,
        filterOutClients,
        filterOutMembers,
      },
    });
  };

  /* END CALL API */

  const handleCSVExport = async () => {
    const sheets = [stats, allAllocations];

    const fileName = `${teamName}_export.xlsx`;

    exportDataInXlsx(sheets, fileName).then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName; // Use the provided fileName
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      /* Notify the user */
      updateOpenNotification(true);
      updateNotificationData({
        type: 'success',
        title: 'Download started',
        description: 'Your Excel file is being downloaded.',
      });
    });
  };

  /* If changes on date make a call stats api with it */
  useEffect(() => {
    if (teamUUID) {
      const requestData = {
        teamUUID,
      };

      if (fromDate && fromDate.trim().length !== 0) {
        requestData.dateStart = fromDate;
      }

      if (toDate && toDate.trim().length !== 0) {
        requestData.dateEnd = toDate;
      }

      fetchStatsByTeamUUIDPromise(requestData);
      fetchTeamReportingPieChartPromise(requestData);
      fetchTeamReportingMultipleLineChartPromise(requestData);
    }
  }, [fromDate, toDate, teamUUID]);

  useEffect(() => {
    if (teamUUID) {
      setFromDate(beginningOfYear());
      setToDate(endOfYear());

      fetchAccessPagePromise(teamUUID, 'Reports');
      fetchTeamReportingFiltersPromise({ teamUUID });
    }
  }, [teamUUID]);

  /* Listen filters by tag/projetct/members */
  useEffect(() => {
    if (filtersData.length > 0) {
      const unselectedProject = [];
      const unselectedTags = [];
      const unselectedMembers = [];
      const unselectedClients = [];

      filtersData.forEach((filter) => {
        if (filter.id === 'projects') {
          filter.options.map((option) =>
            option.isSelected === false
              ? unselectedProject.push(option.uuid)
              : null
          );
        }
        if (filter.id === 'tags') {
          filter.options.map((option) =>
            option.isSelected === false
              ? unselectedTags.push(option.uuid)
              : null
          );
        }
        if (filter.id === 'members') {
          filter.options.map((option) =>
            option.isSelected === false
              ? unselectedMembers.push(option.uuid)
              : null
          );
        }
        if (filter.id === 'clients') {
          filter.options.map((option) =>
            option.isSelected === false
              ? unselectedClients.push(option.uuid)
              : null
          );
        }
      });

      fetchTeamReportingPieChartPromise({
        teamUUID,
        dateStart: fromDate,
        dateEnd: toDate,
        filterOutProjects: unselectedProject.join(','),
        filterOutMembers: unselectedMembers.join(','),
      });

      fetchTeamReportingMultipleLineChartPromise({
        teamUUID,
        dateStart: fromDate,
        dateEnd: toDate,
        filterOutClients: unselectedClients.join(','),
        filterOutMembers: unselectedMembers.join(','),
      });
    }
  }, [filtersData]);

  return (
    <SideBarLayout>
      <div className="px-4 h-full sm:px-6 lg:px-8">
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex items-center justify-between">
              <SingleScreenTitle text="Reports" />
              <PageHeadingButton
                label="Export all data"
                hoverEffect="hover:bg-gray-50"
                buttonColors="flex ring-1 ring-inset ring-gray-300 text-gray-900 bg-white hover:bg-gray-50"
                onClick={() => {
                  handleCSVExport();
                }}
              >
                <ArrowDownTrayIcon
                  className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </PageHeadingButton>
            </div>
            <Accordion
              title="Filters"
              className="text-indigo-900 bg-indigo-100 rounded-lg"
              icon={<FunnelIcon className="w-5 h-5 mr-1 text-indigo-900" />}
            >
              <CategoryFilter
                initialToDate={toDate}
                initialFromDate={fromDate}
                filtersData={filtersData}
                setFiltersData={setFiltersData}
                onCalendarDateChange={(from, to) => {
                  updateFromDate(from);
                  updateToDate(to);
                }}
              />
            </Accordion>
            <StatsWithSharedBoard data={stats} />

            <div className="mt-6" />
            <div className="flex space-x-6">
              <Container>
                <DividerWithTitleOnLeft
                  color="bg-white"
                  title="Hours allocated by clients"
                />
                {linesChart.datasets && linesChart.datasets.length > 0 ? (
                  <Line
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          position: 'top',
                        },
                        title: {
                          display: false,
                          text: 'Chart.js Line Chart',
                        },
                      },
                    }}
                    data={linesChart}
                  />
                ) : (
                  <p className="flex justify-center items-center mt-5">
                    No data available for this period
                  </p>
                )}
              </Container>

              <Container>
                <DividerWithTitleOnLeft
                  color="bg-white"
                  title="Hours allocated by projects"
                />
                {pieChart.labels && pieChart.labels.length > 0 ? (
                  <Pie data={pieChart} />
                ) : (
                  <p className="flex justify-center items-center mt-5">
                    No data available for this period
                  </p>
                )}
              </Container>
            </div>
          </div>
        </div>
      </div>
    </SideBarLayout>
  );
}
