export const TableCellPulseLoader = ({ data, index }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 pr-3'
      )}
    >
      <div className="animate-pulse bg-gray-300 h-4 rounded" />
    </td>
  );
};
