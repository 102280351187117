export const getTeamMembersPreferencesAndSetOptionsLogic = async (datas) => {
  const { callData, setTimeLabel, setCreateUpdateValue, setOptions } = datas;

  if (callData?.label) {
    setTimeLabel(callData.label);
    setOptions(callData.options);
    if (setCreateUpdateValue) {
      const selectedOption = callData.options.find(
        (option) => option.isSelected
      );
      if (selectedOption) {
        setCreateUpdateValue((prev) => ({
          ...prev,
          hours: selectedOption.value,
        }));
      }
    }
  }
};
