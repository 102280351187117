import { useEffect, useState } from 'react';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';

export const AddMemberPopupHeader = ({
  data,
  handleInputChange,
  isMailInputDisabled = false,
  register,
  errors,
  isMailRequired = false,
}) => {
  const [firstName, setFirstName] = useState(data?.firstName);
  const [lastName, setLastName] = useState(data?.lastName);
  const [mail, setMail] = useState(data?.mail);
  const [firstNameInitial, setFirstNameInitial] = useState('-');
  const [lastNameInitial, setLastNameInitial] = useState('-');

  useEffect(() => {
    if (data) {
      setFirstName(data.firstName || '');
      setLastName(data.lastName || '');
      setMail(data.mail || '');
      setFirstNameInitial(data?.firstName?.charAt(0) || '-');
      setLastNameInitial(data?.lastName?.charAt(0) || '-');
    }
  }, [data]);

  const localHandleInputChange = (e) => {
    // Update local state
    if (e.target.name === 'firstName') {
      setFirstName(e.target.value);
      setFirstNameInitial(e.target.value.charAt(0));
    } else if (e.target.name === 'lastName') {
      setLastName(e.target.value);
      setLastNameInitial(e.target.value.charAt(0));
    } else if (e.target.name === 'mail') {
      setMail(e.target.value);
    }

    /* Call the original handleInputChange */
    handleInputChange(e);
  };

  return (
    <div className="flex flex-row">
      <div className="flex-grow">
        <input
          type="text"
          name="firstName"
          id="firstName"
          className="block w-full border-0 p-0 text-lg text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
          placeholder="First name"
          value={firstName}
          {...(register && register('firstName', {}))}
          onChange={(e) => {
            localHandleInputChange(e);
          }}
        />
        {errors && errors.firstName && (
          <span className="text-red-500 text-xs">
            {errors.firstName.message}
          </span>
        )}
        <input
          type="text"
          name="lastName"
          id="lastName"
          className="block w-full border-0 p-0 text-base text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
          placeholder="Last name"
          value={lastName}
          {...(register && register('lastName', {}))}
          onChange={(e) => {
            localHandleInputChange(e);
          }}
        />
        {errors && errors.lastName && (
          <span className="text-red-500 text-xs">
            {errors.lastName.message}
          </span>
        )}
        <input
          type="text"
          name="mail"
          id="mail"
          disabled={isMailInputDisabled}
          className="block w-full border-0 p-0 text-sm text-gray-600 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
          value={mail}
          {...(register &&
            register('mail', {
              validate: (value) => {
                if (isMailRequired && !value) {
                  return 'Email field is required';
                }

                if (value && !/^\S+@\S+$/i.test(value)) {
                  return 'Invalid email format';
                }

                return true;
              },
            }))}
          onChange={(e) => {
            localHandleInputChange(e);
          }}
          placeholder={isMailRequired ? 'Email*' : 'Email'}
        />
        {errors && errors.mail && (
          <span className="text-red-500 text-xs">{errors.mail.message}</span>
        )}
      </div>
      <div>
        <AvatarWithPlaceholder
          placeholder={`${firstNameInitial}${lastNameInitial}`}
          size="lg"
        />
      </div>
    </div>
  );
};
