import ExcelJS from 'exceljs';

export function exportDataInXlsx(sheets) {
  const workbook = new ExcelJS.Workbook();

  sheets.forEach((data, index) => {
    const sheetName = `Sheet${index + 1}`;
    const sheet = workbook.addWorksheet(sheetName);

    /* Add column headers */
    sheet.addRow(Object.keys(data[0]));

    /* Add data */
    data.forEach((row) => sheet.addRow(Object.values(row)));
  });

  /* Save the workbook to a Blob */
  return workbook.xlsx.writeBuffer().then(
    (blob) =>
      /* Create a Blob and return it */
      new Blob([blob], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
  );
}
