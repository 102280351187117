import { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';
import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

export const SlideOverInputItemsToggle = ({ name, data }) => {
  const { errors, setValue, watch } = useSlideOverFormContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [preprocessData, setPreprocessData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Moved data preprocessing into this useEffect

    const identifyAndSetDataType = (data) => {
      if (
        data.length > 0 &&
        Object.prototype.hasOwnProperty.call(data[0], 'tagUUID')
      ) {
        const modifiedData = data.map((item) => ({
          ...item,
          type: 'tags',
          uuid: item.tagUUID,
        }));
        setPreprocessData(modifiedData);
      } else if (
        data.length > 0 &&
        Object.prototype.hasOwnProperty.call(data[0], 'budgets')
      ) {
        const modifiedData = data.map((item) => ({
          ...item,
          type: 'projects',
        }));
        setPreprocessData(modifiedData);
      } else if (
        data.length > 0 &&
        Object.prototype.hasOwnProperty.call(data[0], 'teamUUID')
      ) {
        const modifiedData = data.map((item) => ({
          ...item,
          type: 'members',
        }));
        setPreprocessData(modifiedData);
      } else {
        setPreprocessData(data);
      }
    };

    identifyAndSetDataType(data);
  }, [data]);

  // Ensuring toggleStates is always an object, even if watch returns null or undefined
  const toggleStates = watch(name) || {};

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        if (searchQuery) {
          setSearchQuery('');
          event.stopPropagation(); // Prevent the dialog from closing
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [searchQuery]);

  const handleToggle = (uuid) => {
    const newState = { ...toggleStates, [uuid]: !toggleStates[uuid] };
    setValue(name, newState, { shouldDirty: true });
  };

  const selectAll = () => {
    const allSelected = preprocessData.reduce(
      (acc, item) => ({ ...acc, [item.uuid]: true }),
      {}
    );
    setValue(name, allSelected, { shouldDirty: true });
  };

  const unselectAll = () => {
    const noneSelected = preprocessData.reduce(
      (acc, item) => ({ ...acc, [item.uuid]: false }),
      {}
    );
    setValue(name, noneSelected, { shouldDirty: true });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleKeyDown = (event, uuid) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleToggle(uuid, event);
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    if (preprocessData) {
      const newFilteredData = preprocessData.filter((item) =>
        item.type === 'tags' ||
        item.type === 'projects' ||
        item.dataType === 'budgets'
          ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
          : `${item.firstName} ${item.lastName}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
      );
      setFilteredData(newFilteredData);
    }
  }, [preprocessData, searchQuery]);

  return (
    <div className="sm:col-span-2">
      <div className="flex justify-between items-center mb-4">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Research..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md placeholder-gray-400"
          />
          {searchQuery && (
            <button
              type="button"
              onClick={clearSearch}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              <svg
                className="h-5 w-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>

        <div className="flex gap-x-2 ml-4">
          <button
            type="button"
            onClick={selectAll}
            className="text-xs text-indigo-500 hover:text-indigo-700"
          >
            All
          </button>
          <button
            type="button"
            onClick={unselectAll}
            className="text-xs text-red-600 hover:text-red-800"
          >
            Unselect
          </button>
        </div>
      </div>
      <ul
        role="list"
        className="divide-y divide-gray-200 overflow-y-auto max-h-44"
      >
        {filteredData.map((item) => (
          <li
            key={item.uuid}
            className="py-4 flex justify-between items-center"
          >
            <div
              role="button"
              tabIndex={0}
              className="flex items-center justify-between w-full text-left"
              onClick={() => handleToggle(item.uuid)}
              onKeyDown={(e) => handleKeyDown(e, item.uuid)}
            >
              <div className="flex items-center cursor-pointer">
                <AvatarWithPlaceholder
                  placeholder={
                    item.type === 'tags' ||
                    item.type === 'projects' ||
                    item.dataType === 'budgets'
                      ? ` `
                      : item.type === 'members' &&
                        item.firstName &&
                        item.lastName
                      ? `${item.firstName[0]}${item.lastName[0]}`.toUpperCase()
                      : `-`
                  }
                  className={item.type === 'tags' ? item.colors : 'bg-gray-500'}
                  size="sm"
                />
                <p className="ml-4 text-sm font-medium text-gray-900">
                  {item.type === 'tags' ||
                  item.type === 'projects' ||
                  item.dataType === 'budgets' ? (
                    item.name
                  ) : item.type === 'members' &&
                    item.firstName &&
                    item.lastName ? (
                    `${item.firstName} ${item.lastName}`
                  ) : (
                    <span className="italic font-light text-gray-400">
                      Firstname LASTNAME
                    </span>
                  )}
                </p>
              </div>
              <div className="min-w-11">
                <Switch
                  checked={toggleStates[item.uuid] || false}
                  onChange={() => {}}
                  className={`${
                    toggleStates[item.uuid] ? 'bg-green-600' : 'bg-gray-200'
                  } mr-4 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
                  onClick={(e) => handleToggle(item.uuid, e)}
                >
                  <span className="sr-only">Enable</span>
                  <span
                    className={`${
                      toggleStates[item.uuid]
                        ? 'translate-x-6'
                        : 'translate-x-1'
                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                  />
                </Switch>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};
