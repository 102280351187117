import React, { useState, useEffect } from 'react';
import { useSlideOverFormContext } from './SlideOver';
import { Checkboxes } from './Checkboxes';
import { SmallCards } from './SmallCards';
import { useTeam } from '../../contexts/TeamContext';
import { transformTeamDefaultHoursPreferences } from '../../services/utils/utils';

export const SlideOverInputWorkDays = ({ name }) => {
  const { teamDefaultHoursPreferences } = useTeam();

  const { setValue, watch } = useSlideOverFormContext();
  /* Setting the default values for the form if they exist */
  const currentValueHoursPrefs = watch(name);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);

  const options = [
    {
      id: 'teamSettings',
      name: 'Team settings',
      isSelected: selectedOption?.id === 'teamSettings',
    },
    {
      id: 'partTime',
      name: 'Custom',
      isSelected: selectedOption?.id === 'partTime',
    },
  ];

  const registerInReactHookForm = (value) => {
    setValue(name, value, {
      shouldDirty: true,
    });
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);

    if (option.id === 'teamSettings') {
      registerInReactHookForm(null);
    } else if (option.id === 'partTime') {
      registerInReactHookForm(selectedDays);
    }
  };

  const handleDayChange = (dayId, isSelected) => {
    /* Count the number of currently selected days */
    const selectedCount = selectedDays.filter((day) => day.isSelected).length;

    /* If we are trying to unselect the last selected day, do nothing */
    if (!isSelected && selectedCount === 1) {
      return;
    }

    const updatedDays = selectedDays.map((day) =>
      day.id === dayId ? { ...day, isSelected } : day
    );
    setSelectedDays(updatedDays);

    if (selectedOption.id === 'teamSettings') {
      registerInReactHookForm(null);
    } else if (selectedOption.id === 'partTime') {
      registerInReactHookForm(updatedDays);
    }
  };

  const handleHoursChange = (event) => {
    const { name, value } = event.target;

    const dayId = name.charAt(0).toUpperCase() + name.slice(1);

    const updatedDays = selectedDays.map((day) =>
      day.id === dayId ? { ...day, hoursPreferences: Number(value) } : day
    );
    setSelectedDays(updatedDays);

    if (selectedOption.id === 'teamSettings') {
      registerInReactHookForm(null);
    } else if (selectedOption.id === 'partTime') {
      registerInReactHookForm(updatedDays);
    }
  };

  /*  When hours preferences changes (can come from slideOverInputNumberWithTradeBridge with the checkboxes) 
      we need to update selectedDays accordingly to match react hook form values 
  */
  useEffect(() => {
    if (currentValueHoursPrefs) {
      setSelectedOption({ id: 'partTime' });
      setSelectedDays(currentValueHoursPrefs);
    } else {
      setSelectedOption({ id: 'teamSettings' });

      const transformedDays = transformTeamDefaultHoursPreferences(
        teamDefaultHoursPreferences
      );
      setSelectedDays(transformedDays);
    }
  }, [currentValueHoursPrefs, teamDefaultHoursPreferences]);

  return (
    <div className="sm:col-span-2">
      <SmallCards
        options={options}
        selectedOption={selectedOption}
        onChange={handleOptionChange}
      />

      {selectedOption?.id === 'partTime' && (
        <Checkboxes
          options={selectedDays}
          onChange={handleDayChange}
          onHoursChanges={handleHoursChange}
        />
      )}
    </div>
  );
};
