import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

export const SlideOverInputCheckbox = ({
  name,
  label,
  sublabel,
  isRequired,
}) => {
  const { register, errors } = useSlideOverFormContext();

  return (
    <div className="sm:col-span-2">
      <div className="flex items-center">
        <input
          id={name}
          name={name}
          type="checkbox"
          {...(register &&
            register(name, {
              required: isRequired ? `This input is required.` : false,
            }))}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2"
        />
        <label htmlFor={name} className="text-sm text-gray-900">
          {label}
        </label>
      </div>

      <InputErrorMessage errors={errors} name={name} />

      {sublabel && (
        <p className="text-sm font-light text-gray-500 mt-1">{sublabel}</p>
      )}
    </div>
  );
};
