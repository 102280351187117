export const postNewConfirmationMailLogic401 = async (datas) => {
  const {
    navigate,
    navigateTo,
    setErrorMessage,
    updateOpenNotification,
    updateNotificationData,
    callData,
  } = datas;

  setErrorMessage({
    title: callData.title,
    description: callData.description,
  });
  updateOpenNotification(true);
  updateNotificationData({
    ...callData,
  });
};
