export const ModalHandleButtons = ({
  onClick,
  showDeleteButton = false,
  onDelete = null,
  label = `Save`,
  onClickCancel,
  labelCancel = `Cancel`,
  className = `bg-indigo-600 hover:bg-indigo-500`,
  disabled = false,
}) => (
  <div
    className={`mt-8 sm:mt-8 flex ${
      showDeleteButton ? 'justify-between' : 'justify-end'
    }`}
  >
    {showDeleteButton && (
      <button
        type="button"
        className="inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600"
        onClick={onDelete}
      >
        Delete
      </button>
    )}
    <div>
      <button
        type="button"
        className="mt-3 mr-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        onClick={onClickCancel}
      >
        {labelCancel}
      </button>
      <button
        type="submit"
        className={`
        ${disabled ? 'bg-gray-300 cursor-not-allowed' : className}
        inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold
        text-white shadow-sm sm:w-auto
      `}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  </div>
);
