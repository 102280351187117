/* Sort from A to Z the clients and projects associated */
export function sortClientsAndProjects(clientsAndProjects) {
  // First, sort the clients by their names in alphabetical order
  const sortedClients = [...clientsAndProjects].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Then, sort the projects within each client by their names in alphabetical order
  sortedClients.forEach((client) => {
    if (client.projects && client.projects.length > 0) {
      client.projects.sort((a, b) => a.name.localeCompare(b.name));
    }
  });

  return sortedClients;
}
