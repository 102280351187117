import { Fragment, useState, useEffect } from 'react';
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { CalendarPicker } from '../atoms/CalendarPicker';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const CategoryFilter = ({
  initialFromDate = null,
  initialToDate = null,
  filtersData = null,
  setFiltersData = null,
  onCalendarDateChange = null,
  hideCalendar = false,
}) => {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(filtersData);

  useEffect(() => {
    if (filtersData.length > 0 && setFiltersData) {
      setFilters(filtersData);
    }
  }, [filtersData]);

  /* Update parent state */
  useEffect(() => {
    if (setFiltersData) {
      setFiltersData(filters);
    }
  }, [filters]);

  // Function to toggle the isSelected property when a checkbox is clicked
  const handleCheckboxChange = (sectionIndex, optionIndex) => {
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[sectionIndex].options[optionIndex].isSelected =
        !updatedFilters[sectionIndex].options[optionIndex].isSelected;
      return updatedFilters;
    });
  };

  // Function to select all options within a section
  const handleSelectAll = (sectionIndex) => {
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[sectionIndex].options = updatedFilters[
        sectionIndex
      ].options.map((option) => ({
        ...option,
        isSelected: true,
      }));
      return updatedFilters;
    });
  };

  // Function to unselect all options within a section
  const handleUnselectAll = (sectionIndex) => {
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[sectionIndex].options = updatedFilters[
        sectionIndex
      ].options.map((option) => ({
        ...option,
        isSelected: false,
      }));
      return updatedFilters;
    });
  };

  return (
    <div>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters.map((section, index) => {
                    if (section.id === 'dateRange') return null;
                    return (
                      <Disclosure
                        as="div"
                        key={index}
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                <span className="font-medium text-gray-900">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex items-center">
                                  <ChevronDownIcon
                                    className={classNames(
                                      open ? '-rotate-180' : 'rotate-0',
                                      'h-5 w-5 transform'
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={optionIdx}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.name}
                                      type="checkbox"
                                      checked={option.isSelected}
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 text-sm text-gray-500"
                                    >
                                      {option.firstname && option.lastname ? (
                                        <>
                                          {option.firstname} {option.lastname}
                                        </>
                                      ) : (
                                        option.name
                                      )}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    );
                  })}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="mx-auto max-w-3xl mt-2 lg:max-w-7xl">
        <section aria-labelledby="filter-heading">
          <h2 id="filter-heading" className="sr-only">
            Filters
          </h2>

          <div className="flex items-center justify-between">
            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 lg:hidden"
              onClick={() => setOpen(true)}
            >
              Open filters menu
            </button>

            <div className="hidden lg:flex lg:items-center lg:justify-between lg:w-full">
              {!hideCalendar ? (
                <div className="flex-shrink-0">
                  <CalendarPicker
                    defaultStartValue={initialFromDate}
                    defaultEndValue={initialToDate}
                    onChange={(from, to) => {
                      if (onCalendarDateChange) onCalendarDateChange(from, to);
                    }}
                  />
                </div>
              ) : null}
              <Popover.Group className="flex items-center space-x-8">
                {filters.map((section, sectionIdx) => {
                  if (section.id === 'dateRange') return null;
                  return (
                    <Popover
                      as="div"
                      key={sectionIdx}
                      id={`desktop-menu-${sectionIdx}`}
                      className="relative inline-block text-left"
                    >
                      <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                          <span>{section.name}</span>
                          <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                            {
                              section.options.filter(
                                (option) => option.isSelected
                              ).length
                            }
                          </span>
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80 overflow-y-auto">
                          <form className="space-y-4">
                            <div>
                              {/* Add "Everyone" button */}
                              <button
                                type="button"
                                className="text-sm font-medium text-indigo-600 hover:text-indigo-900"
                                onClick={(e) => handleSelectAll(sectionIdx)}
                              >
                                Everyone
                              </button>

                              {/* Add "/" separator */}
                              <span className="mx-2">/</span>

                              {/* Add "Unselect" button */}
                              <button
                                type="button"
                                className="text-sm font-medium text-red-600 hover:text-red-900"
                                onClick={() => handleUnselectAll(sectionIdx)}
                              >
                                Unselect
                              </button>
                            </div>
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={optionIdx}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.name}
                                  checked={option.isSelected}
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  onChange={() =>
                                    handleCheckboxChange(sectionIdx, optionIdx)
                                  }
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                >
                                  {option.firstname && option.lastname ? (
                                    <>
                                      {option.firstname} {option.lastname}
                                    </>
                                  ) : (
                                    option.name
                                  )}
                                </label>
                              </div>
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  );
                })}
              </Popover.Group>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
