import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { FormTitle } from '../atoms/FormTitle';
import { FormSubTitle } from '../atoms/FormSubTitle';
import { Container } from '../atoms/Container';
import { Header } from '../molecules/Header';
import { CreateTeamForm } from '../organisms/CreateTeamForm';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { NotificationContext } from '../../contexts/NotificationContext';

export default function CreateTeams() {
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { isFirstTeam = true } = location.state || {};

  const fetchPostTeamsPromise = async (name) => {
    await dynamicApiCall({
      callName: 'postTeams',
      navigate,
      params: { name, updateOpenNotification, updateNotificationData },
    });
  };

  return (
    <>
      {isFirstTeam ? (
        <Header>
          Already have an account ?{' '}
          <Link
            to="/login"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Log in
          </Link>
        </Header>
      ) : (
        <Header>
          <Link
            to="/portfolio"
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            Return to dashboard
          </Link>
        </Header>
      )}
      <div className="flex h-5/6 items-center">
        <Container>
          <div className="text-center">
            <FormTitle text="Pick a name for your team" />
            <FormSubTitle text="This will become your account name" />
          </div>

          <div className="mt-5" />

          <CreateTeamForm postTeamsPromise={fetchPostTeamsPromise} />
        </Container>
      </div>
    </>
  );
}
