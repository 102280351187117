import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { SubSideBarLayout } from '../organisms/SubSideBarLayout';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SubTitle } from '../atoms/SubTitle';
import { ActionPanel } from '../molecules/ActionPanel';
import { Comboboxes } from '../atoms/Comboboxes';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { useTeam } from '../../contexts/TeamContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { ActionPanelWithCheckboxes } from '../molecules/ActionPanelWithCheckboxes';
import { ActionPanelWithInputTradeBridge } from '../molecules/ActionPanelWithInputTradeBridge';

export const SettingsHoursAndCurrencyScreen = () => {
  const navigate = useNavigate();
  /* Access context values using the useTeam hook */
  const { teamUUID } = useTeam();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);
  const [currentTeam, setCurrentTeam] = useState({});
  const [timezones, setTimezones] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [checkboxesOptions, setCheckboxesOptions] = useState([]);

  const createObjectOfTeamHoursPreferences = () => {
    const selectedDays = checkboxesOptions.reduce((result, option) => {
      result[option.name.toLowerCase()] = option.hoursPreferences;
      return result;
    }, {});

    return selectedDays;
  };

  const handleCheckboxChange = (id, checked) => {
    // Update the isSelected property in the state based on checkbox change
    setCheckboxesOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === id
          ? {
              ...option,
              isSelected: checked,
              hoursPreferences: checked ? option.hoursPreferences : 0,
            }
          : option
      )
    );
  };

  const handleCheckboxChangeHoursChanges = (e) => {
    let { name, value } = e.target;
    value = parseInt(value);

    /* Update the specific property by name */
    const updatedOptions = checkboxesOptions.map((option) => {
      if (option.name.toLowerCase() === name) {
        return {
          ...option,
          hoursPreferences: value,
        };
      }

      return option;
    });
    setCheckboxesOptions(updatedOptions);
  };

  const fetchTimezonePromise = async () => {
    await dynamicApiCall({
      callName: 'getTimezone',
      navigate,
      params: {
        setTimezones,
      },
    });
  };

  const fetchCountryPromise = async () => {
    await dynamicApiCall({
      callName: 'getCountry',
      navigate,
      params: {
        setCountries,
      },
    });
  };

  const fetchCurrencyPromise = async () => {
    await dynamicApiCall({
      callName: 'getCurrency',
      navigate,
      params: {
        setCurrencies,
      },
    });
  };

  const fetchTeamsByUUIDPromise = async () => {
    await dynamicApiCall({
      callName: 'getTeamsByUUID',
      navigate,
      params: {
        teamUUID,
        setCurrentTeam,
        setCheckboxesOptions,
        checkboxesOptions,
      },
    });
  };

  const fetchPutTeamsByUUIDPromise = async (data, name) => {
    const params = {
      [name]:
        data /* Using computed property name to set the property dynamically */,
      uuid: teamUUID,
      fetchTeamsByUUIDPromise,
      fetchTimezonePromise,
      fetchCountryPromise,
      fetchCurrencyPromise,
      updateOpenNotification,
      updateNotificationData,
    };

    await dynamicApiCall({
      callName: 'putTeamsByUUID',
      navigate,
      params,
    });
  };

  const fetchAccessPagePromise = async (teamUUID, page, optionalText) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        optionalText,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  useEffect(() => {
    if (teamUUID) {
      fetchAccessPagePromise(
        teamUUID,
        'TeamsSettingsHoursAndCurrency',
        'settings'
      );
      fetchTeamsByUUIDPromise();
      fetchTimezonePromise();
      fetchCountryPromise();
      fetchCurrencyPromise();
    }
  }, [teamUUID]);

  // Calculate max hours worked from checkboxesOptions
  const maxHours = checkboxesOptions.reduce(
    (max, option) => Math.max(max, option.hoursPreferences),
    0
  );

  return (
    <SideBarLayout>
      <SubSideBarLayout>
        <main className="ml-4 lg:pl-72 xl:ml-0 xl:pl-96">
          <SingleScreenTitle text="Hours & currency" />
          <SubTitle text="Set team hours, public holiday and currency." />
          <div className="mt-4" />

          <ActionPanel
            title="Country"
            description="This will define public holiday for your team."
          >
            <Comboboxes
              data={countries}
              dataType="name"
              initialValue={{ uuid: currentTeam?.countryUUID }}
              onClick={(e) => fetchPutTeamsByUUIDPromise(e.uuid, 'countryUUID')}
            />
          </ActionPanel>
          <div className="mt-8" />
          <ActionPanel title="Time zone">
            <Comboboxes
              data={timezones}
              dataType="currency"
              initialValue={{ uuid: currentTeam?.timezoneUUID }}
              onClick={(e) =>
                fetchPutTeamsByUUIDPromise(e.uuid, 'timezoneUUID')
              }
            />
          </ActionPanel>
          <div className="mt-8" />
          <ActionPanel title="Currency">
            <Comboboxes
              data={currencies}
              dataType="name"
              initialValue={{ uuid: currentTeam?.currencyUUID }}
              onClick={(e) =>
                fetchPutTeamsByUUIDPromise(e.uuid, 'currencyUUID')
              }
            />
          </ActionPanel>
          <div className="mt-8" />
          <ActionPanelWithInputTradeBridge
            title="Payment rate"
            description={`Defines default payment rate for team member. Current max hours worked per day: ${maxHours}`}
            buttonLabel="Save"
            inputType="number"
            inputPrefix={currentTeam.currencySymbol}
            inputSuffix="/ day"
            inputPrefixTraded={currentTeam.currencySymbol}
            inputSuffixTraded="/ hour"
            onClick={(e) => {
              fetchPutTeamsByUUIDPromise(e.secondValue, 'hourlyRate');
            }}
            value={currentTeam?.defaultDailyRate}
            maxHours={maxHours}
          />
          <div className="mt-8" />
          <ActionPanelWithCheckboxes
            title="Hours preferences"
            description="Update team default hours preferences."
            buttonLabel="Save"
            onClick={() => {
              let hoursPreferences = createObjectOfTeamHoursPreferences();
              hoursPreferences = JSON.stringify(hoursPreferences);

              fetchPutTeamsByUUIDPromise(
                hoursPreferences,
                'defaultHoursPreferences'
              );
            }}
            checkboxesOptions={checkboxesOptions}
            handleCheckboxChange={handleCheckboxChange}
            handleCheckboxChangeHoursChanges={handleCheckboxChangeHoursChanges}
          />
          <div className="h-12" />
        </main>
      </SubSideBarLayout>
    </SideBarLayout>
  );
};
