export const PrimaryButton = ({
  label,
  type = 'button',
  className = `bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600`,
  onClick,
}) => (
  <button
    type={type === 'submit' ? 'submit' : 'button'}
    className={`${className} rounded-md  px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
    onClick={onClick}
  >
    {label}
  </button>
);
