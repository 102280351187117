import { formatDate } from '../../../utils/momentUtils';

export const getTeamMembersByTeamUUIDLogic = async (datas) => {
  const {
    setPeople,
    rawData = false,
    callData,
    returnData,
    setProjectMembers,
  } = datas;

  if (returnData) {
    return callData;
  }

  if (rawData === true) {
    setPeople(callData);
  } else if (callData && callData.length > 0) {
    const teamMembersFormattedData = [];

    callData.forEach((element) => {
      const newPerson = {
        uuid: element.uuid,
        privilegeUUID: element.privilegeUUID,
        teamUUID: element.teamUUID,
        userUUID: element.userUUID,
        firstName: element.userFirstName || '',
        lastName: element.userLastName || '',
        mail: element.userMail,
        access: element.privilegeName,
        contract: element.contractName,
        contractUUID: element.contractUUID,
        startedAt: element.startedAt ? formatDate(element.startedAt) : null,
        verifiedAt: element.verifiedAt ? formatDate(element.verifiedAt) : null,
        endedAt: element.endedAt ? formatDate(element.endedAt) : null,
        hoursPreferences: element.hoursPreferences,
        projectsUUID: element.projectsUUID,
        hourlyRate: element.hourlyRate,
        dailyRate: element.dailyRate,
        languageUUID: element.userLanguageUUID,
        teamDefaultHoursPreferences: JSON.parse(
          element.teamDefaultHoursPreferences
        ),
        teamDefaultHourlyRate: element.teamDefaultHourlyRate,
      };

      teamMembersFormattedData.push(newPerson);
    });

    setPeople(teamMembersFormattedData);
    if (setProjectMembers) {
      setProjectMembers(teamMembersFormattedData);
    }
  }
};
