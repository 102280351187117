import { calculateAllocationAmount } from './calculateAllocationAmount';

export const calculateTotalAllocationsAmount = (project, budget, teamData) =>
  project.allocations
    .reduce((total, allocation) => {
      const amount = calculateAllocationAmount(
        allocation,
        project,
        budget,
        teamData
      );
      return total + (Number.isNaN(amount) ? 0 : amount);
    }, 0)
    .toFixed(2); // Ensure the total is rounded to 2 decimal places
