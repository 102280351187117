import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { formatDate } from '../../services/utils/momentUtils';

export const Table = ({
  tableHeads,
  tableData,
  tableLabel = null,
  tableDescription = null,
  onClick = null,
  trClassName = null,
}) => {
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);

  const handleSort = (column) => {
    if (sortColumn === column) {
      /* If clicking on the same column, toggle the sort order */
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      /* If clicking on a new column, set the column and default to ascending order */
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const compareStrings = (a, b, sortOrder) =>
    sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a);

  const compareNumbers = (a, b, sortOrder) =>
    sortOrder === 'asc' ? a - b : b - a;

  const compareValues = (valueA, valueB, sortOrder) => {
    if (valueA === null && valueB === null) {
      return 0;
    }
    if (valueA === null) {
      return sortOrder === 'asc' ? 1 : -1;
    }
    if (valueB === null) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return compareStrings(valueA, valueB, sortOrder);
    }
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return compareNumbers(valueA, valueB, sortOrder);
    }
    /* Add more cases for other data types as needed */
    return 0; // Default to no sorting
  };

  const sortedTableData = [...tableData].sort((a, b) => {
    if (sortColumn) {
      let valueA = a[sortColumn];
      let valueB = b[sortColumn];

      /* Handle specific cases where the sortColumn is different from the property name */
      if (sortColumn === 'dates') {
        valueA = a.date;
        valueB = b.date;
      }
      if (sortColumn === 'durations') {
        valueA = a.duration;
        valueB = b.duration;
      }
      if (sortColumn === 'projects') {
        valueA = a.project.name;
        valueB = b.project.name;
      }
      if (sortColumn === 'notes') {
        valueA = a.note;
        valueB = b.note;
      }
      if (sortColumn === 'member') {
        valueA = a[sortColumn].name;
        valueB = b[sortColumn].name;
      }
      if (sortColumn === 'tags') {
        valueA = a[sortColumn][0]?.name;
        valueB = b[sortColumn][0]?.name;
      }

      return compareValues(valueA, valueB, sortOrder);
    }

    return 0; /* Default to no sorting */
  });

  return (
    <div>
      <div className="flow-root">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {tableLabel}
          </h1>
          {tableDescription ? (
            <p className="mt-2 text-sm text-gray-700">{tableDescription}</p>
          ) : null}
        </div>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {Object.keys(tableHeads).map((tableHead, index) => {
                    const isActiveColumn = sortColumn === tableHead;

                    if (tableHead === `actionLabel`) {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="relative py-3.5 pl-3 pr-0"
                        >
                          <span className="sr-only">
                            {tableHeads[tableHead]}
                          </span>
                        </th>
                      );
                    }
                    return (
                      <th
                        key={index}
                        scope="col"
                        className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 ${
                          isActiveColumn ? 'active' : ''
                        }`}
                        onClick={() => handleSort(tableHead)}
                      >
                        <a href="#" className="group inline-flex">
                          {tableHeads[tableHead]}
                          <span
                            className={`ml-2 flex-none rounded text-gray-400 ${
                              isActiveColumn
                                ? sortOrder === 'asc'
                                  ? 'transform rotate-180'
                                  : ''
                                : ''
                            }`}
                          >
                            <ChevronDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        </a>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {sortedTableData.map((data, index) =>
                  data.isVisible !== false ? (
                    <tr
                      onClick={onClick ? () => onClick(data) : null}
                      key={index}
                      className={trClassName}
                    >
                      {Object.keys(data).map((key, indexKey) => {
                        switch (key) {
                          case 'uuid':
                            return null;
                          case 'hoursMaxForDay':
                            return null;
                          case 'projectUUID':
                            return null;
                          case 'projectName':
                            return null;
                          case 'member':
                            return (
                              <td
                                key={indexKey}
                                data-uuid={data[key].uuid}
                                className="whitespace-nowrap py-4 pl-0 pr-3 text-sm text-gray-500"
                              >
                                {data[key].name === `null null`
                                  ? data[key].userMail
                                  : data[key].name}
                              </td>
                            );
                          case 'tags':
                            return (
                              <td key={indexKey}>
                                <Fragment key={indexKey}>
                                  {data[key][0]?.color ? (
                                    <a
                                      href={data[key][0]?.href}
                                      className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                    >
                                      {data[key][0]?.color && (
                                        <span
                                          className={`h-3 w-3 rounded-full ${data[key][0]?.color} inline-block`}
                                        />
                                      )}
                                      {data[key][0]?.name}
                                    </a>
                                  ) : (
                                    <p className="whitespace-nowrap py-4 pl-0 pr-3 text-sm text-gray-500">
                                      No tag
                                    </p>
                                  )}
                                </Fragment>
                              </td>
                            );
                          case 'colors':
                            return (
                              <td
                                key={indexKey}
                                data-uuid={data[key].uuid}
                                className="whitespace-nowrap py-4 pl-0 pr-3 text-sm text-gray-500"
                              >
                                <div
                                  className={`h-4 w-12 rounded-md ${data[key]}`}
                                />
                              </td>
                            );
                          case 'actionCalls':
                            return (
                              <td
                                key={indexKey}
                                className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0"
                              >
                                {data[key].map(
                                  (actionCall, actionCallIndex) => (
                                    <button
                                      key={actionCallIndex}
                                      type="button"
                                      onClick={() => actionCall.onClick(data)}
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      {actionCall.name}
                                    </button>
                                  )
                                )}
                              </td>
                            );
                          case 'project':
                            return (
                              <td
                                key={indexKey}
                                className="whitespace-nowrap py-4 pl-0 pr-3 text-sm text-gray-500 truncate max-w-[200px]"
                              >
                                {data[key]?.color && (
                                  <span
                                    className={`h-3 w-3 rounded-full ${data[key]?.color} inline-block mr-2`}
                                  />
                                )}
                                {data[key].name}
                              </td>
                            );
                          case 'date':
                            return (
                              <td
                                key={indexKey}
                                className="whitespace-nowrap py-4 pl-0 pr-3 text-sm text-gray-500"
                              >
                                {formatDate(data[key], 'DD/MM/YYYY')}
                              </td>
                            );
                          case 'clocked':
                            return (
                              <td
                                key={indexKey}
                                className="whitespace-nowrap py-4 pl-0 pr-3 text-sm text-gray-500"
                              >
                                {parseFloat(data[key]).toFixed(1)}
                              </td>
                            );
                          default:
                            return (
                              <td
                                key={indexKey}
                                className="whitespace-nowrap py-4 pl-0 pr-3 text-sm text-gray-500 truncate max-w-[200px]"
                              >
                                <span>{data[key]}</span>
                              </td>
                            );
                        }
                      })}
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
