import React, { useState, useEffect } from 'react';
import {
  QuestionMarkCircleIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';

const Tooltip = ({ information, visible, position }) => {
  if (!visible) return null;

  return createPortal(
    <div
      className="absolute z-50 px-2 py-1 mt-0.5 bg-black text-white text-xs rounded shadow-lg max-w-xs whitespace-normal"
      style={{ top: position.top, left: position.left }}
    >
      {information}
    </div>,
    document.body
  );
};

const TableHeaderCell = ({
  label,
  information,
  filteredBy,
  onFilterClick,
  sortDirection,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setTooltipVisible(true);
  };

  return (
    <th
      scope="col"
      className="px-2 sticky top-0 z-10 border-b border-gray-300 bg-opacity-75 py-3.5 pr-3 text-left text-sm font-semibold text-gray-600 backdrop-blur backdrop-filter"
    >
      <button
        type="button"
        className={`group inline-flex items-center ${
          filteredBy ? 'cursor-pointer' : 'cursor-auto'
        }`}
        onClick={filteredBy ? onFilterClick : null}
      >
        {label}
        {filteredBy && (
          <div className="ml-2">
            {sortDirection === 'asc' ? (
              <ChevronUpIcon className="h-4 w-4 text-gray-400" />
            ) : (
              <ChevronDownIcon className="h-4 w-4 text-gray-400" />
            )}
          </div>
        )}
        {information && (
          <div className="relative flex items-center ml-2">
            <QuestionMarkCircleIcon
              className="h-5 w-5 text-gray-400 hover:text-gray-500"
              aria-hidden="true"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={() => setTooltipVisible(false)}
            />
            <Tooltip
              information={information}
              visible={tooltipVisible}
              position={tooltipPosition}
            />
          </div>
        )}
      </button>
    </th>
  );
};

export const TableWithStickyHeader = ({
  tableHeads,
  tableData,
  children,
  isAToggleTable = false, // Added default prop
  title = '',
}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [sortedData, setSortedData] = useState([...tableData]);

  const handleSortClick = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const keyToFilterFrom = tableHeads[key].keyToFilterFrom || key;

    // Helper function to get the value from an object based on the keyToFilterFrom
    const getSortValue = (obj) => {
      if (typeof keyToFilterFrom === 'function') {
        return keyToFilterFrom(obj); // Call the function to get the sorting value
      }

      // Handle nested keys (e.g., 'user.firstname')
      return keyToFilterFrom
        .split('.')
        .reduce(
          (value, keyPart) =>
            value && value[keyPart] !== undefined ? value[keyPart] : null,
          obj
        );
    };

    if (tableHeads[key].filteredBy) {
      const sorted = [...tableData].sort((a, b) => {
        const valueA = getSortValue(a);
        const valueB = getSortValue(b);

        return tableHeads[key].filteredBy(valueA, valueB, direction);
      });
      setSortedData(sorted);
    } else {
      setSortedData([...tableData]);
    }
  };

  useEffect(() => {
    setSortedData([...tableData]);
  }, [tableData]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="rounded-lg inline-block min-w-full py-2 align-middle">
            {isAToggleTable && (
              <div className="py-2">
                <h3 className="text-sm font-medium text-gray-600">{title}</h3>
              </div>
            )}
            <table className="bg-white border min-w-full border-separate border-spacing-0 rounded-lg">
              <thead className="bg-slate-100 border-t border-b border-gray-300">
                <tr>
                  {Object.entries(tableHeads).map(([key, value], index) => {
                    const label =
                      typeof value === 'string' ? value : value.label;

                    if (key === 'actionLabel') {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-2 sticky top-0 z-10 border-b border-gray-300 bg-opacity-75 py-3.5 pr-3 text-sm font-semibold text-gray-600 backdrop-blur backdrop-filter"
                        >
                          <span className="sr-only">{label}</span>
                        </th>
                      );
                    }

                    return (
                      <TableHeaderCell
                        key={index}
                        label={label}
                        information={value.information}
                        filteredBy={value.filteredBy}
                        onFilterClick={() => handleSortClick(key)}
                        sortDirection={
                          sortConfig.key === key ? sortConfig.direction : null
                        }
                      />
                    );
                  })}
                </tr>
              </thead>

              <tbody>{children(sortedData)}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
