import React from 'react';
import { Checkboxes } from './Checkboxes';

export const ActionPanelWithCheckboxes = ({
  title,
  description,
  buttonLabel,
  onClick,
  checkboxesOptions,
  handleCheckboxChange,
  handleCheckboxChangeHoursChanges,
}) => (
  <div className="bg-white shadow sm:rounded-lg">
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        {title}
      </h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>{description}</p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault(); /* Prevents the default form submission behavior */
          onClick(e);
        }}
        className="mt-5 flex"
      >
        <div className="flex-grow">
          <Checkboxes
            options={checkboxesOptions}
            onChange={handleCheckboxChange}
            onHoursChanges={handleCheckboxChangeHoursChanges}
          />
        </div>
        <div className="flex items-center ml-4">
          <button
            type="submit"
            className="inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {buttonLabel}
          </button>
        </div>
      </form>
    </div>
  </div>
);
