export const TableCellText = ({ data, label, index, customClassName }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 pr-3 text-xs text-gray-600 px-2',
        customClassName
      )}
    >
      <span>{label}</span>
    </td>
  );
};
