import { useEffect, useRef, useState } from 'react';
import tailwindColors from '../../services/utils/tailwindColors';
import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

export const SlideOverInputColorPicker = ({ name, isRequired }) => {
  const { register, errors, setValue, watch } = useSlideOverFormContext();

  const currentColor = watch(name) || '';
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const handleColorChange = (e, color, index) => {
    setIsDropdownOpen(false);
    setSelectedIndex(index);
    setValue(name, color, { shouldDirty: true });
  };

  // Function to close the dropdown when clicking outside of it
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    // Focus the dropdown when it opens
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  };

  const handleDropdownBlur = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    // Add the event listener when the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      // Remove the event listener when the dropdown closes
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  return (
    <div className="sm:col-span-2">
      <div className="mb-4 relative">
        <div
          onClick={toggleDropdown}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleDropdown();
            }
          }}
          tabIndex={0}
          role="button"
          aria-label="Toggle Color Picker"
          className={`mt-2 flex rounded-md shadow-sm ${currentColor}`}
        >
          <span className="w-12 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm" />
          <input
            type="text"
            name={name}
            id={name}
            {...(register &&
              register(name, {
                required: isRequired ? `This input is required.` : false,
              }))}
            className="cursor-pointer block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-slate-500 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Select a color"
            value={currentColor?.replace('bg-', '')}
            readOnly
          />
        </div>

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            onBlur={handleDropdownBlur}
            tabIndex={0}
            role="listbox"
            className="z-10 bg-white drop-shadow-md w-96 mt-2 p-1 border border-gray-300 rounded-lg shadow-lg absolute left-0 max-h-48 overflow-y-auto flex flex-wrap"
          >
            {tailwindColors.map((color, index) => (
              <button
                key={index}
                type="button"
                name="color"
                onClick={(e) => handleColorChange(e, color, index)}
                tabIndex={0}
                role="option"
                aria-selected={index === selectedIndex}
                aria-label={`Select ${color}`}
                className={`w-6 h-6 rounded-md border border-gray-300 cursor-pointer m-1 ${color}`}
              />
            ))}
          </div>
        )}

        <InputErrorMessage errors={errors} name={name} />
      </div>
    </div>
  );
};
