import { Fragment } from 'react';

import { Menu, Transition } from '@headlessui/react';
import {
  ArrowDownTrayIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SingleScreenMenu = ({
  handleCSVExport,
  updateModalSource,
  onClick,
  updateOpenModal,
  modalState,
  shouldDisableDeleteButton,
  shouldHideButton,
}) => (
  <div className="mt-5 flex lg:ml-4 lg:mt-0">
    {shouldHideButton ? null : (
      <span className="hidden sm:block">
        <button
          onClick={onClick}
          type="button"
          className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          <PencilIcon
            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Edit
        </button>
      </span>
    )}

    <span className="ml-3 hidden sm:block">
      <button
        onClick={() => {
          if (handleCSVExport) {
            handleCSVExport();
          }
        }}
        type="button"
        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <ArrowDownTrayIcon
          className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        Export data
      </button>
    </span>

    {shouldHideButton ? null : (
      <span className="sm:ml-3">
        <button
          onClick={() => {
            updateModalSource('delete');
            updateOpenModal(!modalState);
          }}
          type="button"
          className={classNames(
            shouldDisableDeleteButton
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-red-600 hover:bg-red-500',
            'inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
          )}
          disabled={shouldDisableDeleteButton}
        >
          <TrashIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Delete
        </button>
      </span>
    )}

    {/* Dropdown */}
    <Menu as="div" className="relative ml-3 sm:hidden">
      <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
        More
        <ChevronDownIcon
          className="-mr-1 ml-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={onClick}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                Edit
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={() => {
                  handleCSVExport();
                }}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700'
                )}
              >
                Export data
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);
