export const deleteTeamMembersByUUIDLogic = async (datas) => {
  const {
    callData,
    fetchMembersScreenData,
    setSelectedPeople,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    fetchMembersScreenData(callData);
    setSelectedPeople([]);
    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({ ...callData });
    }
  }
};
