import React from 'react';
import { useForm } from 'react-hook-form';
import { RequiredAsterisk } from '../atoms/RequiredAsterisk';

export const DeleteModalInput = ({
  deleteFunction,
  onClickCancel,
  labelToCopy = 'Understood',
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });

  const onSubmit = async () => {
    deleteFunction();
  };

  return (
    <>
      <p>Delete</p>
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          Data will be removed from our servers forever. This cannot be undone.
        </p>

        <div className="mt-4" />

        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="items-center justify-between">
            <label
              htmlFor="confirmDelete"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Copy "{labelToCopy}" to confirm
              <RequiredAsterisk />
            </label>
            <div
              className={`mt-2 ${errors.confirmation ? 'border-red-500' : ''}`}
            >
              <input
                type="text"
                name="confirmDelete"
                id="confirmDelete"
                {...register('confirmation', {
                  required: 'Confirmation is required',
                  validate: (value) =>
                    value === labelToCopy ||
                    'Confirmation should match the exact name',
                })}
                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                  errors.confirmation ? 'ring-red-500' : ''
                }`}
                placeholder={labelToCopy}
              />

              {errors.confirmation && (
                <span className="text-red-500 text-xs">
                  {errors.confirmation.message}
                </span>
              )}
            </div>

            <div className="mt-8 sm:mt-8 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                disabled={!isValid}
                className={`flex justify-center rounded-md ${
                  isValid
                    ? 'bg-red-600 hover:bg-red-500'
                    : 'bg-gray-300 cursor-not-allowed'
                } px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                Confirm delete
              </button>
              <button
                type="button"
                className="mt-3 mr-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={onClickCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
