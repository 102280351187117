export const DividerWithTitleOnLeft = ({ title, color = `bg-slate-50` }) => (
  <div className="relative">
    <div className="relative flex justify-start">
      <span
        className={`${color} pr-3 text-base font-semibold leading-6 text-gray-900`}
      >
        {title}
      </span>
    </div>
  </div>
);
