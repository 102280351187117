import { CheckIcon } from '@heroicons/react/24/solid';

export const Steps = ({ steps }) => (
  <nav aria-label="Progress">
    <ol role="list" className="rounded-md md:flex md:divide-y-0">
      {steps.map((step, stepIdx) => (
        <li key={step.name} className="relative md:flex">
          {step.state === 'complete' ? (
            <p className="group flex w-full items-center">
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600">
                  <CheckIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <span className="ml-4 text-sm font-medium text-gray-900">
                  {step.name}
                </span>
              </span>
            </p>
          ) : step.state === 'current' ? (
            <p
              className="flex items-center px-6 py-4 text-sm font-medium"
              aria-current="step"
            >
              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                <span className="text-indigo-600">{step.id}</span>
              </span>
              <span className="ml-4 text-sm font-medium text-indigo-600">
                {step.name}
              </span>
            </p>
          ) : (
            <p className="group flex items-center">
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                  <span className="text-gray-500">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-gray-500">
                  {step.name}
                </span>
              </span>
            </p>
          )}

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div
                className="flex self-center h-1/4 w-2 md:block"
                aria-hidden="true"
              >
                <svg
                  className="h-full w-full text-gray-400"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </>
          ) : null}
        </li>
      ))}
    </ol>
  </nav>
);
