export const postNewConfirmationMailLogic = async (datas) => {
  const { callData, updateOpenNotification, updateNotificationData } = datas;

  if (callData) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
  }
};
