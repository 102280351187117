import { calculateTotalAllocationsAmount } from './calculateTotalAllocationsAmount';

export const calculateTotalAllocationsAmountForClients = (
  projects,
  budget,
  teamData
) => {
  const clientAllocations = {};

  projects.forEach((project) => {
    const clientId = project.client.uuid;
    const amount = parseFloat(
      calculateTotalAllocationsAmount(project, budget, teamData)
    );

    if (clientAllocations[clientId]) {
      clientAllocations[clientId].amount += amount;
    } else {
      clientAllocations[clientId] = {
        label: project.client.name,
        amount,
      };
    }
  });

  return Object.values(clientAllocations);
};
