export const putTeamProjectsByUUIDLogic = async (datas) => {
  const {
    projectName,
    clientName,
    navigate,
    updateOpenSlideOver,
    reset,
    updateOpenNotification,
    updateNotificationData,
    fetchTeamProjectsPromise,
    callData,
    resetValues,
  } = datas;

  if (fetchTeamProjectsPromise) {
    await fetchTeamProjectsPromise({ projectName });
  }

  updateOpenSlideOver(false);
  if (updateOpenNotification && updateNotificationData) {
    updateOpenNotification(true);
    updateNotificationData({ ...callData });
  }

  const newPathname = `/portfolio/${encodeURIComponent(
    clientName
  )}/${encodeURIComponent(callData.payload)}`;
  navigate(newPathname);

  reset({
    ...resetValues,
    projectMembers: resetValues.projectMembers.reduce((acc, uuid) => {
      acc[uuid] = true;
      return acc;
    }, {}),
    projectTags: resetValues.projectTags.reduce((acc, tagUUID) => {
      acc[tagUUID] = true;
      return acc;
    }, {}),
  });
};
