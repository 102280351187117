import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';

export const TableCellClickableAvatarGroup = ({
  data,
  componentData,
  index,
  customClassName,
  onClick,
  maxDisplayedChildren = 3,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const displayedChildren = componentData.slice(0, maxDisplayedChildren);
  const remainingChildrenCount = componentData.length - maxDisplayedChildren;

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 pr-3 text-xs text-gray-500',
        customClassName
      )}
    >
      <div className="flex items-center space-x-1">
        {displayedChildren.map((child, idx) => (
          <div key={idx} className="tooltip">
            <AvatarWithPlaceholder
              size="xs"
              placeholder={child.name.charAt(0)}
              onClick={(e) => {
                e.stopPropagation();
                if (onClick) {
                  onClick(e, child); // Passing event and child object to onClick handler
                }
              }}
              className={
                child.color
                  ? `${child.color} ml-0 mr-0.5 hover:bg-opacity-75`
                  : `bg-gray-400 ml-0 mr-0.5 hover:bg-gray-500`
              }
            />
            <span className="tooltip-text">{child.name}</span>
          </div>
        ))}
        {remainingChildrenCount > 0 && (
          <p>
            +{remainingChildrenCount} other
            {remainingChildrenCount > 1 ? 's' : ''}
          </p>
        )}
      </div>
    </td>
  );
};
