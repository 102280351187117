import React, { useEffect, useState } from 'react';
import MonthView from './MonthView';
import CalendarHeader from './CalendarHeader';
import WeekView from './WeekView';

export default function Calendar(props) {
  const {
    days,
    resetDates,
    monthSelected,
    yearSelected,
    handleNextMonth,
    handlePreviousMonth,
    handleViewChange,
    selectedView,
    handlePreviousWeek,
    handleNextWeek,
    handleClickOnDay,
    updateIsDraggingEvent,
    isDraggingEvent,
    onDragEnd,
    updateOpenModal,
    onEventClick,
    canAllocate,
    startWeekSelected,
    endWeekSelected,
    updateOpenSlideOver,
  } = props;

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startDayIndex, setStartDayIndex] = useState(null);
  const [endDayIndex, setEndDayIndex] = useState(null);

  /* Drag and drop events */
  const handleMouseDown = (dayIndex) => {
    setIsMouseDown(true);
    setStartDayIndex(dayIndex);
    setEndDayIndex(dayIndex);
  };

  const handleMouseOver = (dayIndex) => {
    if (isMouseDown) {
      setEndDayIndex(dayIndex);
    }
  };

  const handleFocus = (dayIndex) => {
    if (isMouseDown) {
      setEndDayIndex(dayIndex);
    }
  };

  /* Function to detect mouse Up even when user is not on the calendar */
  useEffect(() => {
    const handleMouseUp = () => {
      if (isMouseDown) {
        setIsMouseDown(false);
      }
    };

    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseDown, setIsMouseDown]);

  /* Handling selection */
  useEffect(() => {
    const handleSelection = () => {
      if (startDayIndex !== null && endDayIndex !== null) {
        const selectedDays = days.slice(
          Math.min(startDayIndex, endDayIndex),
          Math.max(startDayIndex, endDayIndex) + 1
        );

        /* Extracting dates */
        const allDates = selectedDays.map((dateObj) => new Date(dateObj.date));

        /* Finding the earliest and latest dates */
        const fromDate = new Date(Math.min(...allDates))
          .toISOString()
          .split('T')[0];
        const toDate = new Date(Math.max(...allDates))
          .toISOString()
          .split('T')[0];

        /* Checking if any of the selected days is a holiday */
        const isAnyHoliday = selectedDays.some(
          (day) => day.isHoliday && day.isHoliday.length > 0
        );

        if (isAnyHoliday) {
          updateOpenModal(true);
          /* Open modal to warn user first */
          handleClickOnDay({
            fromDate,
            toDate,
            isHoliday: true,
          });
        } else {
          /* Open modal to allocate */
          handleClickOnDay({ fromDate, toDate });
        }
      }
    };

    /* When user releases the mouse , handle selected dates */
    if (!isMouseDown) {
      handleSelection();
      setStartDayIndex(null);
      setEndDayIndex(null);
    }
  }, [isMouseDown, startDayIndex, endDayIndex, days]);

  return (
    <>
      <CalendarHeader
        handleNextMonth={handleNextMonth}
        handlePreviousMonth={handlePreviousMonth}
        resetDates={resetDates}
        yearSelected={yearSelected}
        monthSelected={monthSelected}
        handleViewChange={handleViewChange}
        selectedView={selectedView}
        handlePreviousWeek={handlePreviousWeek}
        handleNextWeek={handleNextWeek}
        startWeekSelected={startWeekSelected}
        endWeekSelected={endWeekSelected}
        canAllocate={canAllocate}
        updateOpenSlideOver={updateOpenSlideOver}
      />
      {selectedView === 'Month' && (
        <MonthView
          days={days}
          onDragEnd={onDragEnd}
          isDraggingEvent={isDraggingEvent}
          updateIsDraggingEvent={updateIsDraggingEvent}
          isMouseDown={isMouseDown}
          setIsMouseDown={setIsMouseDown}
          onEventClick={onEventClick}
          handleMouseDown={handleMouseDown}
          handleMouseOver={handleMouseOver}
          handleFocus={handleFocus}
          startDayIndex={startDayIndex}
          endDayIndex={endDayIndex}
          canAllocate={canAllocate}
        />
      )}
      {selectedView === 'Week' && (
        <WeekView
          weekDays={days}
          onDragEnd={onDragEnd}
          isDraggingEvent={isDraggingEvent}
          updateIsDraggingEvent={updateIsDraggingEvent}
          isMouseDown={isMouseDown}
          setIsMouseDown={setIsMouseDown}
          onEventClick={onEventClick}
          handleMouseDown={handleMouseDown}
          handleMouseOver={handleMouseOver}
          handleFocus={handleFocus}
          startDayIndex={startDayIndex}
          endDayIndex={endDayIndex}
        />
      )}
      {/* Additional views (WeekView, DayView, etc.) can be included here if needed */}
    </>
  );
}
