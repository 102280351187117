import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

export const SlideOverInputTextArea = ({ name, rows = 2, isRequired }) => {
  const { register, errors } = useSlideOverFormContext();

  return (
    <div className="sm:col-span-2">
      <textarea
        id={name}
        name={name}
        rows={rows}
        {...(register &&
          register(name, {
            required: isRequired ? `This input is required.` : false,
          }))}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />

      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};
