export const postTeamProjectsLogic = async (datas) => {
  const {
    updateOpenModal,
    fetchTeamClientsByTeamUUIDPromise,
    fetchTeamBudgetsByTeamUUIDPromise,
    teamUUID,
    updateOpenNotification,
    updateNotificationData,
    setProjectUUID,
    callData,
    updateOpenSlideOver,
    reset,
  } = datas;

  if (setProjectUUID) {
    setProjectUUID(callData.payload);
  }

  if (fetchTeamClientsByTeamUUIDPromise) {
    await fetchTeamClientsByTeamUUIDPromise(teamUUID);
    await fetchTeamBudgetsByTeamUUIDPromise(teamUUID);
  }

  if (updateOpenModal) {
    updateOpenModal(false);
  }

  if (updateOpenSlideOver) {
    updateOpenSlideOver(false);
    reset();
  }

  if (updateOpenNotification && updateNotificationData) {
    updateOpenNotification(true);
    updateNotificationData({ ...callData });
  }
};
