import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowRightIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Steps } from '../organisms/Steps';
import { Title } from '../atoms/Title';
import { SubTitle } from '../atoms/SubTitle';
import { InputWithValidation } from '../atoms/InputWithValidation';
import { LabelInput } from '../atoms/LabelInput';
import { IconButton } from '../atoms/IconButton';
import { SecondaryButton } from '../atoms/SecondaryButton';
import { Label } from '../atoms/Label';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { NotificationContext } from '../../contexts/NotificationContext';

export default function SetupTeam() {
  const navigate = useNavigate();
  const { teamUUID } = useParams();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [steps, setSteps] = useState([
    { id: '01', name: 'Add project', state: 'current' },
    { id: '02', name: 'Add people', state: 'upcoming' },
  ]);
  const [titleLabel, setTitleLabel] = useState('Add your first project');
  const [subTitleLabel, setSubTitleLabel] = useState(
    'Use this project to learn how everything works. You can add more details later.'
  );
  const [projectName, setProjectName] = useState();
  const [isButtonProcessing, setIsButtonProcessing] = useState(false);
  const [memberMails, setMembersMails] = useState({});
  const [memberMailInput, setMemberMailInput] = useState(3);
  const [memberMailInputErrors, setMemberMailInputErrors] = useState([]);
  const [projectUUID, setProjectUUID] = useState(null);

  const updateProjectName = (newProjectName) => {
    setProjectName(newProjectName);
  };

  const updateMembersMails = (index, value) => {
    setMembersMails((prevMemberMails) => {
      const updatedMemberMails = { ...prevMemberMails };

      if (!value) {
        delete updatedMemberMails[index];
      } else {
        updatedMemberMails[index] = value;
      }

      return updatedMemberMails;
    });
  };

  const fetchPostTeamProjects = async () => {
    await dynamicApiCall({
      callName: 'postTeamProjects',
      navigate,
      params: {
        teamUUID,
        name: projectName,
        setProjectUUID,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  const fetchPostInvitedUsersByMailAndTeamUUIDAndProjectUUID = async (
    mailList
  ) => {
    await dynamicApiCall({
      callName: 'postInvitedUsersByMailAndTeamUUIDAndProjectUUID',
      navigate,
      params: {
        mails: mailList,
        teamUUID,
        projectUUID,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  const fetchTeamsByOwnerUUID = async () => {
    await dynamicApiCall({
      callName: 'getTeamsByOwnerUUID',
      navigate,
      params: { teamUUID },
    });
  };

  const onClick = async (event) => {
    let updatedSteps;

    switch (event.target.id) {
      case 'projectName':
        setIsButtonProcessing(true);
        /* Register project name in database */
        await fetchPostTeamProjects();

        updatedSteps = steps.map((step) => {
          if (step.id === '01') {
            return { ...step, state: 'complete' };
          }

          if (step.id === '02') {
            return { ...step, state: 'current' };
          }
          return step;
        });

        setSteps(updatedSteps);
        setTitleLabel(`Add your team`);
        setSubTitleLabel(`Include anyone you’d like to schedule.`);
        setIsButtonProcessing(false);
        break;

      case 'addMember':
        setIsButtonProcessing(true);
        /* Reset input errors */
        setMemberMailInputErrors([]);
        let shouldBlock = false;
        /* Check if every input is correctly mail formatted */
        for (const [index, mail] of Object.entries(memberMails)) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(mail)) {
            shouldBlock = true;
            setMemberMailInputErrors((prevInputErrors) => [
              ...prevInputErrors,
              index,
            ]);
          }
        }

        if (memberMailInputErrors.length > 0 || shouldBlock) {
          setIsButtonProcessing(false);
          break;
        } else {
          /* Add members to the project */
          const mailList = Object.values(memberMails).join(',');
          await fetchPostInvitedUsersByMailAndTeamUUIDAndProjectUUID(mailList);
          navigate(`/allocations`, { replace: true });
        }

        setIsButtonProcessing(false);
        break;

      case 'addInput':
        setMemberMailInput(memberMailInput + 1);
        break;

      case 'skip':
        navigate(`/allocations`, { replace: true });
        break;

      default:
        break;
    }
  };

  // Function to handle key press events on the input field
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && projectName) {
      onClick({ target: { id: 'projectName' } }); // Simulate the target object
    }
  };

  useEffect(() => {
    fetchTeamsByOwnerUUID();
  }, []);

  return (
    <div className="px-8 py-4">
      <Steps steps={steps} />

      <div className="mt-10" />

      <div className="ml-5">
        <Title text={titleLabel} />
        <div className="mt-5" />
        <SubTitle text={subTitleLabel} />

        <div className="mt-12" />

        {steps[0].state === 'current' ? (
          <>
            <LabelInput
              label="Project"
              id="projectName"
              placeholder="Enter project name"
              updateData={updateProjectName}
              onKeyDown={handleKeyDown}
            />

            <div className="mt-8" />

            <IconButton
              id="projectName"
              label="Next"
              isDisabled={!projectName}
              isProcessing={isButtonProcessing}
              onClick={onClick}
            >
              <ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </IconButton>
          </>
        ) : null}

        {steps[1].state === 'current' ? (
          <>
            <Label text="Teammates" />

            {[...Array(memberMailInput)].map((_, index) => {
              if (memberMailInputErrors.includes(JSON.stringify(index))) {
                return (
                  <InputWithValidation
                    key={index}
                    index={index}
                    type="email"
                    updateData={updateMembersMails}
                    errorMessage="Enter a valid email address."
                  />
                );
              }
              return (
                <InputWithValidation
                  key={index}
                  index={index}
                  type="email"
                  updateData={updateMembersMails}
                />
              );
            })}

            <div className="mt-2" />

            <SecondaryButton
              id="addInput"
              label="Add another"
              onClick={onClick}
            >
              <PlusIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </SecondaryButton>

            <div className="mt-8" />

            <IconButton
              id="addMember"
              label="Next"
              isDisabled={JSON.stringify(memberMails) === `{}`}
              isProcessing={isButtonProcessing}
              onClick={onClick}
            >
              <ArrowRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </IconButton>

            <div className="inline ml-3" />

            {JSON.stringify(memberMails) === `{}` ? (
              <SecondaryButton id="skip" label="Later" onClick={onClick} />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
