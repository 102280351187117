export const putUserByUUIDLogic500 = async (datas) => {
  const {
    setUpdatingUserData,
    updatingUserData,
    updateOpenModal,
    callData,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });
    updateOpenModal(false);
    if (setUpdatingUserData) {
      setUpdatingUserData({
        ...updatingUserData,
        currentPassword: null,
        newPasswordConfirm: null,
        newPassword: null,
      });
    }
  }
};
