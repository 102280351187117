import { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../molecules/Header';

import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { Container } from '../atoms/Container';
import { NotificationContext } from '../../contexts/NotificationContext';

export default function ConfirmUserMailFromMailScreen() {
  const { authToken, confirmMailToken } = useParams();

  const navigate = useNavigate();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    /* Check if an authToken is present in localStorage */
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      dynamicApiCall({
        callName: 'getAuthTokenValidity',
        navigate,
        params: {
          authToken,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (authToken && confirmMailToken) {
      fetchPutUserConfirmMailPromise(authToken, confirmMailToken);
    }
  }, [authToken, confirmMailToken]);

  /* BEGINNING CALL API */

  /* Check if token are correct and update user verif */
  const fetchPutUserConfirmMailPromise = async (
    authToken,
    confirmMailToken
  ) => {
    await dynamicApiCall({
      callName: 'putUserConfirmMail',
      params: {
        authToken,
        setError,
        setErrorMessage,
        confirmMailToken,
        navigate,
        setLoading,
        updateOpenNotification,
        updateNotificationData,
        fetchPostNewConfirmationMailPromise,
      },
    });
  };

  const fetchPostNewConfirmationMailPromise = async (authToken) => {
    await dynamicApiCall({
      callName: 'postNewConfirmationMail',
      params: {
        authToken,
        navigate,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };
  /* END CALL API */

  return (
    <>
      <Header />
      <div className="flex h-5/6 items-center">
        <Container>
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-indigo-500 border-solid" />
            </div>
          ) : error ? (
            <div className="flex justify-center">
              <div>{errorMessage.title}</div>
              <div>{errorMessage.description}</div>
            </div>
          ) : (
            <h1>Mail verified with success</h1>
          )}
        </Container>
      </div>
    </>
  );
}
