export const postUserSignupLogic = async (datas) => {
  const {
    navigate,
    navigateTo,
    callData,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    localStorage.setItem('authToken', callData.payload);

    updateOpenNotification(true);
    updateNotificationData({
      ...callData,
    });

    // FIXME: Redirect to "/" and check if there is already a team created or assigned, if not, redirect to team creation
    navigateTo({ navigate, routeName: `/team/create` });
  }
};
