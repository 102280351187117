import React from 'react';

export const Checkboxes = ({ options, onChange, onHoursChanges }) => {
  // Define the correct order of days.
  const daysOrder = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  // Sort options based on the index of their name in the daysOrder array.
  const sortedOptions = options.sort(
    (a, b) => daysOrder.indexOf(a.name) - daysOrder.indexOf(b.name)
  );

  return (
    <fieldset>
      <div className="mt-4 divide-y divide-gray-200 border-t border-gray-200">
        {sortedOptions.map((option, optionIdx) => (
          <div
            key={optionIdx}
            className={`relative flex items-center py-4 space-x-4 ${
              optionIdx === sortedOptions.length - 1 ? '' : 'border-b'
            }`}
          >
            <div className="flex items-center">
              <input
                id={`option-${option.id}`}
                name={`option-${option.id}`}
                type="checkbox"
                checked={!!option.isSelected}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={(e) => onChange(option.id, e.target.checked)}
              />
            </div>
            <label
              htmlFor={`option-${option.id}`}
              className="select-none font-medium text-gray-900 min-w-[6rem] flex-1"
            >
              {option.name}
            </label>
            <div className="relative flex items-center w-28">
              <input
                type="number"
                name={option.name.toLowerCase()}
                id="workingHours"
                className={`w-20 pr-6 rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                  option.isSelected ? 'visible' : 'invisible'
                }`}
                placeholder="1"
                value={option.hoursPreferences}
                onChange={(e) => {
                  if (e.target.value < 1) {
                    e.target.value = 1;
                  }
                  onHoursChanges(e);
                }}
                max="24"
                min="1"
                disabled={!option.isSelected} // Disable if not selected
              />
              <span
                className={`absolute right-2 text-gray-500 sm:text-sm ${
                  option.isSelected ? 'visible' : 'invisible'
                }`}
              >
                h
              </span>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};
