export const getTeamMembersAllocationsLogic = async (datas) => {
  const { callData, setDays, returnData } = datas;

  if (returnData) {
    return callData;
  }

  if (callData.days) {
    setDays(callData.days);
  }
  if (callData.allocationsByProject) {
    setDays(callData.allocationsByProject);
  }
};
