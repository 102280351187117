import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

export const SlideOverInputCurrencyAmount = ({
  name,
  isRequired,
  currencySymbol,
  currencyName,
}) => {
  const { register, errors } = useSlideOverFormContext();

  // Handle input change to prevent negative values
  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value.startsWith('-')) {
      event.target.value = '1'; // Replace negative sign with 1
    }
  };

  return (
    <div className="sm:col-span-2">
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span className="text-gray-500 sm:text-sm">{currencySymbol}</span>
        </div>
        <input
          id={name}
          type="number"
          step="0.01"
          name={name}
          placeholder="0.00"
          aria-describedby={`${name}-currency`}
          onInput={handleInputChange}
          {...(register &&
            register(name, {
              required: isRequired ? `This input is required.` : false,
              valueAsNumber: true,
              min: 0,
            }))}
          className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span id={`${name}-currency`} className="text-gray-500 sm:text-sm">
            {currencyName}
          </span>
        </div>
      </div>
      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};
