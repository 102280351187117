export const getTeamsByOwnerUUIDLogic = async (datas) => {
  const { callData, teamUUID, navigate, navigateTo, routeUnauthorized } = datas;
  let isOwnerOfTheCurrentTeam = false;

  callData.forEach((team) => {
    if (team.uuid === teamUUID) {
      isOwnerOfTheCurrentTeam = true;
    }
  });

  if (!isOwnerOfTheCurrentTeam) {
    navigateTo({ navigate, routeName: routeUnauthorized });
  }
};
