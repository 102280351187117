import { dynamicApiCall } from '../../api/response/callResponsehandler';

export const attachMembersToAllocations = async (
  allocations,
  teamUUID,
  memberCache,
  navigate
) => {
  const updatedAllocations = [];

  for (const allocation of allocations) {
    if (allocation.user?.uuid) {
      // Check if the member data is already cached
      if (!memberCache.current[allocation.user.uuid]) {
        await dynamicApiCall({
          callName: 'getTeamMembersByTeamUUID',
          navigate,
          params: {
            teamUUID,
            userUUID: allocation.user.uuid,
            setPeople: (memberData) => {
              // Cache the member data
              memberCache.current[allocation.user.uuid] = memberData;
            },
          },
        });
      }
      // Attach the cached or fetched member details to the allocation
      allocation.user.teamMemberDetails =
        memberCache.current[allocation.user.uuid];
    }
    updatedAllocations.push(allocation);
  }

  return updatedAllocations;
};
