import React from 'react';

function TableCellTextWithBarChart({ data, label, index, customClassName }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // Destructure the data object to extract consumed and amount
  const { consumed, amount } = data;

  // Calculate the percentage for the bar chart
  const percentage = amount > 0 ? (consumed / amount) * 100 : 0;

  // Determine the bar color based on the percentage
  let barColor;
  if (percentage <= 100) {
    barColor = 'bg-green-600';
  } else if (percentage > 100 && percentage <= 120) {
    barColor = 'bg-orange-500';
  } else {
    barColor = 'bg-red-600';
  }

  return (
    <td
      className={classNames(
        index !== data.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 pr-3 text-xs text-gray-600 px-2',
        customClassName
      )}
    >
      <div className="flex flex-col items-center">
        <span className={`text-xs text-gray-600 ${customClassName}`}>
          {label}
        </span>
        <div className="w-full mt-1 max-w-xs">
          {/* Fixed width for the bar chart container */}
          <div className="relative w-full bg-gray-200 rounded h-2">
            <div
              className={`${barColor} rounded h-2`}
              style={{ width: `${percentage}%`, maxWidth: '100%' }}
            />
          </div>
        </div>
      </div>
    </td>
  );
}

export default TableCellTextWithBarChart;
