import React from 'react';
import { formatDateInDDMMMYYYY } from '../../services/utils/momentUtils';

export const TableCellDate = ({ data, date, index, customClassName }) => {
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 text-xs text-gray-600 px-2',
        customClassName
      )}
    >
      <div className="flex items-center justify-end gap-x-2 sm:justify-start">
        <span>{formatDateInDDMMMYYYY(date)}</span>
      </div>
    </td>
  );
};
