export const putTeamsByUUIDLogic = async (datas) => {
  const {
    callData,
    fetchTeamsByUUIDPromise,
    fetchTimezonePromise,
    fetchCountryPromise,
    fetchCurrencyPromise,
    navigate,
    path,
    updateTeamName,
    teamName,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    if (navigate && path) {
      navigate(path);
    }
    if (fetchTeamsByUUIDPromise) {
      await fetchTeamsByUUIDPromise();
    }
    if (fetchTimezonePromise) {
      await fetchTimezonePromise();
    }
    if (fetchCountryPromise) {
      await fetchCountryPromise();
    }
    if (fetchCurrencyPromise) {
      await fetchCurrencyPromise();
    }
    if (updateTeamName && teamName) {
      updateTeamName(teamName);
    }
    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({ ...callData });
    }
  }
};
