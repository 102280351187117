import { Link } from 'react-router-dom';
import {
  AdjustmentsHorizontalIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  TagIcon,
} from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SubSideBarLayout = ({ children }) => {
  const currentCustomPath = `${window.location.pathname.split(`/`)[1]}/${
    window.location.pathname.split(`/`)[2]
  }/${window.location.pathname.split(`/`)[3]}`;

  const label = 'Team settings';
  const navigation = [
    {
      sectionLabel: 'Settings',
      sectionItems: [
        {
          name: 'Plans & billing',
          href: `/${currentCustomPath}/plans&billing`,
          icon: CreditCardIcon,
          current: window.location.pathname.includes('/plans&billing'),
        },
        {
          name: 'General',
          href: `/${currentCustomPath}/general`,
          icon: Cog6ToothIcon,
          current: window.location.pathname.includes('/general'),
        },
      ],
    },
    {
      sectionLabel: 'Admin',
      sectionItems: [
        {
          name: 'Hours & currency',
          href: `/${currentCustomPath}/hours&currency`,
          icon: AdjustmentsHorizontalIcon,
          current: window.location.pathname.includes('/hours&currency'),
        },
        {
          name: 'Tags',
          href: `/${currentCustomPath}/tags`,
          icon: TagIcon,
          current: window.location.pathname.includes('/tags'),
        },
      ],
    },
  ];

  return (
    <>
      <div className="lg:fixed bg-slate-200 inset-y-0 left-72 lg:w-80 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8 xl:block">
        <p className="text-xl font-semibold leading-6 text-slate-700">
          {label}
        </p>

        {navigation.map((section) => (
          <div key={section.sectionLabel}>
            <p className="my-4 text-base font-semibold leading-6 text-slate-500">
              {section.sectionLabel}
            </p>

            <ul role="list" className="-mx-2 space-y-1">
              {section.sectionItems.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-slate-300 text-slate-600'
                        : 'text-slate-500 hover:text-slate-600 hover:bg-slate-300',
                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-slate-600'
                          : 'text-slate-500 hover:text-slate-600',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {children}
    </>
  );
};
