import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
} from '@heroicons/react/24/outline';

export const TableCellToggle = ({
  data,
  label,
  index,
  isExpanded,
  customClassName,
  onClick,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };

  return (
    <td
      className={classNames(
        'relative whitespace-nowrap px-2 py-4 text-sm text-gray-600',
        data !== index.length - 1 ? 'border-b' : 'border-b border-gray-200',
        isExpanded ? 'border-t border-x border-indigo-500 bg-gray-50' : '',
        customClassName
      )}
    >
      <div
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={handleKeyPress}
        className="flex items-center group hover:text-indigo-500 rounded-md"
      >
        <span className="truncate block max-w-xs" title={label}>
          {label}
        </span>
        {isExpanded ? (
          <BarsArrowUpIcon className="h-3 w-3 ml-2 text-gray-400 group-hover:text-gray-600" />
        ) : (
          <BarsArrowDownIcon className="h-3 w-3 ml-2 text-gray-400 group-hover:text-gray-600" />
        )}
      </div>
      {isExpanded && (
        <div
          className="absolute bottom-0 left-0 right-0 h-2 bg-gray-50"
          style={{ marginBottom: '-3px' }} // Adjust the margin to perfectly align with the row below
        />
      )}
    </td>
  );
};
